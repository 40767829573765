import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api-service/api.service';
import { Apis } from '../API';


@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor(private apiService: ApiService, private http: HttpClient) { }

  getListAlert(records_per_page, page_number, body) {
    return this.apiService.post(`${Apis.main.list_alerts}/${records_per_page}/${page_number}`, body)
  }

   desactivateAlert(id_alert,body) {
    return this.apiService.post(`${Apis.main.desactivate_alert}/${id_alert}`, body)
  } 

  ajouterAlert(body){
    return this.apiService.post(`${Apis.main.ajouter_alert}`,body)
  }

  getFicheAlert(id_alert, body) {
    return this.apiService.post(`${Apis.main.fiche_alert}/${id_alert}`, body)
  }

}
