import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FullCalendarComponent } from "@fullcalendar/angular";
import {
  CalendarOptions,
  DatesSetArg,
  EventClickArg,
  EventSourceInput,
  ViewApi,
} from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGrigPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";
import { PageTitleService } from "app/core/page-title/page-title.service";
import { CookieStoreService } from "app/service/cookieService/cookieService.service";
import { SharedService } from "app/service/shared-service/shared.service";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { InterventionService } from "app/service/intervention/intervention.service";
import { HttpErrorResponse } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { DemandesRequetesService } from "app/service/demandes-requetes-service/demandes-requetes.service";
import { ReservationService } from "app/service/reservation-service/reservation.service";
import { AgendaService } from "../../service/agenda/agenda.service";
import { MatDialog } from "@angular/material/dialog";
import { GroupEventsDialogComponent } from "../group-events-dialog/group-events-dialog.component";
import { ActivatedRoute } from "@angular/router";
import { ReservationModalComponent } from "../reservation-modal/reservation-modal.component";
import { FicheReservationComponent } from "../fiche-reservation/fiche-reservation.component";

@Component({
  selector: "ms-calendrier-reservations",
  templateUrl: "./calendrier-reservations.component.html",
  styleUrls: ["./calendrier-reservations.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CalendrierReservationsComponent implements OnInit, OnDestroy {
  @ViewChild("calendar") calendarComponent: FullCalendarComponent;

  calendarOptions: CalendarOptions = {};
  id_residence: string;
  private subscription: Subscription;
  startDateCalendar: any;
  endDateCalendar: any;
  locales = [frLocale];
  events: any[] = [];
  currentView: string = "";
  selectedType: string = "";
  listeCategories: any[] = [];
  listeInstallation: any[] = [];
  listeEquipements: any[] = [];
  listColors: any[] = [];
  listParams: any[] = [];
  listCategory: any[] = [];
  // listColorsIntervention : any[] = [];
  selectedCategoryRESE: string = null;
  selectedCategoryINTE: string = null;
  selectedEquipment: any;
  selectedStatus: any = null;
  selectedTypeINTE: any = null;
  type: string = "";
  start: string = "";
  end: string = "";
  activeButton: string;
  selectedCategoryName: string;
  listType: any;

  constructor(
    private pageTitleService: PageTitleService,
    public sharedService: SharedService,
    private cookies: CookieStoreService,
    private reservationService: ReservationService,
    private toastr: ToastrService,
    private demandesRequetesService: DemandesRequetesService,
    private dialog: MatDialog,
    private agendaService: AgendaService,
    private InterventionService: InterventionService,
    private route: ActivatedRoute
  ) {
    this.activeButton = "dayGridMonth";
  }

  ngOnInit(): void {
    this.initCalendar();

    this.route.data.subscribe({
      next: (data) => {
        this.pageTitleService.setTitle(data.title);
        this.selectedType = this.type = data.type || "";
        this.id_residence = this.cookies.getTOKEN("id_residence");
        this.getType();
        this.getColors();
        this.getStatus();
        if (this.selectedType === "RESE") {
          /* this.getCategories();*/
        } else if (this.selectedType === "INTE") {
          /*  this.getcateg(); */
        }
      },
    });
  }

  initCalendar() {
    this.calendarOptions = {
      plugins: [dayGridPlugin, timeGrigPlugin, interactionPlugin],
      initialView: "dayGridMonth",
      dayMaxEvents: true,
      dateClick: this.handleDateClick.bind(this),
      datesSet: this.handleDatesSet.bind(this),
      headerToolbar: {
        right: "dayGridMonth,timeGridWeek",
        center: "title",
        left: "prev,next",
      },
      allDaySlot: false,
      locales: [frLocale],
      locale: "fr",
      eventMaxStack: 1,
      eventClick: this.handleEventClick.bind(this),
      views: {
        timeGridWeek: {
          dayMaxEventRows: 1,
        },
      },
    };
  }

  handleDateClick() {
    this.calendarOptions["events"] = this.events;
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  handleButtonClick(buttonType: string) {
    this.activeButton = buttonType; // Update active button
  }

  handleEventClick(arg: EventClickArg) {
    const props = arg.event.extendedProps;

    if (props.isGroup)
      this.openEventsGroupDialog(
        props.items,
        props.type,
        props.backgroundColor
      );
    else if (!props.isGroup && props.type === "RESE") {
      // Reservation popup
      this.ficheReservation(props.id);
    }
  }

  onFilterChange() {
    this.initCalendar();
  }

  handleDatesSet(arg: DatesSetArg) {
    this.currentView = arg.view.type;
    this.start = moment(arg.start).format("YYYY-MM-DD");
    this.end = moment(arg.end).format("YYYY-MM-DD");
    this.events = [];
    if (!this.events.length) {
      if (this.selectedType === "INTE") {
        this.getcateg();
      } else if (this.selectedType === "RESE") {
        this.getCategories();
      }
    }
    this.calendarComponent.events = [];
  }

  getInterventions(fromDate: string, toDate: string) {
    this.agendaService
      .listIntervention({
        id_residence: this.id_residence,
        code_status: this.selectedStatus,
        fromDate,
        toDate,
        code_type_intervention: this.selectedTypeINTE,
        code_categorie: this.selectedCategoryINTE,
      })
      .subscribe({
        next: (response) => {
          this.groupedToEvent(response.interventions, "Interventions");
        },
        error: (error: HttpErrorResponse) => {
          this.toastr.error(error.error.message ?? "Internal Error!");
        },
        complete: () => {
          this.calendarComponent.events = [...this.events];
        },
      });
  }

  getReservations(fromDate: string, toDate: string) {
    this.agendaService
      .listReservation({
        id_residence: this.id_residence,
        code_installation: this.selectedCategoryRESE,
        id_installation: !isNaN(this.selectedEquipment)
          ? this.selectedEquipment
          : null,
        code_status: this.selectedStatus,
        fromDate,
        toDate,
      })
      .subscribe({
        next: (response) => {
          this.groupedToEvent(response.reservations, "Reservation");
        },
        error: (error: HttpErrorResponse) => {
          this.toastr.error(error.error.message ?? "Internal Error!");
        },
        complete: () => {
          this.calendarComponent.events = [...this.events];
        },
      });
  }

  handleCalendarDatesSet(arg: any) {
    this.startDateCalendar = moment(arg.start).format("YYYY-MM-DD");
    this.endDateCalendar = moment(arg.end).format("YYYY-MM-DD");
  }

  groupObjectsByHour(
    objects: any[],
    type: string = "Reservation"
  ): { [key: string]: any[] } {
    const groupedByHour: { [key: string]: any[] } = {};
    objects.forEach((obj) => {
      let key = "";
      const status = obj.status;
      if (type === "Reservation") {
        const date = new Date(obj.date);
        const day = date.getDate().toString();
        const month = (date.getMonth() + 1).toString();
        const year = date.getFullYear().toString();
        const hour = date.getHours().toString();
        key = `${year}-${month}-${day}|${status}`;
      } else {
        key = obj.date_realisation
          ? this.getDateFormat(
              obj.date_realisation,
              obj.heure_debut_realisation
            )
          : this.getDateFormat(
              obj.date_planification,
              obj.heure_debut_planification
            );
        key = `${key}|${status}`;
      }
      if (!groupedByHour[key]) {
        groupedByHour[key] = [];
      }
      groupedByHour[key].push(obj);
    });
    return groupedByHour;
  }

  openEventsGroupDialog(group: any[], type: string, color: string) {
    const dialogRef = this.dialog.open(GroupEventsDialogComponent, {
      width: "600px",
      autoFocus: false,
      maxHeight: "85vh",
    });
    dialogRef.componentInstance.items = group;
    dialogRef.componentInstance.type = type;
    dialogRef.componentInstance.color = color;
  }

  groupedToEvent(interventions: any[], type: string) {
    const groupedByDay = this.groupObjectsByDate(interventions);

    for (const day in groupedByDay) {
      const eventsForDay = groupedByDay[day];
      for (const item of eventsForDay) {
        let classNames = [];
        if (type === "Interventions") {
          const TypeColor = this.listType.find((param) => {
            return param.code_parametrage === item.status;
          })?.code_couleur;
          const root = document.documentElement;
          const style = document.createElement("style");
          style.textContent = `
            .custom-event-${item.status}::after {
              background-color: ${item.color_Status};
            }
          `;
          document.head.appendChild(style);
          this.events.push({
            title: `${item.nom_createur}`,
            start: item.date_realisation
              ? this.getDateFormat(
                  item.date_realisation,
                  item.heure_debut_realisation
                )
              : this.getDateFormat(
                  item.date_planification,
                  item.heure_debut_planification
                ),
            end: item.date_realisation
              ? this.getDateFormat(
                  item.date_realisation,
                  item.heure_fin_realisation
                )
              : this.getDateFormat(
                  item.date_planification,
                  item.heure_fin_planification
                ),
            classNames: [`custom-event-${item.status} custom-event`],
            backgroundColor: "#fff",
            borderColor: item.color_type_intervention,
            textColor: item.color_type_intervention,
            url: this.getEventUrl(type, item.id) ?? null,
            allDay: this.currentView === "dayGridMonth",
            extendedProps: {
              isGroup: false,
              type: "INTE",
              id: item.id,
            },
          });
        } else {
          const root = document.documentElement;
          const style = document.createElement("style");
          style.textContent = `
            .custom-event-${item.status}::after {
              background-color: ${item.color_Status};
            }
          `;
          document.head.appendChild(style);
          this.events.push({
            title: `${item.nom_demandeur}`,
            start: `${item.date} ${item.start}`,
            end: `${item.date} ${item.end}`,
            classNames: [`custom-event-${item.status} custom-event`],
            backgroundColor: "#fff",
            borderColor: item.color_installation,
            textColor: item.color_installation,
            allDay: this.currentView === "dayGridMonth",
            extendedProps: {
              isGroup: false,
              type: "RESE",
              id: item.id,
            },
          });
        }
      }
    }
  }

  groupObjectsByDate(objects: any[]): { [key: string]: any[] } {
    const groupedByDate: { [key: string]: any[] } = {};
    objects.forEach((obj) => {
      const date = obj.date;
      if (!groupedByDate[date]) {
        groupedByDate[date] = [];
      }
      groupedByDate[date].push(obj);
    });
    return groupedByDate;
  }

  getDateFormat(day: string, time: string) {
    const date = moment(day).format("YYYY-MM-DD");
    return `${date} ${time}`;
  }

  getCategories() {
    this.reservationService
      .getReservationCategories({ id_residence: this.id_residence })
      .subscribe({
        next: (res: any) => {
          this.listeCategories = res.liste_reservation_category;
          if (this.listeCategories.length > 0) {
            this.selectedCategoryRESE = this.selectedCategoryRESE
              ? this.selectedCategoryRESE
              : this.listeCategories[0].code_categorie;
            this.getEquipement();
            this.getReservations(this.start, this.end);
          }
        },
        complete: () => {
          this.getColors();
        },
      });
  }

  getEquipement() {
    this.reservationService
      .getReservationCategories({
        code_type_installation: this.selectedCategoryRESE,
        id_residence: this.id_residence,
      })
      .subscribe((res: any) => {
        this.listeEquipements = res.liste_reservation_category;

        const selectedCategoryObj = this.listeCategories.find(
          (category) => category.code_categorie === this.selectedCategoryRESE
        );
        if (selectedCategoryObj) {
          this.selectedCategoryName = selectedCategoryObj.nom_categorie_fr;
        } else {
          this.selectedCategoryName = "";
        }
      });
  }

  getColors() {
    if (this.selectedType === "INTE") {
      this.agendaService
        .parametrage({ type_parametrages: "CAIN" })
        .subscribe((res: any) => {
          this.listColors = res.parametrage;
        });
    }
    if (this.selectedType === "RESE" && this.selectedCategoryRESE) {
      this.agendaService
        .getReservationColors({ code_installation: this.selectedCategoryRESE })
        .subscribe((res: any) => {
          this.listColors = res.colors;
        });
    }
  }

  getType() {
    if (this.selectedType === "INTE") {
      this.agendaService
        .parametrage({ type_parametrages: "TYIN" })
        .subscribe((res: any) => {
          this.listType = res.parametrage;
        });
    }
  }

  getcateg() {
    if (this.selectedType === "INTE") {
      this.agendaService.parametrage({ type_parametrages: "CAIN" }).subscribe({
        next: (res: any) => {
          this.listCategory = res.parametrage;

          this.selectedCategoryINTE = this.selectedCategoryINTE
            ? this.selectedCategoryINTE
            : this.listCategory[0]?.code_parametrage;

          this.getInterventions(this.start, this.end);
        },
        error: (error: any) => {
          // Handle error if needed
        },
      });
    }
  }

  getStatus() {
    if (this.selectedType === "INTE") {
      this.agendaService
        .parametrage({ type_parametrages: "COST" })
        .subscribe((res: any) => {
          this.listParams = res.parametrage;
          if (this.listParams.length > 0) {
            this.selectedStatus =
              this.listParams.find(
                (o) =>
                  o.code_parametrage === "PLAN" &&
                  o.code_parametrage === "REAL" &&
                  o.code_parametrage === "ANNU"
              ).code_parametrage ?? this.listParams[0].code_parametrage;
          }
        });
    } else if (this.selectedType === "RESE") {
      this.agendaService
        .parametrage({ type_parametrages: "TYRE" })
        .subscribe((res: any) => {
          this.listParams = res.parametrage;
          if (this.listParams.length > 0) {
            this.selectedStatus = null;
          }
        });
    }
  }

  getEventUrl(type: string, id: any) {
    if (type === "Interventions") return `/interventions/fiche/${id}`;
    else if (type === "Reservation")
      return `reservations/fiche-reservation/${id}`;
    else return null;
  }

  openReservationDialog(id: any) {
    const dialogRef = this.dialog.open(ReservationModalComponent, {
      width: "600px",
      autoFocus: false,
      maxHeight: "85vh",
    });
    dialogRef.componentInstance.id = id;
    dialogRef.componentInstance.id_residence = this.id_residence;
    dialogRef.afterClosed().subscribe((result) => {
      if (result.refresh) {
        this.resetEvents();
      }
    });
  }

  ficheReservation(id: any) {
    const dialogRef = this.dialog.open(FicheReservationComponent, {
      width: "900px",
      autoFocus: false,
      maxHeight: "85vh",
    });
    dialogRef.componentInstance.id = id;
    dialogRef.componentInstance.id_residence = this.id_residence;
  }

  resetEvents() {
    this.events = [];
    if (this.selectedType === "INTE") {
      this.selectedEquipment = null;
      this.selectedCategoryRESE = null;
      this.getInterventions(this.start, this.end);
    } else if (
      (this.selectedCategoryRESE || this.selectedEquipment) &&
      this.selectedType === "RESE"
    ) {
      this.getReservations(this.start, this.end);
    } else {
      this.calendarComponent.events = [];
    }
  }
}
