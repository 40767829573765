import { Injectable } from '@angular/core';
import { ApiService } from '../api-service/api.service';
import { Apis } from '../API';

@Injectable({
  providedIn: 'root'
})
export class InterventionService {

  constructor(private apiService: ApiService) { }

  getListIntervention(records_per_page : number = 99, page_number : number = 0, data : any = {}) {
    return this.apiService.post(`${Apis.main.liste_intervention}/${records_per_page}/${page_number}`, data)
  }

  fiche(id: any, data : any = {}) {
    return this.apiService.post(`${Apis.main.fiche_intervention}/${id}`, data);
  }

  annuler(data : any = {}) {
    return this.apiService.post(`${Apis.main.annuler_intervention}`, data);
  }
}
