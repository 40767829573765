import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api-service/api.service';
import { Apis } from '../API';

@Injectable({
  providedIn: 'root'
})
export class ListIncidentService {
  constructor(private apiService: ApiService, private http: HttpClient) { }


  getListIncident(records_per_page, page_number, body) {
    return this.apiService.post(`${Apis.backoffice.incident}/${records_per_page}/${page_number}`, body)
  }

  getFicheIncident(idIcident,body) {
    return this.apiService.post(`${Apis.main.FicheIncident}/${idIcident}`,body)
  }

  getCategories(id_residence){
    return this.apiService.get(`${Apis.main.liste_categories}/${id_residence}`)
  }

  getResidence(){
    return this.apiService.get(`${Apis.main.list_residence}/99999999/0`)
  }

  ajouterIncident(body: any) {
    return this.apiService.post(`${Apis.backoffice.ajouter_incident}`, body);
  }
  qualifierIncident(body){
    return this.apiService.post(`${Apis.backoffice.qualifier_incident}`,body)
  }

  uploadImage(imgExtension, order, cle_incident, fd) {
    return this.apiService.upload(`${Apis.main.uploadImages}${imgExtension}/${order}/${cle_incident}`, fd)
  }

  // uploadImageWith(imgExtension, order, cle_incident, fd) {
  //   return this.apiService.upload(`${Apis.main.uploadImages}${imgExtension}/${order}/${cle_incident}`, fd)
  // }

  getHistoriqueIncident(id_incident: number, body) {
    return this.apiService.post(`${Apis.main.historique_incident}/${id_incident}`, body)
  }
  traiterIncident(body) {
    return this.apiService.post(`${Apis.backoffice.traiter_incident}`, body)
  }

  qualifierTraitement(body) {
    return this.apiService.post(`${Apis.backoffice.qualifier_traitement}`, body)
  }
  
  getStatut(body){
    return this.apiService.post(`${Apis.main.list_parametrage}`,body)
  }

  getTypeIncident(body) {
    return this.apiService.post(`${Apis.main.liste_param_categorie}`, body);
  }
  getStatutIncident(body){
    return this.apiService.post(`${Apis.main.list_statut_incident}`,body)
  }

  getResidenceCategories(body) {
    return this.apiService.post(`${Apis.main.liste_residence_categorie}`, body);
  }

  ajouterIntervention(body) {
    return this.apiService.post(`${Apis.main.ajouter_intervention}`, body);
  }

  verifierIncident(body: any) {
    return this.apiService.post(`${Apis.main.verifier_incident}`, body);
  }

  annulerIncident(body: any) {
    return this.apiService.post(`${Apis.main.annuler_incident}`, body);
  }

  ajouterRapport(body: any) {
    return this.apiService.post(`${Apis.main.ajouter_rapport}`, body);
  }
}
