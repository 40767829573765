import { filter } from 'rxjs/operators';
import { ListeCodifService } from 'app/service/liste-codif-service/liste-codif.service';
import { Component, Inject, Input, OnInit, ViewChild, } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core/option';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CookieStoreService } from 'app/service/cookieService/cookieService.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { InfoUtilesService } from 'app/service/info-utiles/info-utiles-service.service';
import { ResidencesService } from 'app/service/residences/residences.service';

@Component({
  selector: 'ms-ajouter-resident',
  templateUrl: './ajouter-resident.component.html',
  styleUrls: ['./ajouter-resident.component.scss']
})
export class AjouterResidentComponent implements OnInit {
  @Input() data_resident?: any;
  @Input() type? : string;
  body;
  id_residence;
  residentForm : FormGroup;
  listeTyperesident: any;
  dialCode: any = "+212";
  liste_biens: any;
  liste_etages: any;
  liste_immeubles: any;
  listeTypesAffectation: any[];
  libelle_occupant;
    liste_type_utilisation: any[];
    iso2 : string = "MA";
  libelle_site_short;
  flag_genre_site

  constructor(public dialogRef: MatDialogRef<AjouterResidentComponent> ,
    private listeCodifService     : ListeCodifService   ,
    private residencesService     : ResidencesService,
    private formBuilder: FormBuilder,
    private cookies : CookieStoreService
    ){
      this.residentForm = this.formBuilder.group({
        nom_compte: ['', [Validators.required]],
        tel_compte: ['', [Validators.required, this.numericAndMaxLengthValidator(10)]],
        ref_immeuble: ['', [Validators.required]],
        num_etage: ['', [Validators.required]],
        code_type_affectation : ['', [Validators.required]],
        reference_bien: ['', [Validators.required]],
        email: [null, [Validators.email]],
        nom_societe : [null],
        flag_compte_gestionnaire : ["N"],
        code_type_utilisation : ['', [Validators.required]]
      });
    }

  
  async ngOnInit(): Promise<void> {

    this.getListeTypesAffectation();
    this.get_type_utilisation();
    this.id_residence = this.cookies.getTOKEN("id_residence");
    this.libelle_occupant = this.cookies.getTOKEN("libelle_occupant").replace(/"/g, '');
    this.libelle_site_short = this.cookies.getTOKEN("libelle_site_short").replace(/"/g, '');
    this.flag_genre_site = this.cookies.getTOKEN("flag_genre_site").replace(/"/g, '');
    this.residencesService.getBiensResidences({id_residence : this.id_residence}).subscribe(res => {
      this.liste_immeubles = res.liste_immeuble_villas.immeubles
    })
    this.initResidentForm()
  }

  initResidentForm() {
    this.residentForm = this.formBuilder.group({
      nom_compte: [this.data_resident ? this.data_resident.nom_compte : ''],
      tel_compte: [this.data_resident ? this.data_resident.tel_compte : ''],
      ref_immeuble: [''],
      num_etage: [''],
      code_type_affectation : [''],
      reference_bien: [''],
      email: [this.data_resident ? this.data_resident.email: null],
      nom_societe : [this.data_resident ? this.data_resident.nom_societe : null],
      flag_compte_gestionnaire : ["N"],
      code_type_utilisation : ['']
    });
  }
  numericAndMaxLengthValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
  
      if (value === null || value === '') {
        return null;
      }
  
      const numericRegex = /^[0-9]+$/;
  
      if (!numericRegex.test(value) || value.length > maxLength) {
        return { 'numericAndMaxLength': true }; 
      }
  
      return null;
    };
  }

  onCountryChange(event){
    this.dialCode = event.dialCode; 
    this.iso2 = event.iso2.toUpperCase();
  }

  immeubleChanged() {
    this.residentForm.controls["num_etage"].setValue(null)
    this.residentForm.controls["reference_bien"].setValue(null)
    this.liste_biens = []
    this.liste_etages = this.liste_immeubles.find(i => i.reference_immeuble == this.residentForm.controls["ref_immeuble"].value).nbr_etages
  }

  etageChanged() {
    this.liste_biens = this.liste_etages.find(e => e.nbr_etage == this.residentForm.controls["num_etage"].value).biens
  }

  getListeTypesAffectation() {
    this.listeTypesAffectation = [];
      let body ={
        "type_parametrages" : "CTAF"
      }
      this.listeCodifService.getStatut(body)
        .subscribe((res: any) => {
          this.listeTypesAffectation = res.parametrage.filter(p => p.code_parametrage != 'COPN');
        })
  }
  ModifierCompte() {
    let body = {}
    if (this.type == 'resident'){

      if (!this.residentForm.get('nom_compte').value) {
        return this.showMessageWarning("Nom et pénom et obligatoire!")
      }

      body = {
        id_affectation:this.data_resident.id_affectation,
        nom_compte:this.residentForm.get('nom_compte').value,
        nom_societe: this.residentForm.get('nom_societe').value,
        type: this.type
      }
    }
    else if (this.type == 'compte') {
      body = {
        id_compte:this.data_resident.id_compte,
        email:this.residentForm.get('email').value,
        tel_compte: this.residentForm.get('tel_compte').value,
        dialCode:  this.dialCode,
        iso2:  this.iso2,
        type: this.type
      }

      let numericRegex = /^[0-9]+$/;

      if (!numericRegex.test(body["tel_compte"])) {
        return this.showMessageWarning("Le numéro de téléphone ne doit contenir que des chiffres!")
      }

      if (!body["tel_compte"]) {
        return this.showMessageWarning("Téléphone est obligatoire!")
      }

      if (!["06","07"].includes(body["tel_compte"].slice(0,2))) {
        return this.showMessageWarning("Le numéro de téléphone doit commencer par 06 ou 07")
      }

    }

    this.residencesService.ModifierCompte(body)
      .subscribe((res: any) => {
        this.showSuccessMessage("info modifier avec success!")
        this.dialogRef.close();
      }, err => {
        console.log(err.status);
        if (err.status == 409){
          this.showMessageError(err.error.message);
        }
        else {
          this.showMessageError('error');
        }

      })
  }
  AjouterResident(){ 

    if (this.residentForm.valid) {

      const formData = this.residentForm.value;
      formData["id_residence"] = this.id_residence
      formData["code_role_compte"] = "RESI"
      formData["code_type_bien"] = this.liste_biens.find(b => b.reference_bien == this.residentForm.get('reference_bien').value).code_type_bien
      formData["tel_compte"] = this.residentForm.get('tel_compte').value
      formData["dialCode"] = this.dialCode
      formData["iso2"] = this.iso2
      formData["id_bien"] = this.liste_biens.find(b => b.reference_bien == this.residentForm.get('reference_bien').value).id_bien

      let numericRegex = /^[0-9]+$/;

      if (!numericRegex.test(formData["tel_compte"])) {
        return this.showMessageWarning("Le numéro de téléphone ne doit contenir que des chiffres!")
      }

      if (!formData["tel_compte"]) {
        return this.showMessageWarning("Téléphone est obligatoire!")
      }

      if (!["06","07"].includes(formData["tel_compte"].slice(0,2))) {
        return this.showMessageWarning("Le numéro de téléphone doit commencer par 06 ou 07")
      }
      
      this.residencesService.ajouterAffectationResident(formData)
      .subscribe((res: any) => {
        if (res.limit_exceeded) {
          this.showMessageWarning(`Le ${this.libelle_occupant} est assigné mais n'est pas actif parceque vous avez dépassé la limite des residents actifs que vous pouvez ajouter pour cette société`)
        } else {
          this.showSuccessMessage("Resident Affecté!")
        }
        this.dialogRef.close();
      }, err => {
          if (err.error.error_code == "ROLE") {
            this.showMessageWarning("Vous n'avez pas le droit d'ajouter un gestionnaire")
          } else {
            if (err.error.code === "EXIST") {
              this.showMessageWarning(`Vous êtes déjà affecté ${this.flag_genre_site == "M" ? "sur ce" : "dans cette"} ${this.libelle_site_short}`)
            } else {
              this.showMessageError("Une erreur s'est produite. veuillez réessayer ultérieurement.");        
            }
          }
      })
      
    } else {
      this.showMessageError("les données ne sont pas valides")
    }
  }

  showMessageWarning(msg) {
    Swal.fire({
      icon: 'warning',
      text: msg,
      showConfirmButton: false,
      heightAuto: false,
      timer: 5000,
    });
  }

  showSuccessMessage(msg) {
    Swal.fire({
      icon: 'success',
      text: msg,
      showConfirmButton: false,
      heightAuto: false,
      timer: 2500,
    });
  }

  showMessageError(msg) {
    Swal.fire({
      icon: 'error',
      text: msg,
      showConfirmButton: false,
      heightAuto: false,
      timer: 2500,
    });
  }

  get_type_utilisation () {
    this.liste_type_utilisation = [];
    this.listeCodifService.getStatut({type_parametrages : "TYUT"})
      .subscribe((res: any) => {
        this.liste_type_utilisation = res.parametrage;
      })
  }

}
