import { Injectable } from "@angular/core";
import { ApiService } from "../api-service/api.service";
import { HttpClient } from "@angular/common/http";
import { Apis } from "../API";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private apiService: ApiService, private http: HttpClient) {}

  getStatistiquesDashboard(body: any) {
    return this.apiService.post(`${Apis.main.getStatistiquesDashboard}`, body);
  }

  getResidences() {
    return this.apiService.get(`${Apis.main.tableau_residences}`);
  }

  getListeResidences() {
    return this.apiService.get(`${Apis.main.liste_residences}9999/0`);
  }

  getResidenceStats(body: any) {
    return this.apiService.post(`${Apis.main.residence_stats}`, body);
  }
}
