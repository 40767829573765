import { Component, OnInit, Input } from '@angular/core';
import { CookieStoreService } from 'app/service/cookieService/cookieService.service';

@Component({
  selector: 'ms-qr-code-modal',
  templateUrl: './qr-code-modal.component.html',
  styleUrls: ['./qr-code-modal.component.scss']
})
export class QrCodeModalComponent implements OnInit {

  libelle_site_short;
  flag_genre_site;

  @Input() qrcode! : string
  constructor(
    private cookies :  CookieStoreService
  ) { }

  ngOnInit(): void {
    this.libelle_site_short = this.cookies.getTOKEN("libelle_site_short").replace(/"/g, '');
    this.flag_genre_site = this.cookies.getTOKEN("flag_genre_site").replace(/"/g, '');
  }

  shareQRCode() {
    if (navigator.share) {
      const shareData = {
        title: "Le code QR de l'utilisateur",
        text: `Voici le qr code pour accéder ${this.flag_genre_site == "M" ? "au" : "à la"} ${this.libelle_site_short}`,
        url: this.qrcode
      };
  
      navigator.share(shareData)
        .then(() => {
          console.log('Shared successfully');
        })
        .catch((error) => {
          console.error('Error sharing:', error);
        });
    }
  }

}
