import { Component, OnInit } from '@angular/core';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { FormBuilder,FormGroup,FormControl} from '@angular/forms';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CurrentUserService } from 'app/service/current-user.service';
import { MatDialog } from '@angular/material/dialog';
import { UtilisateurService } from 'app/service/utilisateur-service/utilisateur.service';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';


export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = 'Objets par page';

  return customPaginatorIntl;
}

export interface Biens {
  imme?: any;
  etages: any;
  appa: any;
} 


@Component({
  selector: 'ms-ajouter-biens',
  templateUrl: './ajouter-biens.component.html',
  styleUrls: ['./ajouter-biens.component.scss']
})
export class AjouterBiensComponent implements OnInit {
  isLoading
  formBiens
  etages = []
  immeubles = []
  appartements = []
  immeuble
  item
  id_affectation
  isSubmitted
  idBiens
  constructor(
      private pageTitleService    : PageTitleService    ,
      private formBuilder         : FormBuilder         ,
      private utlisateurservice   : UtilisateurService  ,
      private _currentUser        : CurrentUserService  , 
      public  dialog              : MatDialog           ,
      private http                : HttpClient          ,
      public  dialogRef           : MatDialogRef<AjouterBiensComponent>

  ) {
    this.formBiens = new FormGroup({
      Immeubles             : new FormControl(),
      Etages                : new FormControl(),
      Appartements          : new FormControl(),
      code_type_affectation : new FormControl()
    });
  }

  ngOnInit(): void {
    this.getImmeuble();
    this.ajouterBiens();
  }


  getImmeuble() {
    this.immeubles = [];
    let body ={
      "id_residence": 1
    }
    this.utlisateurservice.getImmeuble(body)
      .subscribe((res: any) => {
      this.immeubles = res.immeubles;
    })
  }


  ajouterBiens() {
    this.isLoading = true
    let body ={
      
        "id_affectation"          : this.id_affectation,
        "code_type_bien"          : "APPA",
        "code_type_affectation"   : this.formBiens.controls['code_type_affectation'].value,
        "ref_immeuble"            : this.formBiens.controls['Immeubles'].value,
        "num_etage"               : this.formBiens.controls['Etages'].value,
        "reference_bien"          : this.formBiens.controls['Appartements'].value,
    }
    this.utlisateurservice.ajouterBien(body)
      .subscribe((res: any) => {
        this.isLoading = false;
    }, err => {
      this.isLoading = false;
    })
  }

  initIncidentForm() {
    this.formBiens = this.formBuilder.group({
      Immeubles                : [null],
      Etages                   : [null],
      Appartements             : [null],
      code_type_affectation    : [null],
    
  });
  this.ajouterBiens();
}
  
  reset() {
    this.initIncidentForm();
    this.ajouterBiens();
  }

  showMessageSuc(msg) {
    Swal.fire({
      icon: 'success',
      text: msg,
      showConfirmButton: true,
      heightAuto: false,
      timer: 4000,
    });
    this.dialogRef.close(true)
  }

  showMessageError() {
    Swal.fire({
      icon: 'error',
      title: `Une erreur est survenue`,
      text: 'Veuillez réessayer plus tard',
      showConfirmButton: false,
      heightAuto: false,
      timer: 2500
    });
  }

  Ajouter() {
    this.isSubmitted = true
    setTimeout(() => {
      if (this.isSubmitted && this.formBiens.valid) {
        Swal.fire({
          title: "Opération est en cours!",
          heightAuto: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
        const body = {
          "id_affectation"          : this.id_affectation,
          "code_type_bien"          : "APPA",
          "code_type_affectation"   : this.formBiens.controls['code_type_affectation'].value,
          "ref_immeuble"            : this.formBiens.controls['Immeubles'].value,
          "num_etage"               : this.formBiens.controls['Etages'].value,
          "reference_bien"          : this.formBiens.controls['Appartements'].value,
        }

        this.utlisateurservice.ajouterBien(body).subscribe(success => {
          this.showMessageSuc(`Opération réussie`);
        }, error => {
          this.showMessageError();
        })
      }
    }, 100);
  }



}
