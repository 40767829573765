import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MainComponent } from "./main/main.component";
import { AuthGuard } from "./core/guards/auth.guard";
import { ToolbarHeaderComponent } from "./toolbar-header/toolbar-header.component";

const appRoutes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "",
    loadChildren: () =>
      import("./session/session.module").then((m) => m.SessionModule),
  },

  // idk what is this
  // {
  //   path: "incident",
  //   component: ToolbarHeaderComponent,
  //   loadChildren: () =>
  //     import("../pages-externes/pages-externes.module").then(
  //       (m) => m.PagesExternesModule
  //     ),
  //   // loadChildren: () => import('./pages-externes/pages-externes.module').then(m => m.PagesExternesModule)
  // },
  {
    path: "",
    component: MainComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: "taches",
        loadChildren: () =>
          import("./taches/taches.module").then((m) => m.TachesModule),
      },
      {
        path: "incidents",
        loadChildren: () =>
          import("./incident/incident.module").then((m) => m.IncidentModule),
      },
      {
        path: "demandes-requetes",
        loadChildren: () =>
          import("./demandes-requetes/demandes-requetes.module").then((m) => m.DemandesRequetesModule),
      },
      {
        path: "residence",
        loadChildren: () =>
          import("./utilisateurs/utilisateurs.module").then(
            (m) => m.UtilisateursModule
          ),
      },
      {
        path: "residence",
        loadChildren: () =>
          import("./residences/residences.module").then((m) => m.ResidencesModule),
      },
      {
        path: "residence",
        loadChildren: () =>
          import("./resident/resident.module").then((m) => m.ResidentModule),
      },
      {
        path: "communications",
        loadChildren: () =>
          import("./news/news.module").then((m) => m.NewsModule),
      },
      {
        path: "communications",
        loadChildren: () =>
          import("./communication/communication.module").then(
            (m) => m.CommunicationModule
          ),
      },
      {
        path: "reservations",
        loadChildren: () =>
          import("./reservations/reservations.module").then(
            (m) => m.ReservationsModule
          ),
      },
      {
        path: "sondage",
        loadChildren: () =>
          import("./sondage/sondage.module").then((m) => m.SondageModule),
      },
      {
        path: "communications",
        loadChildren: () =>
          import("./alerts/alert.module").then((m) => m.AlertModule),
      },
      {
        path: "fichiers",
        loadChildren: () =>
          import("./fichier-integre/fichier.module").then(
            (m) => m.FichierModule
          ),
      },
      {
        path: "interventions",
        loadChildren: () =>
          import("./interventions/interventions.module").then(
            (m) => m.InterventionsModule
          ),
      },
      {
        path: "communications",
        loadChildren: () =>
          import("./info-utiles/info-utiles.module").then(
            (m) => m.InfoUtilesModule
          ),
      },
      {
        path: "residence",
        loadChildren: () =>
          import("./residences/residences.module").then(
            (m) => m.ResidencesModule
          ),
      },
      {
        path: "autorisations",
        loadChildren: () =>
          import("./autorisations/autorisations.module").then(
            (m) => m.AutorisationsModule
          ),
      },
    ],
  },
  {
    path: "**",
    redirectTo: "login",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: "legacy" }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class RoutingModule {}
