import { filter } from 'rxjs/operators';
import { ListeCodifService } from 'app/service/liste-codif-service/liste-codif.service';
import { Component, Inject, Input, OnInit, ViewChild, } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core/option';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CookieStoreService } from 'app/service/cookieService/cookieService.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { InfoUtilesService } from 'app/service/info-utiles/info-utiles-service.service';
import { ResidencesService } from 'app/service/residences/residences.service';

@Component({
  selector: 'ms-modifier-utilisateur',
  templateUrl: './modifier-utilisateur.component.html',
  styleUrls: ['./modifier-utilisateur.component.scss']
})
export class ModifierUtilisateurComponent implements OnInit {

  modifierForm : any
  data;
  type;
  iso2 : string = "MA";
  dialCode: any = "+212";
  role: any;
  libelle_occupant;

  constructor(
    public dialogRef: MatDialogRef<ModifierUtilisateurComponent> ,
    private listeCodifService     : ListeCodifService   ,
    private residencesService     : ResidencesService,
    private formBuilder: FormBuilder,
    private cookies : CookieStoreService
  ) {
    this.modifierForm = this.formBuilder.group({
      nom_compte: ['', [Validators.required]],
      tel_compte: ['', [Validators.required, this.numericAndMaxLengthValidator(10)]],
      email: [null, [Validators.email]],
      nom_societe : [null],
      flag_afficher_resident : [true],
      cin_compte : [null]
    });
  }

  ngOnInit(): void {
    this.initForm();
    this.libelle_occupant = this.cookies.getTOKEN("libelle_occupant").replace(/"/g, '');
  }

  initForm() {
    this.modifierForm = this.formBuilder.group({
      nom_compte: [this.data ? this.data.nom_compte : ''],
      tel_compte: [this.data ? this.data.tel_compte : ''],
      flag_afficher_resident: [this.data ? (this.data.flag_afficher_resident === "O" ? true : false) : ''],
      ref_immeuble: [''],
      num_etage: [''],
      cin_compte : [this.data ? this.data.cin_compte : ''],
      code_type_affectation : [''],
      reference_bien: [''],
      email: [this.data ? this.data.email: null],
      nom_societe : [this.data ? this.data.nom_societe : null],
      flag_compte_gestionnaire : ["N"],
      code_type_utilisation : ['']
    });
  }

  numericAndMaxLengthValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
  
      if (value === null || value === '') {
        return null;
      }
  
      const numericRegex = /^[0-9]+$/;
  
      if (!numericRegex.test(value) || value.length > maxLength) {
        return { 'numericAndMaxLength': true }; 
      }
  
      return null;
    };
  }

  ModifierCompte() {
    let body = {}
    if (this.type == 'salarie'){
      body = {
        id_affectation:this.data.id_affectation,
        nom_compte:this.modifierForm.get('nom_compte').value,
        nom_societe: this.modifierForm.get('nom_societe').value,
        cin_compte: this.modifierForm.get('cin_compte').value,
        flag_afficher_resident: this.modifierForm.get('flag_afficher_resident').value,
        type: this.type
      }
    }
    else if (this.type == 'compte') {
      body = {
        id_compte:this.data.id_compte,
        email:this.modifierForm.get('email').value,
        tel_compte: this.modifierForm.get('tel_compte').value,
        dialCode:  this.dialCode,
        iso2:  this.iso2,
        type: this.type
      }

      let numericRegex = /^[0-9]+$/;

      if (!numericRegex.test(body["tel_compte"])) {
        return this.showMessageWarning("Le numéro de téléphone ne doit contenir que des chiffres!")
      }

      if (!body["tel_compte"]) {
        return this.showMessageWarning("Téléphone est obligatoire!")
      }

      if (!["06","07"].includes(body["tel_compte"].slice(0,2))) {
        return this.showMessageWarning("Le numéro de téléphone doit commencer par 06 ou 07")
      }

    }

    this.residencesService.ModifierCompte(body)
      .subscribe((res: any) => {
        this.showSuccessMessage("info modifier avec success!")
        this.dialogRef.close();
      }, err => {
        console.log(err.status);
        if (err.status == 409){
          this.showMessageError(err.error.message);
        }
        else {
          this.showMessageError('error');
        }

      })
  }

  showMessageWarning(msg) {
    Swal.fire({
      icon: 'warning',
      text: msg,
      showConfirmButton: false,
      heightAuto: false,
      timer: 3500,
    });
  }

  showSuccessMessage(msg) {
    Swal.fire({
      icon: 'success',
      text: msg,
      showConfirmButton: false,
      heightAuto: false,
      timer: 2500,
    });
  }

  showMessageError(msg) {
    Swal.fire({
      icon: 'error',
      text: msg,
      showConfirmButton: false,
      heightAuto: false,
      timer: 2500,
    });
  }

}
