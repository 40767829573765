import { Component, EventEmitter, Input , OnInit, Output, SimpleChanges } from '@angular/core';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { FormBuilder,FormControl,FormGroup} from '@angular/forms';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { ListIncidentService} from 'app/service/list-incident-service/list-incident.service';
import { CurrentUserService } from 'app/service/current-user.service';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import Swal from 'sweetalert2';
import { SharedService } from 'app/service/shared-service/shared.service';


export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = 'Objets par page';

  return customPaginatorIntl;
}
@Component({
  selector: 'ms-qualif-incident-taches',
  templateUrl: './qualif-incident-taches.component.html',
  styleUrls: ['./qualif-incident-taches.component.scss']
})
export class QualifIncidentTachesComponent implements OnInit {
  cleIncident
  isLoading = false
  userItem
  incident
  form: FormGroup;
  listeMotifAbandon
  isSubmitted: boolean;
  cle_incident: any;
  id_incident : any;
  images: any;
  imagesList: [];
  imagesListProv = []
  photoUrls
  imgUpload
  selectedFiles
  imgExtension
  order
  fd
  code_statut_incident
  description
  motif_abandon
  qualifIncidentForm;
  niveau_gravite;
  damage_caused;
  reasons;
  solutions_retenue;
  listeCategories = []  
  deletedImages = []
  emitImages
  code_type_incident
  id_residence
  ListIncident
  records_per_page = 10
  page_number = 0
  code_categorie_prin
  code_categorie_seco
  listeMorifs = []
  ListNiveauGravite: any;

  constructor(
      private pageTitleService    : PageTitleService                      ,
      private formBuilder         : FormBuilder                           ,
      private listincidentservice : ListIncidentService                   ,
      private _currentUser        : CurrentUserService                    , 
      public  dialog              : MatDialog                             , 
      public sharedService        : SharedService                         ,
      public  dialogRef           : MatDialogRef<QualifIncidentTachesComponent>

  ) {
    this.userItem = this._currentUser.getRoleCurrentUser();

    this.qualifIncidentForm = new FormGroup({
      code_statut_incident   : new FormControl('VRIN'),
      motif_abandon          : new FormControl(),
      description_incident : new FormControl(),
      niveau_gravite : new FormControl(),
      damage_caused : new FormControl(),
      reasons : new FormControl(),
      solutions_retenue : new FormControl(),
      ref_incident_existant: new FormControl()
    });
   }

   

  ngOnInit(): void {
    this.sharedService.getSelectedIdResidenceBehavior().subscribe(value => {
      this.id_residence = value;
      // this.getListIncident(this.records_per_page, this.page_number);
    });      
    this.getListeMotifs()
    this.getListeNiveaugravite();
    this.initForm();
  }

  getListeMotifs() {
    this.listincidentservice.getStatut({"type_parametrages" : "MOTI"})
      .subscribe((res: any) => {
      this.listeMorifs = res.parametrage;
    })
  }

  initForm() {
    this.qualifIncidentForm = this.formBuilder.group({
      description_incident           : [null],
      code_statut_incident     : ["VRIN"],
      motif_abandon            : [null],
      niveau_gravite : [this.niveau_gravite ? this.niveau_gravite : null],
      damage_caused : [this.damage_caused ? this.damage_caused : null],
      reasons : [this.reasons ? this.reasons : null],
      solutions_retenue : [this.solutions_retenue ? this.solutions_retenue : null],
      ref_incident_existant    : [null]
    });
  }

  getListIncident(records_per_page, page_number) {
    this.isLoading      = false;
    this.ListIncident = [];
    console.log(this.id_incident);

    let body = {
    "code_statut_incident"    : null,
    "code_type_incident"      : this.code_type_incident,
    "code_categorie_prin"     : this.code_categorie_prin,
    "code_categorie_seco"     : this.code_categorie_seco,
    "id_residence"            : this.id_residence,
    "id_incident"             : this.id_incident,
    "longitude"               : null,
    "latitude"                : null
    };
    this.listincidentservice.getListIncident(records_per_page, page_number, body)
    .subscribe((res: any) => {
      this.ListIncident = res.incidents;
      console.log(this.ListIncident);
      // this.totalIncidents = res.nbr_total_incidents;
      this.isLoading = false;
      }, err => {
      this.isLoading = false;
    })
  }

  getListeNiveaugravite() {
    this.ListNiveauGravite = [];
    let body ={
      "type_parametrages" : "NVGR"
    }
    this.listincidentservice.getStatut(body).subscribe((res: any) => {
      this.ListNiveauGravite = res.parametrage;
    })
  }


  onChangeStatutIncident(){
    this.getListIncident(this.records_per_page, this.page_number);
  }

  save() {
    this.isSubmitted = true
    setTimeout(() => {
      if (this.isSubmitted && this.qualifIncidentForm.valid) {
        Swal.fire({
          title: "Opération est en cours!",
          heightAuto: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
        const body = this.qualifIncidentForm.value;
        body["id_incident"] = this.id_incident
        body["id_residence"] = this.id_residence;
        body["code_etape"] = 'QUAL';
        this.listincidentservice.qualifierIncident(body).subscribe(success => {
          this.showMessageSuc(`Opération réussie`);
        }, error => {
          this.showMessageError();
        })
      }
    }, 100);
  }

  showMessageSuc(msg) {
    Swal.fire({
      icon: 'success',
      text: msg,
      showConfirmButton: true,
      heightAuto: false,
      timer: 4000,
    });
    this.dialogRef.close(true)
  }

  showMessageError() {
    Swal.fire({
      icon: 'error',
      title: `Une erreur est survenue`,
      text: 'Veuillez réessayer plus tard',
      showConfirmButton: false,
      heightAuto: false,
      timer: 2500
    });
  }
}
