import { AlertsService } from './../../service/alerts/alerts.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { CurrentUserService } from 'app/service/current-user.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import Swal from 'sweetalert2';
import { SharedService } from 'app/service/shared-service/shared.service';
import { CookieStoreService } from 'app/service/cookieService/cookieService.service';

export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = 'Objets par page';

  return customPaginatorIntl;
}


@Component({
  selector: 'ms-fiche-alerts',
  templateUrl: './fiche-alerts.component.html',
  styleUrls: ['./fiche-alerts.component.scss']
})
export class FicheAlertsComponent implements OnInit {

  Alert
  userItem
  idAlert 
  isLoading
  id_residence
  subscription

  constructor(
    private pageTitleService    : PageTitleService      ,
    private _currentUser        : CurrentUserService    ,
    private alertsService       : AlertsService         ,
    private formBuilder         : FormBuilder           ,
    private route               : ActivatedRoute        ,
    public  dialog              : MatDialog             ,
    private cookies             : CookieStoreService    ,
    public sharedService : SharedService                ,
  ) { 
      this.userItem   = this._currentUser.getRoleCurrentUser();
      this.idAlert = this.route.snapshot.paramMap.get('id_alert');
  }

  ngOnInit(): void {
    setTimeout(()=>{
      this.pageTitleService.setTitle("Fiche Alerte");
    },0)
    this.id_residence = this.cookies.getTOKEN("id_residence");
    this.getAlert();
  }
  
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getAlert() {
    this.isLoading = true;
    let body = {
      "id_residence" : parseInt(this.id_residence, 10),
    };
    this.alertsService.getFicheAlert(this.idAlert, body)
      .subscribe((res: any) => {            
        this.Alert = res[0];        
        this.isLoading = false;
      }, err => {
        this.showMessageError();
      })
  }

  showMessageError() {
    Swal.fire({
      icon: 'error',
      title: `Une erreur est survenue`,
      text: 'Veuillez réessayer plus tard',
      showConfirmButton: false,
      heightAuto: false,
      timer: 2500
    });
  }

}
