import { Injectable } from "@angular/core";
import { ApiService } from "../api-service/api.service";
import { HttpClient } from "@angular/common/http";
import { Apis } from "../API";

@Injectable({
  providedIn: "root",
})
export class DemandesRequetesService {
  constructor(private apiService: ApiService, private http: HttpClient) {}

  getListeDemandes(records_per_page: number, page_number: number, body: any) {
    return this.apiService.post(
      `${Apis.backoffice.liste_demandes}/${records_per_page}/${page_number}`,
      body
    );
  }
  getFicheDemandes(id_demande,body) {
    return this.apiService.post(`${Apis.backoffice.fiche_demande}/${id_demande}`,body);
  }
  ajouterRequest(body) {
    return this.apiService.post(`${Apis.backoffice.ajouter_request}`,body);
  }
  ajouterDemande(body) {
    return this.apiService.post(`${Apis.backoffice.ajouter_demande}`,body);
  }
  cloturerDemande(id_demande,body) {
    return this.apiService.post(`${Apis.backoffice.cloturer_demande}/${id_demande}`,body);
  }
}
