import { Injectable, EventEmitter  } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  

  constructor() { }
  private selectedIdResidenceBehavior = new BehaviorSubject<string>('initialValue');
  private selectedIdResidence = new Subject<string>();

  updateSelectedIdResidence(value: string): void {
    this.selectedIdResidence.next(value);
    this.selectedIdResidenceBehavior.next(value);
  }
  getSelectedIdResidence(): Observable<string> {
    return this.selectedIdResidence.asObservable();
  }

  getSelectedIdResidenceBehavior(): Observable<string> {
    return this.selectedIdResidenceBehavior.asObservable();
  }

}
