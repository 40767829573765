import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../api-service/api.service';
import { Apis } from '../API';

@Injectable({
  providedIn: 'root'
})
export class ListeCodifService {

  constructor(private apiService: ApiService, private http: HttpClient) { }
  
  getCodification(body) {
    return this.apiService.post(`${Apis.main.getCodification}`, body)
  }
  getCodifParametrages(records_per_page, page_number,body){
    return this.apiService.post(`${Apis.main.getCodifParametrages}${records_per_page}/${page_number}`, body)
  }
  activerCodif(body){
    return this.apiService.post(`${Apis.main.activerCodifParametrage}`, body)
  }
  desactiverCodif(body){
    return this.apiService.post(`${Apis.main.desactiverCodifParametrage}`, body)
  }
  ajouterCodification(body) {
    return this.apiService.post(`${Apis.main.ajouterCodifParametrage}`, body)
  }
  modifierCodification(body) {
    return this.apiService.post(`${Apis.main.modifierCodifParametrage}`, body)
  }
  getStatut(body){
    return this.apiService.post(`${Apis.main.list_parametrage}`,body)
  }
}
