import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CookieStoreService } from 'app/service/cookieService/cookieService.service';
import { ResidencesService } from 'app/service/residences/residences.service';
import { UtilisateurService } from 'app/service/utilisateur-service/utilisateur.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'ms-modifier-metier-agent',
  templateUrl: './modifier-metier-agent.component.html',
  styleUrls: ['./modifier-metier-agent.component.scss']
})
export class ModifierMetierAgentComponent implements OnInit {
  
  listeMetiers: any = [];
  id_residence: any;
  metiers: any = null;
  fiche_agent: any;

  constructor(
    public dialogRef: MatDialogRef<ModifierMetierAgentComponent>,
    private residencesService     : ResidencesService,
    private utilisateurService     : UtilisateurService,
    private formBuilder: FormBuilder,
    private cookies : CookieStoreService
  ) { }

  ngOnInit(): void {
    this.getListeMetiers();
    this.metiers = this.fiche_agent.code_metier
  }

  getListeMetiers() {
    this.listeMetiers = [];
      let body ={
        "id_residence" : this.id_residence
      }
      this.residencesService.getListeMetiersResidence(body)
        .subscribe(async (res: any) => {
          this.listeMetiers = await res.liste_metiers_residence;
        })
  }

  modifierMetiers() {
    let body = {
      id_affectation : this.fiche_agent.id_affectation,
      id_residence : this.fiche_agent.id_residence,
      id_compte : this.fiche_agent.id_compte,
      metiers : this.metiers
    }
    
    this.utilisateurService.modifierMetiers(body).subscribe(async (res: any) => {
      this.showSuccessMessage("Metiers Modifié!")
      this.dialogRef.close();
    }, err => {
      console.log(err);
      this.showMessageError("Une erreur s'est produite. veuillez réessayer ultérieurement.");
    })

  }

  showSuccessMessage(msg) {
    Swal.fire({
      icon: 'success',
      text: msg,
      showConfirmButton: false,
      heightAuto: false,
      timer: 2500,
    });
  }

  showMessageError(msg) {
    Swal.fire({
      icon: 'error',
      text: msg,
      showConfirmButton: false,
      heightAuto: false,
      timer: 2500,
    });
  }

}
