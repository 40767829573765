import { Component, OnInit, ViewChild } from '@angular/core';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { FormBuilder,FormGroup,FormControl} from '@angular/forms';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { ListresidentService} from 'app/service/resident/listresident.service';
import { ListIncidentService} from 'app/service/list-incident-service/list-incident.service';
import { CurrentUserService } from 'app/service/current-user.service';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { CookieStoreService } from 'app/service/cookieService/cookieService.service';
import { SharedService } from 'app/service/shared-service/shared.service';
import { AjouterResidentComponent } from '../ajouter-resident/ajouter-resident.component';

export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = 'Objets par page';

  return customPaginatorIntl;
}


@Component({
  selector: 'ms-list-resident',
  templateUrl: './list-resident.component.html',
  styleUrls: ['./list-resident.component.scss']
})
export class ListResidentComponent implements OnInit {
  ResidentForm
  records_per_page
  page_number
  totalResident
  isLoading
  ListResident
  ListStatut
  pageSize = 20
  pageIndex = 0
  listeResidence
  userItem
  id_residence
  libelle_occupant;
  subscription: any;
  
  constructor(
    private pageTitleService      : PageTitleService    ,
    private formBuilder           : FormBuilder         ,
    private listResidentService   : ListresidentService ,
    private listincidentservice   : ListIncidentService ,
    private _currentUser          : CurrentUserService  , 
    public  dialog                : MatDialog           ,
    private cookies: CookieStoreService,
    public sharedService : SharedService,

  ) {


    this.userItem = this._currentUser.getRoleCurrentUser();

    this.ResidentForm = new FormGroup({
      code_statut_affectation : new FormControl(),
      nom_compte : new FormControl(),
      tel_compte : new FormControl(),
      Residence : new FormControl(),
    });
  }

  ngOnInit(): void {
    setTimeout(()=>{
      this.pageTitleService.setTitle("Liste des résidents");
    },0)
    // this.getListResident(this.pageSize, this.pageIndex);
    this.getStatut();
    this.id_residence = this.cookies.getTOKEN("id_residence");
    this.libelle_occupant = this.cookies.getTOKEN("libelle_occupant").replace(/"/g, '');
    this.subscription = this.sharedService.getSelectedIdResidence().subscribe(value => {
      this.id_residence = value;
      this.getListResident(this.pageSize, this.pageIndex);
    });      
    setTimeout(() => {
      this.getListResident(this.pageSize, this.pageIndex);
    }, 100);
  }
  
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getListResident(records_per_page, page_number) {
    this.isLoading = true;
    this.ListResident = []
    
    let body = {
      "flag_actif"  : null,
      "solde"       : null,
      "code_statut_affectation" : this.ResidentForm.controls.code_statut_affectation?.value || null,
      "nom_compte" : this.ResidentForm.controls.nom_compte?.value || null,
      "tel_compte" : this.ResidentForm.controls.tel_compte?.value || null,
      "code_role_compte": "RESI",
      "id_residence"  : this.id_residence,
      "showInactive" : "O"
    };
    this.listResidentService.getListResident(records_per_page, page_number, body)
      .subscribe((res: any) => {
        this.ListResident = res.residents;
        this.totalResident = res.nbr_total_residents;
        // console.log(this.ListResident)
        this.isLoading = false;
      }, err => {
        this.showMessageError();
        this.isLoading = false;
      })
  }

  ajouter_resident() {
    let  dialogRef =this.dialog.open(AjouterResidentComponent,{
      width :"700px",
      maxHeight: "80vh"});
    dialogRef.afterClosed().subscribe(result => {
      this.pageIndex = 0;
      this.getListResident(this.pageSize, this.pageIndex);
    })
  }

  showMessageError() {
    Swal.fire({
      icon: 'error',
      text: `une erreur s'est produite. veuillez réessayer ultérieurement.`,
      showConfirmButton: false,
      heightAuto: false,
      timer: 2500,
    });
  }


  initIncidentForm() {
    this.ResidentForm = this.formBuilder.group({
      code_statut_affectation : [null],
      nom_compte : [null],
      tel_compte : [null],
      Residence            : [null],
      Solde                : [null],
  });
  this.getListResident(this.pageSize, this.pageIndex);
}


  filtrer() {
    this.pageIndex = 0;
    this.getListResident(this.pageSize, this.pageIndex);
  }

  reset() {
    this.initIncidentForm();
    this.pageIndex = 0;
    this.getListResident(this.pageSize, this.pageIndex);
  }

  getNextPage(event) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getListResident(event.pageSize, event.pageIndex);
  }

  getStatut() {
    this.ListStatut = [];
      let body ={
        "type_parametrages" : "STAF"
      }
      this.listincidentservice.getStatut(body)
        .subscribe((res: any) => {
          this.ListStatut = res.parametrage;
        })
  }

}
