import { AlertsService } from "./../../service/alerts/alerts.service";
import { ListeCodifService } from "app/service/liste-codif-service/liste-codif.service";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators} from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { CookieStoreService } from "app/service/cookieService/cookieService.service";
import * as moment from "moment";
import Swal from "sweetalert2";
import { SharedService } from "app/service/shared-service/shared.service";
import { NewsServiceService } from "app/service/news-service/news-service.service";
import { MatOption } from "@angular/material/core";

@Component({
  selector: "ms-ajouter-alert",
  templateUrl: "./ajouter-alert.component.html",
  styleUrls: ["./ajouter-alert.component.scss"],
})
export class AjouterAlertComponent implements OnInit {
  body;
  id_residence;
  populationList: any = [];
  @ViewChild('allSelected') private allSelected: MatOption;
  constructor(
    public dialogRef: MatDialogRef<AjouterAlertComponent>,
    private alertsService: AlertsService,
    private newsService : NewsServiceService,
    public sharedService : SharedService,
    private cookies: CookieStoreService,
  ) {}

  ngOnInit() {
    this.sharedService.getSelectedIdResidenceBehavior().subscribe(value => {
      this.id_residence = value;
    });
    this.getPopulation()
  }

  alert = new FormGroup({
    titre_alert: new FormControl("", Validators.required),
    alert_population: new FormControl("", Validators.required),
    description_alert: new FormControl("", Validators.required),
    informing_type: new FormControl("NOTIF", Validators.required),
  });

  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.alert.controls.alert_population.patchValue([...this.populationList.map(item => item), 0]);
    } else {
      this.alert.controls.alert_population.patchValue([]);
    }
  }

  tosslePerOne(all){ 
    if (this.allSelected.selected) {  
     this.allSelected.deselect();
     return false;
    }
    if(this.alert.controls.alert_population.value.length==this.populationList.length)
      this.allSelected.select();
  }

  getPopulation(){
    this.newsService.getPopulation({"id_residence": this.cookies.getTOKEN("id_residence")})
      .subscribe((res: any) => {
        let List
        if (res.liste_immeuble_villas.immeubles.length > 0) {
          List = res.liste_immeuble_villas.immeubles;
        } else {
          List = res.liste_immeuble_villas.villas;
        }

        for (let i=0 ; i < List.length; i++){          
          this.populationList.push(List[i])
        }       
      })
  }

  AjouterAlert() {
    if (this.alert.valid) {

      Swal.fire({
        title: `êtes-vous sûr de vouloir envoyer cette alerte?`,
        icon : "warning",
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        showLoaderOnConfirm: true,
        preConfirm: () => {
            
          Swal.fire({
            title: "Opération est en cours!",
            heightAuto: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
    
          if(this.alert.get('alert_population').value.includes(0)) {
            this.alert.get('alert_population').setValue(null);
          }
    
          this.body = {
            titre_alert: this.alert.get("titre_alert").value,
            description_alert: this.alert.get("description_alert").value,
            informing_type : this.alert.get("informing_type").value,
            alert_population : this.alert.get("alert_population").value,
            id_residence: this.id_residence
          };
    
          this.alertsService.ajouterAlert(this.body).subscribe((result) => {
            if (result) {
              this.dialogRef.close({
                message_data: "Alerte ajoutée avec succès",
                message: "success",
              });
              Swal.fire(
                "Ajouté",
                "Alerte ajoutée avec succès.",
                "success"
              );
            }
          });

        }
      });

    }
  }
}
