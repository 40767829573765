import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from 'app/service/role-guard.guard';
import { FicheIncidentComponent } from './fiche-incident/fiche-incident.component';
import { ListeIncidentComponent } from './liste-incident/liste-incident.component'



const routes: Routes = [
  {
    path: '',
    redirectTo: 'liste-incident',
    pathMatch: 'full'
 },
 {
  path: '',
  canActivateChild: [RoleGuard],
  children: [
     {
        path: 'liste-incident',
        component: ListeIncidentComponent,
        data: { roles: ['ADMI','USER','AGENT','GEST'] }
     },
     {
      path: 'fiche/:id_incident',
      component: FicheIncidentComponent,
      data: { roles: ['ADMI','USER','AGENT','GEST'] }
     },
    ]
 }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IncidentRoutingModule { }
