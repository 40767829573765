import { AlertsService } from './../../service/alerts/alerts.service';
import { Component, OnInit } from '@angular/core';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { FormBuilder,FormGroup,FormControl} from '@angular/forms';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CurrentUserService } from 'app/service/current-user.service';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import * as moment from 'moment'
import { CookieStoreService } from 'app/service/cookieService/cookieService.service';
import { SharedService } from 'app/service/shared-service/shared.service';
import { Subscription } from 'rxjs';
import { UtilisateurService } from 'app/service/utilisateur-service/utilisateur.service';
import { AjouterAlertComponent } from '../ajouter-alert/ajouter-alert.component';

export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = 'Objets par page';

  return customPaginatorIntl;
}

@Component({
  selector: 'ms-list-alerts',
  templateUrl: './list-alerts.component.html',
  styleUrls: ['./list-alerts.component.scss']
})
export class ListAlertsComponent implements OnInit {

  userItem
  isLoading
  alertForm : FormGroup
  listalert
  pageIndex = 0
  pageSize  = 10
  totalalert
  exampleHeader
  dateAlert = moment(new Date()).format('MM/DD/YYYY');
  id_residence;
  private subscription: Subscription;
  role_user: any;
  disabledStates: { [key: string]: boolean } = {};
  constructor(
    private pageTitleService      : PageTitleService    ,
    private formBuilder           : FormBuilder         ,
    private _currentUser          : CurrentUserService  , 
    public  dialog                : MatDialog           ,
    private alertsService         : AlertsService       ,
    private cookies : CookieStoreService,
    public sharedService : SharedService,
  ) {
    this.userItem = this._currentUser.getRoleCurrentUser();

    this.alertForm = new FormGroup(
      {
        id_alert      : new FormControl(),
        flag_actif           : new FormControl(),
        mot_cle              : new FormControl(),
        datealert            : new FormControl()
      }
    );
   }

  ngOnInit(): void {
    setTimeout(()=>{
      this.pageTitleService.setTitle("Liste des alertes");
    },0)
    
    this.id_residence = this.cookies.getTOKEN("id_residence");
    this.initIncidentForm();
    this.getlistalert(this.pageSize, this.pageIndex);
  }
  
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  
  getlistalert (records_per_page, page_number) {
    this.isLoading = true;
    this.listalert = []
    
    let body = {
      "id_residence"    : this.id_residence,
      "mot_cle"         : this.alertForm.get("mot_cle").value,
      "id_alert" : this.alertForm.get("id_alert").value,
      "flag_actif"      : this.alertForm.get("flag_actif").value,
      "code_type_objet" : "ALER",
      "date"            : this.alertForm.get("datealert").value,
      "showAll" : true,
    };    
    this.alertsService.getListAlert(records_per_page, page_number, body)
      .subscribe((res: any) => {
        this.listalert = res.list_alerts;
        this.totalalert = res.nbr_total_alert;
        this.isLoading = false; 
      }, err => {
        this.showMessageError();
        this.isLoading = false;
      })
  }

  addAlert() {
    let  dialogRef =this.dialog.open(AjouterAlertComponent,{
      width: '800px',
      autoFocus: false,
      maxHeight: '85vh'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.pageIndex = 0;
        this.getlistalert(this.pageSize, this.pageIndex);
      }
    })
  }

  desactivateAlert(id_alert) {

    Swal.fire({
        title: `êtes-vous sûr de vouloir désactiver cette alerte?`,
        icon : "warning",
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        showLoaderOnConfirm: true,
        preConfirm: () => {
            
          Swal.fire({
              title: "Opération est en cours!",
              heightAuto: false,
              didOpen: () => {
                  Swal.showLoading();
              }
          });
            let body = {
                id_residence : this.id_residence
            }
            this.alertsService.desactivateAlert(id_alert,body).subscribe(res => {
              this.showMessageSuc("Alerte désactivé!")
              this.reset()
              this.disabledStates[id_alert] = true;
            }, error => {
              this.showMessageError()
            })

        }
    });

  }
  
  showMessageError() {
    Swal.fire({
      icon: 'error',
      text: `une erreur s'est produite. veuillez réessayer ultérieurement.`,
      showConfirmButton: false,
      heightAuto: false,
      timer: 2500,
    });
  }
  
  initIncidentForm() {
    this.alertForm = this.formBuilder.group({
      datealert            : [null],
      mot_cle              : [null],
      id_alert      : [null],
      flag_actif           : [null],
    });
  }

  showMessageSuc(msg) {
    Swal.fire({
      icon: 'success',
      text: msg,
      showConfirmButton: true,
      heightAuto: false,
      timer: 4000,
    });
  }

  reset() {
    this.initIncidentForm();
    this.pageIndex = 0;
    this.getlistalert(this.pageSize, this.pageIndex)
  }

  filtrer() {
    this.pageIndex = 0;
    this.getlistalert(this.pageSize, this.pageIndex);
  }

  getNextPage(event) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getlistalert(event.pageSize, event.pageIndex);
  }

}


