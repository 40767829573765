import { UtilisateurService } from 'app/service/utilisateur-service/utilisateur.service';
import { ChangeDetectorRef, Component, Input, OnInit, Output } from '@angular/core';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { FormBuilder,FormGroup} from '@angular/forms';
import { CurrentUserService } from 'app/service/current-user.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { CookieStoreService } from 'app/service/cookieService/cookieService.service';
import { SharedService } from 'app/service/shared-service/shared.service';
import { ListresidentService } from 'app/service/resident/listresident.service';
import { ListIncidentService } from 'app/service/list-incident-service/list-incident.service';
import { AjouterUtilisateurComponent } from '../ajouter-utilisateur/ajouter-utilisateur.component';
import { QrCodeModalComponent } from 'app/resident/qr-code-modal/qr-code-modal.component';
import { ModifierUtilisateurComponent } from '../modifier-utilisateur/modifier-utilisateur.component';
import { ModifierMetierAgentComponent } from '../modifier-metier-agent/modifier-metier-agent.component';
import { ResidencesService } from 'app/service/residences/residences.service';

@Component({
  selector: 'ms-fiche-utilisateur',
  templateUrl: './fiche-utilisateur.component.html',
  styleUrls: ['./fiche-utilisateur.component.scss']
})
export class FicheUtilisateurComponent implements OnInit {
  @Input() nbrTotalIncidents: number;
  isLoading
  Salarie
  id_compte 
  salarie
  pageSize = 5
  pageIndex = 0
  listIncident
  totalIncident
  id_residence
  subscription: any;
  listeEntreSortie: any;
  totalEntreSortie: any;
  nbr_liste_reservation: number;
  totalIncidents: number = 0;
  totalReservations: number = 0;
  modules: any;
  libelle_occupant;
  type:any;
  isToggleOn = false;

  toggleState: boolean = false; // Variable to store the state of the toggle
  toggleDisabled: boolean = false; // Variable to control the disabled state of the toggle

  originalToggleState: boolean;
  constructor(
    private pageTitleService    : PageTitleService    ,
    private formBuilder         : FormBuilder         ,
    private residencesService     : ResidencesService,
    private _currentUser        : CurrentUserService  ,
    private route               : ActivatedRoute      ,
    public  dialog              : MatDialog           ,
    private listincidentservice : ListIncidentService ,
    private utilisateurService  : UtilisateurService  ,
    private cookies: CookieStoreService               ,
    public dialogRef: MatDialogRef<FicheUtilisateurComponent>,
    public sharedService : SharedService              
  ) {
    this.id_compte = this.route.snapshot.paramMap.get('id_compte');
    this.originalToggleState =this.Salarie?.flag_afficher_resident === "O" ? true : false;
  }

  ngOnInit(): void {

    // setTimeout(()=>{
		// 	this.pageTitleService.setTitle("Fiche Salarié");
		// },100);
    
    this.id_residence = this.cookies.getTOKEN("id_residence");
    this.modules = this.cookies.getTOKEN("modules");

    this.getListIncident(this.pageSize,this.pageIndex)
    this.getFicheSalarie();
    this.libelle_occupant = this.cookies.getTOKEN("libelle_occupant").replace(/"/g, '');
    // this.getListIncident(this.pageSize, this.pageIndex);
  }

  onTotalIncidentsChanged(total: number) {
    console.log('Total Incidents  changed:', total);
    this.totalIncidents = total;
  }
  
  onTotalReservationsChanged(totalReserv: number) {
    console.log('Total reservations changed:', totalReserv);
    this.totalReservations = totalReserv;
  }


  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe(); 
    } 
  }
  getInitials(): string {
    if (this.Salarie?.nom_compte) {
      return this.Salarie?.nom_compte
        .split(' ')
        .map(name => name.charAt(0).toUpperCase())
        .join('');
    } else {
      return '';
    }
  }
  getFicheSalarie() {
    this.isLoading = true;
    let body = {
      "id_residence" : this.id_residence
    }
    this.utilisateurService.getFicheSalarie(this.id_compte,body)
      .subscribe((res: any) => {

        this.Salarie = res.fiche_salarie;
        this.isToggleOn = this.Salarie.flag_afficher_resident === 'O'
        this.isLoading = false;
        
        setTimeout(()=>{
          this.pageTitleService.setTitle(`Fiche ${this.Salarie.code_role_compte === "GEST" ? "Gestionnaire" : "Agent"}`);
        },100);

      }, err => {
        this.showMessageError();
      })
  }

  showMessageError() {
    Swal.fire({
      icon: 'error',
      title: `Une erreur est survenue`,
      text: 'Veuillez réessayer plus tard',
      showConfirmButton: false,
      heightAuto: false,
      timer: 2500
    });
  }

  getNextPage(event) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    // this.getListIncident(event.pageSize, event.pageIndex);
  }

  getListIncident(records_per_page,page_number) {
    this.isLoading = true;
    let body = {
      "idCompteContributed" : this.id_compte,
      "fetchContributedIncidentsOnly": "O",
      "id_residence" : this.id_residence
    };
    this.listincidentservice.getListIncident(records_per_page, page_number, body)
      .subscribe((res: any) => {
        this.listIncident = res.incidents;
        this.totalIncident = res.nbr_total_incidents;
        this.isLoading = false;
      }, err => {
        this.isLoading = false;
      })
  }
  
  // modifier_utilisateur(Salarie: any) {
  //   let  dialogRef = this.dialog.open(AjouterUtilisateurComponent,{
  //       width :"700px",
  //       maxHeight: "80vh"
  //   });
  //   dialogRef.componentInstance.data_salarie =  Salarie;
  //   dialogRef.afterClosed().subscribe(result => {
  //     if(result) {
  //       this.getFicheSalarie();
  //     }
  //   })
  // }
  onToggleChange() {
    if (!this.toggleDisabled) {
        // Revert the state of the toggle if it's not confirmed yet
        this.toggleState = !this.toggleState;
    }
  }
  afficherAuResi() {
    
    const action = this.isToggleOn ? 'afficher agent' : 'affiche pas Agent au Résident!' ;
    this.type="salarie";
    Swal.fire({
        title: `Êtes-vous sûr d\'${action} au Résident?`,
        icon : "warning",
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.toggleDisabled = true;
           Swal.fire({
            title: "Opération est en cours!",
            heightAuto: false,
            didOpen: () => {
                Swal.showLoading();
            }
            });
            let body = {
              id_affectation:this.Salarie.id_affectation,
              flag_afficher_resident: this.Salarie.flag_afficher_resident === "O" ? false : true,
              type: this.type
            }
            this.residencesService.ModifierCompte(body)
            .subscribe((res: any) => {
              this.showMessageSuc(`Opération réussie`);
              this.getFicheSalarie();
              this.toggleDisabled = false;
            },error => {
              this.showMessageError();
              this.toggleDisabled = false;
            })
        }
    }).then((result) => {
      this.getFicheSalarie();
    });
    
  }

  validerSalarie() {

    Swal.fire({
        title: 'êtes-vous sûr de vouloir valider ce salarié?',
        icon : "warning",
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        showLoaderOnConfirm: true,
        preConfirm: () => {
            
           Swal.fire({
            title: "Opération est en cours!",
            heightAuto: false,
            didOpen: () => {
                Swal.showLoading();
            }
            });
            let body = {
            id_residence : this.id_residence,
            id_affectation : this.Salarie.id_affectation,
            }
            this.utilisateurService.validerAffectation(body).subscribe(res => {
            this.getFicheSalarie();
            this.showMessageSuc(`Opération réussie`);
            location.reload();
            }, error => {
            this.showMessageError();
            })

        }
    });

    
  }

  modifier_salarie(Salarie: any, type: any) {
    const dialogRef = this.dialog.open(ModifierUtilisateurComponent,{
        width :"700px",
        maxHeight: "80vh"
    });
    dialogRef.componentInstance.data =  Salarie;
    dialogRef.componentInstance.type =  type;
    dialogRef.componentInstance.role =  Salarie.code_role_compte;
    dialogRef.afterClosed().subscribe(result => {
      this.getFicheSalarie();
    })
  }

  modifierMetiersAgent() {
    let  dialogRef = this.dialog.open(ModifierMetierAgentComponent,{
        width :"700px",
        maxHeight: "80vh"
    });
    dialogRef.componentInstance.id_residence = this.id_residence
    dialogRef.componentInstance.fiche_agent = this.Salarie
    dialogRef.afterClosed().subscribe(result => {
      this.getFicheSalarie();
    })
  }

  desactiverSalarie() {

    Swal.fire({
        title: 'êtes-vous sûr de vouloir désactiver ce salarié?',
        icon : "warning",
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        showLoaderOnConfirm: true,
        preConfirm: () => {
            
           Swal.fire({
            title: "Opération est en cours!",
            heightAuto: false,
            didOpen: () => {
                Swal.showLoading();
            }
            });
            let body = {
            id_residence : this.id_residence,
            id_affectation : this.Salarie.id_affectation,
            }
            this.utilisateurService.desactiverAffectation(body).subscribe(res => {
            this.getFicheSalarie()
            this.showMessageSuc(`Opération réussie`);
            }, error => {
            this.showMessageError();
            })

        }
    });

    
  }

  rejeterSalarie() {

    Swal.fire({
        title: 'êtes-vous sûr de vouloir rejeter ce salarié?',
        icon : "warning",
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        showLoaderOnConfirm: true,
        preConfirm: () => {
            
           Swal.fire({
                title: "Opération est en cours!",
                heightAuto: false,
                didOpen: () => {
                    Swal.showLoading();
                }
                });
                let body = {
                id_residence : this.id_residence,
                id_affectation : this.Salarie.id_affectation,
                }
                this.utilisateurService.rejeterAffectation(body).subscribe(res => {
                this.getFicheSalarie()
                this.showMessageSuc(`Opération réussie`);
                }, error => {
                this.showMessageError();
                })

        }
    });

    
  }

  activerSalarie(flag : any) {

    Swal.fire({
        title: `êtes-vous sûr de vouloir ${flag === 'O' ? "activer l'accés à l'application pour" : "désactiver l'accés à l'application pour" } ce salarié?`,
        icon : "warning",
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        showLoaderOnConfirm: true,
        preConfirm: () => {
            
           Swal.fire({
              title: "Opération est en cours!",
              heightAuto: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });
            let body = {
              id_residence : this.id_residence,
              id_affectation : this.Salarie.id_affectation,
              code_role_compte : this.Salarie.code_role_compte,
              flag_compte_gestionnaire : flag
            }
            this.utilisateurService.activerDesactiverAffectation(body).subscribe(res => {
            this.getFicheSalarie()
            this.showMessageSuc(`Opération réussie`);
            }, error => {
              if (error.error.code == "LIMIT") {
                this.showMessageWarning(`vous avez dépassé la limite des ${this.Salarie.code_role_compte == 'GEST' ? 'gestionnaires' : "agents"} actifs que vous pouvez ajouter pour cette société`)
              } else {
                this.showMessageError();
              }
            })

        }
    });

    
  }

  genererPwd() {

    Swal.fire({
        title: `êtes-vous sûr de vouloir générer un nouveau mot de passe pour cet utilisateur ??`,
        icon : "warning",
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        showLoaderOnConfirm: true,
        preConfirm: () => {
            
           Swal.fire({
              title: "Opération est en cours!",
              heightAuto: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });

            let body = {
              id_residence : this.id_residence,
              id_compte : this.Salarie.id_compte
            }
            this.utilisateurService.genererPwd(body).subscribe(res => {
              this.getFicheSalarie()
              this.showMessageSuc(`Opération réussie`);
            }, error => {
              this.showMessageError();
            })

        }
    });  
  }

  resetData(event) {
    if (event == true)
      this.getFicheSalarie()
  }

  showMessageWarning(msg) {
    Swal.fire({
      icon: 'warning',
      text: msg,
      showConfirmButton: false,
      heightAuto: false,
      timer: 5000,
    });
  }

  showMessageSuc(msg) {
    Swal.fire({
      icon: 'success',
      text: msg,
      showConfirmButton: true,
      heightAuto: false,
      timer: 4000,
    });
  }

  openQrDialog(qrcode : string){
    const dialogRef = this.dialog.open(QrCodeModalComponent, {
      width: '600px',
      autoFocus: false,
      height: '600px'
    });
    dialogRef.componentInstance.qrcode = qrcode;
  }

}
