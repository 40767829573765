import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../api-service/api.service';
import { Apis } from '../API';

@Injectable({
  providedIn: 'root'
})
export class UtilisateurService {

  constructor(private apiService: ApiService, private http: HttpClient) { }

  getUtilisateurs(records_per_page, page_number, body){
    return this.apiService.post(`${Apis.backoffice.liste_utilisateurs}/${records_per_page}/${page_number}`, body)
  }

  getResidence(){
    return this.apiService.get(`${Apis.main.list_residence}/99999999/0`)
  }

  getFicheAffectation(id_affectation,body) {
    return this.apiService.post(`${Apis.backoffice.affectation_fiche}/${id_affectation}`,body)
  }

  getFicheSalarie(id_compte,body) {
    return this.apiService.post(`${Apis.backoffice.salarie_fiche}/${id_compte}`,body)
  }

  getImmeuble(body) {
    return this.apiService.post(`${Apis.main.list_immeuble}`,body)
  }

  ajouterBien(body) {
    return this.apiService.post(`${Apis.main.ajouter_biens}`,body)
  }

  validerAffectation(body) {
    return this.apiService.post(`${Apis.backoffice.valider_affectation}`,body)
  }

  rejeterAffectation(body) {
    return this.apiService.post(`${Apis.backoffice.rejeter_affectation}`,body)
  }

  desactiverAffectation(body) {
    return this.apiService.post(`${Apis.backoffice.desactiver_affectation}`,body)
  }

  activerDesactiverAffectation(body) {
    return this.apiService.post(`${Apis.backoffice.activer_desactiver_residence}`,body)
  }

  getRoleUserPerResidence(body) {
    return this.apiService.post(`${Apis.backoffice.get_role}`,body)
  }

  getListeUtilisateurs(body) {
    return this.apiService.post(`${Apis.main.liste_utilisateur}`, body);
  }

  modifierMetiers(body) {
    return this.apiService.post(`${Apis.main.modifier_metiers}`, body);
  }

  genererPwd(body) {
    return this.apiService.post(`${Apis.main.generer_pwd}`, body);
  }
}
