import { HttpErrorResponse } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { ReservationService } from "app/service/reservation-service/reservation.service";
import { ToastrService } from "ngx-toastr";
import * as moment from "moment";
import Swal from "sweetalert2";
import { CookieStoreService } from "app/service/cookieService/cookieService.service";
import { ActivatedRoute } from "@angular/router";
import { PageTitleService } from "app/core/page-title/page-title.service";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "ms-fiche-reservation",
  templateUrl: "./fiche-reservation.component.html",
  styleUrls: ["./fiche-reservation.component.scss"],
})
export class FicheReservationComponent implements OnInit {
  reservation: any;
  isLoading : any = false;
  id: string | number;
  id_residence: string | number;
  @Input() fiche: any = { isfiche: false, id_compte: null };
  steps: any = [];
  constructor(
    private reservationService: ReservationService,
    private toastr: ToastrService,
    private cookies: CookieStoreService,
    private route: ActivatedRoute,
    private pageTitleService: PageTitleService,
    public dialogRef: MatDialogRef<FicheReservationComponent>
  ) {
    // this.id = this.route.snapshot.paramMap.get("id_reservation");
  }

  ngOnInit(): void {
    this.id_residence = this.cookies.getTOKEN("id_residence");
    if (!this.fiche.isFiche) {
      setTimeout(() => {
        this.pageTitleService.setTitle("Fiche réservations");
      }, 0);
      this.getReservation();
    }
  }

  generateSteps(objectData): void {
    this.steps = [];
    let creationStep = {
      name: "creation",
      color: "#2929ff",
      data: {
        nom: objectData.createur_nom_compte,
        date: objectData.date_creation,
      },
    };
    this.steps.push(creationStep);

    if (objectData.date_validation && !objectData.date_rejection) {
      const validationStep = {
        name: "validation",
        color: "#29b400",
        data: {
          nom: objectData.nom_validateur,
          date: objectData.date_validation,
        },
      };
      this.steps.push(validationStep);
    }
    if (objectData.date_rejection) {
      const rejectionStep = {
        name: "rejet",
        color: "#e74c3c",
        data: {
          nom: objectData.nom_rejecteur,
          date: objectData.date_rejection,
        },
      };
      this.steps.push(rejectionStep);
    }
    if (objectData.date_annulation) {
      const annulationStep = {
        name: "annulation",
        color: "#c0392b",
        data: {
          nom: objectData.nom_annulateur,
          date: objectData.date_annulation,
        },
      };
      this.steps.push(annulationStep);
    }
    if (!objectData.date_validation && !objectData.date_rejection && !objectData.date_annulation) {
      const nodataStep = {
        name: "Validation",
        color: "#e2e2e2",
        data: {
          nom: "---",
          date: "---",
        },
      };
      this.steps.push(nodataStep);
    }
  }

  getReservation() {
    this.isLoading = true
    this.reservationService
      .getFiche(this.id, { id_residence: this.id_residence })
      .subscribe({
        next: (response) => {
          this.reservation = response[0];
          this.generateSteps(this.reservation);
          this.isLoading = false
        },
        error: (error: HttpErrorResponse) => {
          this.toastr.error(error.error.message ?? "Internal Error!");
        },
      });
  }

  formatTime(time: string | undefined) {
    if (time) {
      const parsedTime: Date = new Date(`2023-01-01T${time}`);
      return moment(parsedTime).format("H:mm");
    } else return "-";
  }

  formatDate(date: string | undefined) {
    if (date) {
      const parsedTime: Date = new Date(date);
      return moment(parsedTime).format("YYYY-MM-DD H:mm");
    } else return "-";
  }

  validerReservation() {
    Swal.fire({
      title: `êtes-vous sûr de vouloir valider cette réservation?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        Swal.fire({
          title: "Opération est en cours!",
          heightAuto: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        let body = {
          id_residence: this.id_residence,
          id_reservation: this.id,
        };
        this.reservationService.validerReservation(body).subscribe(
          (res) => {
            this.getReservation();
            this.showMessageSuc(`Opération réussie`);
          },
          (error) => {
            this.showMessageError();
          }
        );
      },
    });
  }

  rejeterReservation() {
    Swal.fire({
      title: "Veuillez clarifier le motif du rejet",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      heightAuto: false,
      confirmButtonText: "Rejeter",
      showLoaderOnConfirm: true,
      preConfirm: (motif) => {
        Swal.fire({
          title: "Opération est en cours!",
          heightAuto: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        let body = {
          id_residence: this.id_residence,
          id_reservation: this.id,
          motif,
        };
        this.reservationService.rejeterReservation(body).subscribe(
          (res) => {
            this.getReservation();
            this.showMessageSuc(`Réservation rejetée avec succès`);
          },
          (error) => {
            this.showMessageError();
          }
        );
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  annulerReservation() {
    Swal.fire({
      title: `êtes-vous sûr de vouloir annuler cette réservation?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        Swal.fire({
          title: "Opération est en cours!",
          heightAuto: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const body = {
          id_reservation: this.id,
          id_residence: this.id_residence,
        };
        this.reservationService.annulerReservation(body).subscribe(
          (res) => {
            this.getReservation();
            this.showMessageWarn(`Réservation annulée`);
          },
          (error) => {
            this.showMessageError();
          }
        );
      },
    });
  }

  showMessageSuc(msg: string) {
    Swal.fire({
      icon: "success",
      text: msg,
      showConfirmButton: true,
      heightAuto: false,
      timer: 4000,
    });
  }

  showMessageError() {
    Swal.fire({
      icon: "error",
      text: `une erreur s'est produite. veuillez réessayer ultérieurement.`,
      showConfirmButton: false,
      heightAuto: false,
      timer: 2500,
    });
  }

  showMessageWarn(msg: string) {
    Swal.fire({
      icon: "warning",
      text: msg,
      showConfirmButton: true,
      heightAuto: false,
      timer: 4000,
    });
  }
}
