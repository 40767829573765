import { ListeCodifService } from 'app/service/liste-codif-service/liste-codif.service';
import { Component, Inject, Input, OnInit, ViewChild, } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core/option';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CookieStoreService } from 'app/service/cookieService/cookieService.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { InfoUtilesService } from 'app/service/info-utiles/info-utiles-service.service';
import { ResidencesService } from 'app/service/residences/residences.service';

@Component({
  selector: 'ms-ajouter-utilisateur',
  templateUrl: './ajouter-utilisateur.component.html',
  styleUrls: ['./ajouter-utilisateur.component.scss']
})
export class AjouterUtilisateurComponent implements OnInit {
  @Input() data_salarie?: any;
  body;
  id_residence;
  utilisateurForm : FormGroup;
  listeTypeutilisateur: any;
  listeTypesInfoUtiles: any[];
  listeCategoriesInfoUtiles: any[];
  iso2 : string = "MA";
  dialCode: any = "212";
  liste_biens: any;
  liste_etages: any;
  liste_immeubles: any;
  listeMetiers: any[];
  liste_roles = [
    { code_role : "GEST", libelle_role : "Gestionnaire" },
    { code_role : "AGEN", libelle_role : "Agent" }
  ];
  liste_type_agent: any;
  roleSelected = null
  libelle_site_short;
  flag_genre_site

  constructor(public dialogRef: MatDialogRef<AjouterUtilisateurComponent> ,
    private listeCodifService     : ListeCodifService   ,
    private residencesService     : ResidencesService,
    private formBuilder: FormBuilder,
    private cookies : CookieStoreService
    ){
      this.utilisateurForm = this.formBuilder.group({
        code_role_compte: [this.roleSelected ? this.roleSelected : '', [Validators.required]],
        type_agent: [null],
        code_metier: [null],
        nom_compte: ['', [Validators.required]],
        tel_compte: ['', [Validators.required, this.numericAndMaxLengthValidator(15)]],
        email: [null,[Validators.email]],
        flag_compte_gestionnaire : ["N"],
        cin_compte: [null, [Validators.maxLength(8)]],
        nom_societe : [null],
      });
      this.utilisateurForm.get("nom_societe").disable()
    }

  
  async ngOnInit(): Promise<void> {
    
    this.get_types_agent();
    this.id_residence = this.cookies.getTOKEN("id_residence");
    this.libelle_site_short = this.cookies.getTOKEN("libelle_site_short").replace(/"/g, '');
    this.flag_genre_site = this.cookies.getTOKEN("flag_genre_site").replace(/"/g, '');
    this.getListeMetiers();
    this.initFormSalarie();
    this.residencesService.getBiensResidences({id_residence : this.id_residence}).subscribe(res => {
      this.liste_immeubles = res.liste_immeuble_villas.immeubles
      // this.liste_etages = this.liste_immeubles[0].nbr_etages
      // this.liste_biens = this.liste_etages[0].biens
    })
  }
  initFormSalarie() {
      this.utilisateurForm = this.formBuilder.group({
        code_role_compte: [this.data_salarie ? this.data_salarie?.code_role_compte: (this.roleSelected ? this.roleSelected : ''), [Validators.required]],
        type_agent: [this.data_salarie ? this.data_salarie?.type_agent: '', [Validators.required]],
        code_metier: [this.data_salarie ? this.data_salarie?.code_metier: null],
        nom_compte: [this.data_salarie ? this.data_salarie?.nom_compte: null, [Validators.required]],
        tel_compte: [this.data_salarie ? this.data_salarie?.tel_compte: null, [Validators.required, this.numericAndMaxLengthValidator(15)]],
        email: [this.data_salarie ? this.data_salarie?.email: null],
        flag_compte_gestionnaire : ["N"],
        cin_compte: [this.data_salarie ? this.data_salarie?.cin_compte: null, [Validators.maxLength(8)]],
        nom_societe : [this.data_salarie ? this.data_salarie?.nom_societe: null],
      });
      
      if (this.roleSelected) {
        this.utilisateurForm.controls['code_role_compte'].disable();
      }
  }
  numericAndMaxLengthValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
  
      if (value === null || value === '') {
        return null;
      }
  
      const numericRegex = /^[0-9]+$/;
  
      if (!numericRegex.test(value) || value.length > maxLength) {
        return { 'numericAndMaxLength': true }; 
      }
  
      return null;
    };
  }

  onCountryChange(event){
    this.dialCode = event.dialCode; 
    this.iso2 = event.iso2.toUpperCase();
  }

  immeubleChanged() {
    this.utilisateurForm.controls["num_etage"].setValue(null)
    this.utilisateurForm.controls["reference_bien"].setValue(null)
    this.liste_biens = []
    this.liste_etages = this.liste_immeubles.find(i => i.reference_immeuble == this.utilisateurForm.controls["ref_immeuble"].value).nbr_etages
  }

  etageChanged() {
    this.liste_biens = this.liste_etages.find(e => e.nbr_etage == this.utilisateurForm.controls["num_etage"].value).biens
  }

  getListeMetiers() {
    this.listeMetiers = [];
      let body ={
        "id_residence" : this.id_residence
      }
      this.residencesService.getListeMetiersResidence(body)
        .subscribe(async (res: any) => {
          this.listeMetiers = await res.liste_metiers_residence;
          // console.log(this.listeMetiers);
          // this.utilisateurForm.controls.code_metier.setValue([this.data_salarie.code_metier]);
          // console.log(this.data_salarie.code_metier);
          // this.utilisateurForm.get('code_metier').updateValueAndValidity();
        })
  }
  updateValidator(event: any) {
    if(event.value == 'AGEN') {
      this.utilisateurForm.get('email').setValidators(null);
      this.utilisateurForm.get('email').updateValueAndValidity();
    }
    else {
      this.utilisateurForm.get('email').setValidators([Validators.email, Validators.required]);
      this.utilisateurForm.get('email').updateValueAndValidity();
    }
  }
  AjouterUtilisateur(){ 
    
    if (this.utilisateurForm.valid) {
      const formData = this.utilisateurForm.value;
      formData["id_residence"] = this.id_residence;
      formData["tel_compte"] = this.utilisateurForm.get('tel_compte').value;
      formData["code_role_compte"] = this.utilisateurForm.controls['code_role_compte'].value
      formData["dialCode"] = "+" + this.dialCode;
      formData["iso2"] = this.iso2;

      let numericRegex = /^[0-9]+$/;

      if (!numericRegex.test(formData["tel_compte"])) {
        return this.showMessageWarning("Le numéro de téléphone ne doit contenir que des chiffres!")
      }

      if (!formData["tel_compte"]) {
        return this.showMessageWarning("Téléphone est obligatoire!")
      }

      if (!["06","07"].includes(formData["tel_compte"].slice(0,2))) {
        return this.showMessageWarning("Le numéro de téléphone doit commencer par 06 ou 07")
      }

      if (this.utilisateurForm.get('code_role_compte').value == "AGEN" && !this.utilisateurForm.get('code_metier').value) {
        return this.showMessageWarning("Veuillez sélectionner un metier")
      }

      if (this.utilisateurForm.get('code_role_compte').value == "AGEN" && !this.utilisateurForm.get('type_agent').value) {
        return this.showMessageWarning("Veuillez sélectionner le type d'agent")
      }

      if (this.utilisateurForm.get('code_role_compte').value == "GEST" && !this.utilisateurForm.get('email').value) {
        return this.showMessageWarning("Email est obligatoire pour ajouter un gestionnaire")
      }

      this.residencesService.ajouterAffectationResident(formData)
      .subscribe((res: any) => {
        if (res.limit_exceeded) {
          this.showMessageWarning(`${formData["code_role_compte"] == 'GEST' ? 'Le gestionnaire' : "L'agent"} est assigné mais n'est pas actif parceque vous avez dépassé la limite des ${formData["code_role_compte"] == 'GEST' ? 'gestionnaires' : "agents"} actifs que vous pouvez ajouter pour cette société`)
        } else {
          this.showSuccessMessage("Utilisateur Affecté!")
        }
        this.dialogRef.close();
      }, err => {
        if (err.error.error_code == "ROLE") {
          this.showMessageWarning("Vous n'avez pas le droit d'ajouter un gestionnaire")
        } else {
          if (err.error.code === "EXIST") {
            this.showMessageWarning(`Vous êtes déjà affecté  ${this.flag_genre_site == "M" ? "sur ce" : "dans cette"} ${this.libelle_site_short} en tant que ${err.error.role === 'GEST' ? 'Gestionnaire' : 'Agent'}`)
          } else {
            this.showMessageError("Une erreur s'est produite. veuillez réessayer ultérieurement.");        
          }
    
        }
      })

      if(this.data_salarie) {
        formData["id_compte"] = this.data_salarie.id_compte;
        this.residencesService.modifierAffectationResident(formData)
      .subscribe((res: any) => {
        this.showSuccessMessage("Utilisateur Affecté!")
        this.dialogRef.close();
      }, err => {
        if (err.error.error_code == "ROLE") {
          this.showMessageWarning("Vous n'avez pas le droit d'ajouter un gestionnaire")
        } else {
          if (err.error.code === "EXIST") {
            this.showMessageWarning(`Vous êtes déjà affecté  ${this.flag_genre_site == "M" ? "sur ce" : "dans cette"} ${this.libelle_site_short} en tant que ${err.error.role === 'GEST' ? 'Gestionnaire' : 'Agent'}`)
          } else {
            this.showMessageError("Une erreur s'est produite. veuillez réessayer ultérieurement.");        
          }
    
        }
      })
      }
      
    } else {
      this.showMessageWarning("les données ne sont pas valides")
    }
  }

  showSuccessMessage(msg) {
    Swal.fire({
      icon: 'success',
      text: msg,
      showConfirmButton: false,
      heightAuto: false,
      timer: 2500,
    });
  }

  typeAgentChanged() {
    if (this.utilisateurForm.controls["type_agent"].value == "INTE") {
        this.utilisateurForm.controls["nom_societe"].setValue(null)
        this.utilisateurForm.controls["nom_societe"].disable()
    } else {
        this.utilisateurForm.controls["nom_societe"].enable()
    }
  }

  showMessageError(msg) {
    Swal.fire({
      icon: 'error',
      text: msg,
      showConfirmButton: false,
      heightAuto: false,
      timer: 2500,
    });
  }

  showMessageWarning(msg) {
    Swal.fire({
      icon: 'warning',
      text: msg,
      showConfirmButton: false,
      heightAuto: false,
      timer: 5000,
    });
  }

  get_types_agent () {
    this.liste_type_agent = [];
    this.listeCodifService.getStatut({type_parametrages : "TYAG"})
      .subscribe((res: any) => {
        this.liste_type_agent = res.parametrage;
      })
  }

}
