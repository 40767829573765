import { ResidencesService } from './../../../service/residences/residences.service';
import { Component, OnInit } from "@angular/core";
import { PageTitleService } from "app/core/page-title/page-title.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ListIncidentService } from "app/service/list-incident-service/list-incident.service";
import { CurrentUserService } from "app/service/current-user.service";
import { MatDialog } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
} from "@angular/common/http";
import Swal from "sweetalert2";
import { SharedService } from "app/service/shared-service/shared.service";
import { UtilisateurService } from "app/service/utilisateur-service/utilisateur.service";
import * as moment from "moment";
import { InterventionService } from "app/service/intervention/intervention.service";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";

@Component({
  selector: "ms-ajouter-intervention",
  templateUrl: "./ajouter-intervention.component.html",
  styleUrls: ["./ajouter-intervention.component.scss"],
})
export class AjouterInterventionComponent implements OnInit {
  cleIncident;
  isLoading = false;
  userItem;
  incident;
  form: FormGroup;
  listeMotifAbandon;
  isSubmitted: boolean;
  cle_incident: any;
  id_incident: any;
  selectedDate: any;
  selectedHour: any;
  selectedEndHour: any;
  hoursEnd: any = [];
  hours: any = [];
  images: any;
  imagesList: [];
  imagesListProv = [];
  ListIncident = [];
  photoUrls = [];
  imgUpload = [];
  selectedFiles = [];
  selectedDocuments = [];
  imgExtension;
  order;
  fd;
  description_incident;
  listeIntervenants = [];
  motif_abandon;
  traitIncidentForm;
  isTypeDisable: any;
  isCategorieDisable: any;
  isRapport: any = true;
  id_intervention: any;
  listeCategories = [];
  code_statut_intervention;
  intervention;
  deletedImages = [];
  code_type_interventions = [];
  code_categories = [];
  emitImages;
  id_residence;
  role_user;
  code_type_incident: any;
  code_categorie_prin: any;
  code_categorie_seco: any;
  code_origin_intervention: any;
  maxDate = new Date();
  minDate = new Date();

  constructor(
    private pageTitleService: PageTitleService,
    private formBuilder: FormBuilder,
    private listincidentservice: ListIncidentService,
    private _currentUser: CurrentUserService,
    private utilisateurService: UtilisateurService,
    public dialog: MatDialog,
    public sharedService: SharedService,
    public dialogRef: MatDialogRef<AjouterInterventionComponent>,
    private interventionService: InterventionService,
    private residencesService:ResidencesService
  ) {
    this.userItem = this._currentUser.getRoleCurrentUser();
    this.traitIncidentForm = new FormGroup({
      code_statut_intervention: new FormControl(
        this.isRapport ? "REAL" : "PLAN"
      ),
      code_categorie: new FormControl(
        null,
        !this.isRapport ? Validators.required : null
      ),
      code_type_intervention: new FormControl("CORR"),
      id_compte_agent: new FormControl(),
      date_planification: new FormControl(),
      date_realisation: new FormControl(
        "",
        this.isRapport ? Validators.required : null
      ),
      description_planification: new FormControl(),
      description_realisation: new FormControl(),
    });
  }

  ngOnInit(): void {
    this.sharedService.getSelectedIdResidenceBehavior().subscribe((value) => {
      this.id_residence = value;
      this.utilisateurService
        .getRoleUserPerResidence({ id_residence: this.id_residence })
        .subscribe((value) => {
          this.role_user = value;
        });
    });
    this.initForm();
    this.getCategorieIntervention();
    this.getTypeIntervention();
    this.getIntervenants();
    if (this.id_intervention) {
      this.getIntervention();
    }
    if (this.isRapport) {
      this.traitIncidentForm
        .get("date_realisation")
        .setValidators(Validators.required);
    } else {
      this.traitIncidentForm
        .get("code_statut_intervention")
        .valueChanges.subscribe(
          (value) => {
            const datePlanificationControl =
              this.traitIncidentForm.get("date_planification");
            const dateRealisationControl =
              this.traitIncidentForm.get("date_realisation");

            // Update validators based on the selected radio button option
            if (value === "PLAN") {
              datePlanificationControl.setValidators([Validators.required]);
              dateRealisationControl.setValidators(null);
            } else if (value === "REAL") {
              datePlanificationControl.setValidators(null);
              dateRealisationControl.setValidators([Validators.required]);
            } else {
              datePlanificationControl.setValidators(null);
              dateRealisationControl.setValidators(null);
            }

            // Update the validity status of the date fields
            datePlanificationControl.updateValueAndValidity();
            dateRealisationControl.updateValueAndValidity();
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }
  onDateSelected(event: MatDatepickerInputEvent<Date>) {
    this.selectedDate = event.value;
    this.generateHoursStart();
  }

  generateHoursStart() {
    this.traitIncidentForm
      .get("date_planification")
      .valueChanges.subscribe((value) => {
        this.selectedDate = value;
      });
    this.traitIncidentForm
      .get("date_realisation")
      .valueChanges.subscribe((value) => {
        this.selectedDate = value;
      });
    this.selectedDate = moment(this.selectedDate, "YYYY-MM-DD").format(
      "dd/MM/YYYY"
    );

    const currentHour = moment().add(1, "hour").hours();

    const hourStart = 8;
    const endHour = 23;
    this.hours = [];

    let index = this.traitIncidentForm.get("code_statut_intervention").value == 'REAL' ? hourStart : (this.selectedDate == moment().format("dd/MM/YYYY") ? currentHour : hourStart)

    for (
      index;
      this.traitIncidentForm.get("code_statut_intervention").value == 'PLAN' ? index < endHour : index < currentHour;
      index++
    ) {
      const hour = moment({ hour: index }).format("HH:00");
      this.hours.push(hour);
    }
  }

  statut_changed() {
    this.hours = []
    this.hoursEnd = []
  }

  generateHoursEnd() {
    const currentHour = moment(this.selectedHour, "HH:mm")
      .add(1, "hour")
      .hours();

    const endHour = 23;
    this.hoursEnd = [];
    for (let i = currentHour; i <= endHour; i++) {
      const hour = moment({ hour: i }).format("HH:00");
      this.hoursEnd.push(hour);
    }
  }

  initForm(data?) {
    this.traitIncidentForm = this.formBuilder.group({
      code_statut_intervention: [this.isRapport ? "REAL" : "PLAN"],
      code_categorie: [null],
      code_type_intervention: ["CORR"],
      id_compte_agent: [null],
      date_planification: [""],
      date_realisation: [""],
      description_planification: [null],
      description_realisation: [null],
    });
  }

  onDateChange(date: Date) {
    if (
      this.traitIncidentForm.controls.code_statut_intervention.value == "PLAN"
    ) {
      this.traitIncidentForm
        .get("date_planification")
        .setValue(moment(date).format("dd/MM/YYYY"));
      console.log(this.traitIncidentForm.get("date_planification"));
    } else {
      this.traitIncidentForm
        .get("date_realisation")
        .setValue(moment(date).format("dd/MM/YYYY"));
    }
  }
  getIntervenants() {
    this.isLoading = true;
    this.listeIntervenants = [];
    let body = {
      id_residence: this.id_residence,
      code_statut_affectation: 'VALI',
      showInactive : 'O',
      code_role_compte: "AGEN",
    };
    this.utilisateurService.getUtilisateurs(10, 0, body).subscribe(
      (res: any) => {
        this.listeIntervenants = res.affectations;
        this.isLoading = false;
      },
      (err) => {
        this.showMessageError();
        this.isLoading = false;
      }
    );
  }

  getIntervention() {
    this.interventionService
      .fiche(this.id_intervention, { id_residence: this.id_residence })
      .subscribe({
        next: (response) => {
          this.intervention = response.ficheIntervention[0];
          console.log(this.intervention);
          this.traitIncidentForm
            .get("id_compte_agent")
            .setValue(this.intervention.id_intervenant);
        },
        error: (error: any) => {
          // this.toastr.error(error.error.message ?? "Internal Error!");
          this.showMessageError();
        },
      });
  }
  getTypeIntervention() {
    this.listincidentservice
      .getStatut({ type_parametrages: "TYIN" })
      .subscribe((res: any) => {
        this.code_type_interventions = res.parametrage;
      });
  }
  getCategorieIntervention() {
    this.listincidentservice
      .getStatut({ type_parametrages: "CAIN" })
      .subscribe((res: any) => {
        this.code_categories = res.parametrage;
      });
  }

  // getIntervenant() {
  //   this.listincidentservice
  // }
  save() {
    this.isSubmitted = true;

    setTimeout(async () => {
      if (this.isSubmitted && this.traitIncidentForm.valid) {
        Swal.fire({
          title: "Opération est en cours!",
          heightAuto: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        // handle upload if there is document uploaded

        const promises: Promise<number | undefined>[] = [];

        for (let i = 0; i < this.selectedDocuments.length; i++) {
          const document = this.selectedDocuments[i];
          promises.push(this.saveDocument(document, i));
        }

        let documentsIds = await Promise.all(promises)

        const body = this.traitIncidentForm.value;
        body["id_origin"] = this.id_incident;
        body["documents"] = documentsIds;
        body["code_statut_intervention"] =
          this.traitIncidentForm.controls.code_statut_intervention.value;
        body["id_intervention"] = this.id_intervention;
        body["id_residence"] = this.id_residence;
        body["code_origin_intervention"] = !this.isRapport
          ? this.code_origin_intervention
          : null;
        body["date_planification"] = this.traitIncidentForm.controls
          .date_planification.value
          ? moment(
              this.traitIncidentForm.controls.date_planification.value
            ).format("YYYY-MM-DD HH:mm:ss")
          : null;
        body["date_realisation"] = this.traitIncidentForm.controls
          .date_realisation.value
          ? moment(
              this.traitIncidentForm.controls.date_realisation.value
            ).format("YYYY-MM-DD HH:mm:ss")
          : null;
        body["heure_debut_planification"] = this.traitIncidentForm.controls
          .date_planification.value
          ? this.selectedHour
          : null;
        body["heure_debut_realisation"] = this.traitIncidentForm.controls
          .date_realisation.value
          ? this.selectedHour
          : null;
        body["heure_fin_planification"] = this.traitIncidentForm.controls
          .date_planification.value
          ? this.selectedEndHour
          : null;
        body["heure_fin_realisation"] = this.traitIncidentForm.controls
          .date_realisation.value
          ? this.selectedEndHour
          : null;
        if (this.isRapport) {
          body["id_intervention"] = this.id_intervention;
          this.listincidentservice.ajouterRapport(body).subscribe(
            (success) => {
              this.showMessageSuc(`Rapport ajouté avec succès`);
            },
            (error) => {
              this.showMessageError();
            }
          );
        } else {
          this.listincidentservice.ajouterIntervention(body).subscribe(
            (success) => {
              this.showMessageSuc(`Intervention ajoutée avec succès`);
            },
            (error) => {
              this.showMessageError();
            }
          );
        }
      } else {
        console.log(this.traitIncidentForm.controls);
      }
    }, 100);
  }

  showMessageSuc(msg) {
    Swal.fire({
      icon: "success",
      text: msg,
      showConfirmButton: true,
      heightAuto: false,
      timer: 4000,
    });
    this.dialogRef.close(true);
  }

  showMessageError() {
    Swal.fire({
      icon: "error",
      title: `Une erreur est survenue`,
      text: "Veuillez réessayer plus tard",
      showConfirmButton: false,
      heightAuto: false,
      timer: 2500,
    });
  }

  // -------------------------------------- UPLOAD -------------------------------------- //

  onFileSelected(event: any): void {
    const files: FileList = event.target.files;
    console.log(files);
    
    for (let i = 0; i < files.length; i++) {
      const file: File = files[i];
      const extension: string = file.name.split('.').pop()!.toLowerCase(); // Get the file extension
      const allowedExtensions: string[] = ['png', 'jpeg', 'jpg', 'doc', 'docx', 'pdf']; // Allowed extensions
      if (!allowedExtensions.includes(extension)) {
        Swal.fire({
          icon: "warning",
          text: "Extension non autorisée",
          showConfirmButton: false,
          heightAuto: false,
          timer: 2500,
        });
      }

      if (this.selectedDocuments.length < 3) {
        this.selectedDocuments.push(file);
      } else {
        Swal.fire({
          icon: "warning",
          text: "3 documents au maximum",
          showConfirmButton: false,
          heightAuto: false,
          timer: 2500,
        });
      }
    }
  }

  removeDocument(index) {
    if (index >= 0 && index < this.selectedDocuments.length) {
      this.selectedDocuments.splice(index, 1);
    }
  }

  saveDocument(document: File,index : number): Promise<number | undefined> {
    const formData: FormData = new FormData();
    formData.append('document', document);
  
    return new Promise<number | undefined>((resolve, reject) => {

      const extension: string = document.name.split('.').pop()!.toLowerCase();

      this.residencesService.uploadDocument(
        extension,
        this.isRapport ? this.id_intervention : 0,
        "INTE",
        document.name,
        index + 1,
        formData
      ).subscribe((event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          // Handle upload progress if needed
        } else if (event.type === HttpEventType.Response) {
          console.log(event);
          
          const id_document = event.body.id_document;
          resolve(id_document);
        }
      },
      (err) => {
        console.error(err);
        // Handle error
        reject(err);
      });
    });
  }

}
