import { Injectable } from "@angular/core";
import { ApiService } from "../api-service/api.service";
import { Apis } from "../API";

@Injectable({
  providedIn: "root",
})
export class ReservationService {
  
 
  constructor(private apiService: ApiService) {}

  getListeReservations(
    records_per_page: number,
    page_number: number,
    body: any
  ) {
    return this.apiService.post(
      `${Apis.main.liste_reservation}/${records_per_page}/${page_number}`,
      body
    );
  }

  ajouterReservation(body: any) {
    return this.apiService.post(`${Apis.main.ajouter_reservation}`, body);
  }
  validerReservation(body: any) {
    return this.apiService.post(`${Apis.main.valider_reservation}`, body);
  }

  rejeterReservation(body: any) {
    return this.apiService.post(`${Apis.main.rejeter_reservation}`, body);
  }

  annulerReservation(body: any) {
    return this.apiService.post(`${Apis.main.annuler_reservation}`, body);
  }

  getReservationCategories(body: any) {
    return this.apiService.post(`${Apis.main.liste_categorie_reservation}`, body);
  }

  getListeReservateurs(body: any) {
    return this.apiService.post(`${Apis.main.liste_reservateur}`, body);
  }

  getFiche(id : any, body : any){
    return this.apiService.post(`${Apis.main.fiche_reservation}/${id}`,body);
  }
}
