import { filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { FichierIntegresService } from './../../service/listfichier/fichier-integres.service';
import { Component,Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { SharedService } from 'app/service/shared-service/shared.service';
@Component({
  selector: 'ms-fichier-ajouter',
  templateUrl: './fichier-ajouter.component.html',
  styleUrls: ['./fichier-ajouter.component.scss']
})
export class FichierAjouterComponent implements OnInit {


  file: File  ;
  


  selectedResidenceId: number;
  integrationDate: Date;
  dataselect1 ;
  nameficheir :string ="Aucun fichier choisi" ; 
  data ; 
  body ;
  id_residence: any;

  // message_insert :any;
  

  constructor(public dialogRef: MatDialogRef<FichierAjouterComponent> ,
    private FichierIntegresService:   FichierIntegresService  ,
    private SharedService:SharedService,
    private http:HttpClient , 
    ) { }

  ngOnInit(): void {

    this.SharedService.getSelectedIdResidence().subscribe(value => {
      this.id_residence = value;
    });

    this.FichierIntegresService.getlist_residence_affecte().subscribe(result=>{
      this.dataselect1=result.residences.filter((item)=>{
        return  item.statut_affectation !=="En Attent"
      })
    })
    
  }

  onCloseClick(): void {
    this.dialogRef.close({message :'annuler'});
  }




  onFileSelect(event) {
        this.file = event.target.files[0];
        // console.log(this.file.name)
      }


  //get data from  excel  and upload fichier 
  upload_get_data() { 
      if(this.file && this.selectedResidenceId && this.integrationDate){
        console.log("yeap");
        
        this.upload_fichier_back() ;
        //code permet de get data from fichier 
        // if(false){
        //     //read fichier and stock data in var data 
        //     const reader = new FileReader();
        //     reader.readAsBinaryString(this.file)
        //     reader.onload = (e: any) => {
        //         const workbook = XLSX.read(reader.result, { type: 'binary' });
        //         const sheetNames = workbook.SheetNames;
        //         const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNames[0]]);
        //         this.data =data   
        //       }
        //   }
        } 
  }

  //function calcul   , send data to mysql 
  ajouter_fichier(filename ,erreur ){
              this.body = {
                    "id_residence" :this.selectedResidenceId ,
                    "date_integration" :this.integrationDate ,
                    "name_file" :filename
              }
                this.FichierIntegresService.add_fichier(this.body).subscribe(
                  result=>{
                    if (erreur && result.message === "fichier inserted successfully" ){
                      this.dialogRef.close({  message_data:"file uploaded and inserted successfully" ,message:"success"  });
                    }else{
                      this.dialogRef.close({ message_data:result.message ,message:"fail" });
                    }         
                })   
  }




  upload_fichier_back(){
    const formData = new FormData();
    formData.append('excelFile', this.file, this.file.name); 
      this.FichierIntegresService.uploadSolde(formData, this.id_residence).subscribe(
        result =>{ 
          let erreur =false
                if(result.body ){
                  erreur=true
                  const  filename =result.body.filename
                  this.ajouter_fichier(filename ,erreur)
                }else{
                    erreur =false
                }
        });           
  }





}
