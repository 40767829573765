import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatChipSelectionChange } from "@angular/material/chips";
import { MatNativeDateModule } from "@angular/material/core";
import {
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from "@angular/material/datepicker";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { PageTitleService } from "app/core/page-title/page-title.service";
import { CookieStoreService } from "app/service/cookieService/cookieService.service";
import { CurrentUserService } from "app/service/current-user.service";
import { ReservationService } from "app/service/reservation-service/reservation.service";
import { ResidencesService } from "app/service/residences/residences.service";
import { ListresidentService } from "app/service/resident/listresident.service";
import { SharedService } from "app/service/shared-service/shared.service";
import { SweetAlertService } from "app/service/sweetalert-service/sweetalert.service";
import * as moment from "moment";
import Swal from "sweetalert2";

@Component({
  selector: "ms-ajouter-reservation",
  templateUrl: "./ajouter-reservation.component.html",
  styleUrls: ["./ajouter-reservation.component.scss"],
})
export class AjouterReservationComponent implements OnInit {
  id_residence;
  isLoading: boolean;
  listeEquipements;
  listeCategories;
  selectedCategorie;
  IsResidentChecked: boolean;
  liste_residents;
  hours: string[] = [];
  hoursEnd: string[] = [];
  selectedHour: string;
  selectedEndHour: string;
  selectedResident;
  selectedEquipement;
  selectedDate;
  minDate = new Date();
  isSubmitted: boolean;
  decision: string = "GEST";
  data_resident: any;
  type: string;
  libelle_occupant
  constructor(
    private pageTitleService: PageTitleService,
    private formBuilder: FormBuilder,
    private _sweetAlertService: SweetAlertService,
    private _listeResidentsService: ListresidentService,
    private _reservationService: ReservationService,
    public sharedService: SharedService,
    private _currentUser: CurrentUserService,
    private cookies: CookieStoreService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AjouterReservationComponent>
  ) {}

  ngOnInit(): void {
    this.selectedDate = moment();
    this.libelle_occupant = this.cookies.getTOKEN("libelle_occupant").replace(/"/g, '');
  }
  generateHoursStart() {
    this.selectedDate = moment(this.selectedDate, "YYYY-MM-DD").format(
      "YYYY-MM-DD"
    );
    console.log(this.selectedDate);

    const currentHour = moment().add(1, "hour").hours();
    console.log(currentHour);

    const hourStart = 8;
    const endHour = 23;
    console.log(this.selectedDate == moment().format("YYYY-MM-DD"));
    this.hours = [];
    for (
      let i =
        this.selectedDate == moment().format("YYYY-MM-DD")
          ? currentHour
          : hourStart;
      i < endHour;
      i++
    ) {
      const hour = moment({ hour: i }).format("HH:00");
      this.hours.push(hour);
    }
  }

  onDateSelected(event: MatDatepickerInputEvent<Date>) {
    this.selectedDate = event.value;
    console.log(this.selectedDate);

    this.generateHoursStart();
  }

  generateHoursEnd() {
    const currentHour = moment(this.selectedHour, "HH:mm")
      .add(1, "hour")
      .hours();
    console.log(currentHour);

    const endHour = 23;
    this.hoursEnd = [];
    for (let i = currentHour; i <= endHour; i++) {
      const hour = moment({ hour: i }).format("HH:00");
      this.hoursEnd.push(hour);
    }
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.getCategories();

      this.sharedService.getSelectedIdResidenceBehavior().subscribe((value) => {
        this.id_residence = value;
        this.getListeResidents();
        const body = {
          id_residence: this.id_residence,
        };
      });
    }, 0);
  }

  getListeResidents() {
    let body = {};
    if (this.decision == "RESI") {
      body = {
        code_role_compte: "RESI",
        code_statut_affectation: 'VALI',
        showInactive : 'O',
        id_residence: this.id_residence,
      };
    } else if (this.decision == "GEST") {
      body = {
        code_role_compte: "GEST",
        code_statut_affectation: 'VALI',
        showInactive : 'O',
        id_residence: this.id_residence,
      };
    } else if (this.decision == "AGEN") {
      body = {
        code_role_compte: "AGEN",
        code_statut_affectation: 'VALI',
        showInactive : 'O',
        id_residence: this.id_residence,
      };
    }
    this._listeResidentsService.getListResident(10, 0, body).subscribe(
      (result) => {
        this.liste_residents = result.residents;
      },
      (error) => {
        this.showMessageError();
      }
    );
  }

  getCategories() {
    this.listeCategories = [];
    const body = {
      id_residence: this.cookies.getTOKEN("id_residence"),
    };
    this._reservationService
      .getReservationCategories(body)
      .subscribe((res: any) => {
        this.listeCategories = res.liste_reservation_category;
      });
  }

  getEquipement() {
    console.log(this.selectedCategorie);

    this.listeEquipements = [];
    const body = {
      id_residence: this.cookies.getTOKEN("id_residence"),
      code_type_installation: this.selectedCategorie.code_categorie,
    };
    this._reservationService
      .getReservationCategories(body)
      .subscribe((res: any) => {
        this.listeEquipements = res.liste_reservation_category;
        console.log(this.listeEquipements);
      });
  }
  

  save() {
    
    setTimeout(() => {
        const body = {
        id_compte_demandeur: this.selectedResident,
        code_role_createur: "GEST",
        code_role_demandeur: this.decision,
        id_residence: this.id_residence,
        id_residence_installation: this.selectedEquipement,
        date_reservation: moment(this.selectedDate, "YYYY-MM-DD").format(
          "YYYY-MM-DD"
        ),
        heure_debut: this.selectedHour,
        heure_fin: this.selectedEndHour,
      };
      
      if (
        !body.id_residence_installation ||
        !body.date_reservation ||
        !body.heure_debut 
      ) {
        this._sweetAlertService.showErrorAlert(
          "Validation Error",
          "Veuillez remplir le formulaire"
        );
        
      }
       else {
        this._reservationService.ajouterReservation(body).subscribe(
          (success) => {
            this._sweetAlertService.showSuccessAlert(
              "Success",
              success.message
            );
            this.dialogRef.close();
          },
          (error) => {
            this._sweetAlertService.showErrorAlert(
              "Réservation impossible",
              error.error.message
            );
          }
        );
      }
  }, 100);
  }
  showMessageError() {
    Swal.fire({
      icon: "error",
      title: `Une erreur est survenue`,
      text: "Veuillez réessayer plus tard",
      showConfirmButton: false,
      heightAuto: false,
      timer: 2500,
    });
  }
}
