import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';


@Injectable({
	providedIn: 'root'
})

export class CoreService {

	collapseSidebar 		 	: boolean = false		;
	collapseSidebarStatus 		: boolean				;
	sidenavMode				 	: string  = "side"		;
	sidenavOpen 			 	: boolean = true		;
	sidenavClose 			 	: boolean = true		;
	horizontalSideNavMode 		: string  = "over"		;
	horizontalSideNavOpen 		: boolean = false		;
	projectDetailsContent 		: any					;
	editProductData 		 	: any					;

	constructor(private http : HttpClient){
	}
}