import { ReservationService } from "app/service/reservation-service/reservation.service";
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { PageTitleService } from "app/core/page-title/page-title.service";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { ListIncidentService } from "app/service/list-incident-service/list-incident.service";
import { CurrentUserService } from "app/service/current-user.service";
import { MatDialog } from "@angular/material/dialog";
import Swal from "sweetalert2";
import * as moment from "moment";
import { CookieStoreService } from "app/service/cookieService/cookieService.service";
import { SharedService } from "app/service/shared-service/shared.service";
import { Subscription } from "rxjs";
import { UtilisateurService } from "app/service/utilisateur-service/utilisateur.service";
import { AjouterReservationComponent } from "../ajouter-reservation/ajouter-reservation.component";
import { ListresidentService } from "app/service/resident/listresident.service";
import { FicheReservationComponent } from "../fiche-reservation/fiche-reservation.component";

export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = "Objets par page";

  return customPaginatorIntl;
}

@Component({
  selector: "ms-liste-reservation",
  templateUrl: "./liste-reservation.component.html",
  styleUrls: ["./liste-reservation.component.scss"],
})
export class ListeReservationComponent implements OnInit {
  @Input() fiche: any = {isfiche: false, id_compte: null};
  @Output() totalReservationsChanged: EventEmitter<number> = new EventEmitter<number>();
  userItem;
  isLoading;
  ReservationForm: FormGroup;
  listeReservations;
  pageIndex = 0;
  pageSize = 5;
  totalReservation;
  exampleHeader;
  dateStart = moment(new Date()).format("MM/DD/YYYY");
  dateEnd = moment(new Date()).format("MM/DD/YYYY");
  listeResidences;
  listeCategories;
  selectedResidence;
  id_residence;
  liste_reservateurs;
  private subscription: Subscription;
  role_user: any;
  listeEquipements;
  listeDemandeurs: any;
  ListStatut: any[];
  constructor(
    private pageTitleService: PageTitleService,
    private formBuilder: FormBuilder,
    private reservationService: ReservationService,
    private _currentUser: CurrentUserService,
    public dialog: MatDialog,
    private listincidentservice: ListIncidentService,
    private renderer: Renderer2,
    private el: ElementRef,
    private listresidentService:ListresidentService,
    private cookies: CookieStoreService,
    public sharedService: SharedService
  ) {
    this.userItem = this._currentUser.getRoleCurrentUser();

    this.ReservationForm = new FormGroup({
      id_reservation: new FormControl(),
      id_compte: new FormControl(),
      id_residence_installation: new FormControl(),
      statut_reservation: new FormControl(),
      code_type_installation: new FormControl(),
      date_reservation: new FormControl(),
    });
  }
  @ViewChild("statusChip", { static: false }) statusChip: ElementRef;




  getStatusTooltipContent(): string {
    // Add your logic to generate tooltip content dynamically based on the status
    return "Tooltip Content"; // Replace this with your dynamic content logic
  }
  ngOnInit(): void {
    this.id_residence = this.cookies.getTOKEN("id_residence");
    if(!this.fiche.isFiche) {
      setTimeout(() => {
        this.pageTitleService.setTitle("Liste des réservations");
      }, 0);
      this.getStatut();
    this.getCategories();
    
    this.subscription = this.sharedService
      .getSelectedIdResidence()
      .subscribe((value) => {
        this.id_residence = value;
        this.initIncidentForm();
      });
      setTimeout(() => {
        if (!this.fiche.isFiche) {
          this.getListeReservations(this.pageSize, this.pageIndex);
          this.getListResidents();
        }
      }, 200);    
    }
    
  }

  ngOnChanges(changes) {
    if (this.fiche?.isFiche) {
      this.getListeReservations(this.pageSize, this.pageIndex);
      this.getListResidents();
    }
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getListeReservations(records_per_page, page_number) {
    this.isLoading = true;
    this.listeReservations = [];
    const code_type_installation =
      this.ReservationForm.value.code_type_installation;

    const date_reservation = this.ReservationForm.value.date_reservation;
    const id_residence_installation =
      this.ReservationForm.value.id_residence_installation;
    const id_compte = this.ReservationForm.value.id_compte;
    let body = {
      id_reservation: this.ReservationForm.get('id_reservation').value,
      id_residence: Number(this.cookies.getTOKEN("id_residence")),
      code_type_installation,
      date_reservation,
      id_residence_installation,
      statut_reservation : this.ReservationForm.get('statut_reservation').value,
      id_compte_demandeur: Number(this.fiche?.id_compte) || id_compte,
    };
    this.reservationService
      .getListeReservations(records_per_page, page_number, body)
      .subscribe(
        (res: any) => {
          this.listeReservations = res.liste_reservations;
          this.totalReservation = res.nbr_liste_reservation;
          this.totalReservationsChanged.emit(this.totalReservation);
          this.isLoading = false;
        },
        (err) => {
          this.showMessageError();
          this.isLoading = false;
        }
      );
  }

  getListResidents(){
    let body = {};
    body = {
      code_role_compte: "GEST",
      code_statut_affectation: 'VALI',
      showInactive : 'O',
      id_residence: this.id_residence,
    };
    this.listresidentService.getListResident(9999, 0, body).subscribe(
      (result) => {
        this.liste_reservateurs = result.residents;
      },
      (error) => {
      }
    );
  }
  showMessageError() {
    Swal.fire({
      icon: "error",
      text: `une erreur s'est produite. veuillez réessayer ultérieurement.`,
      showConfirmButton: false,
      heightAuto: false,
      timer: 2500,
    });
  }

  getStatut() {
    this.ListStatut = [];
      let body ={
        "type_parametrages" : "TYRE"
      }
      this.listincidentservice.getStatut(body)
        .subscribe((res: any) => {
          this.ListStatut = res.parametrage;
        })
  }

  getCategories() {
    this.listeCategories = [];
    const body = {
      id_residence: this.cookies.getTOKEN("id_residence"),
    };
    this.reservationService
      .getReservationCategories(body)
      .subscribe((res: any) => {
        this.listeCategories = res.liste_reservation_category;
      });
  }

  getEquipement(equipement: any) {
    console.log(equipement);

    this.listeEquipements = [];
    const body = {
      id_residence: this.cookies.getTOKEN("id_residence"),
      code_type_installation: equipement,
    };
    this.reservationService
      .getReservationCategories(body)
      .subscribe((res: any) => {
        this.listeEquipements = res.liste_reservation_category;
        console.log(this.listeEquipements);
      });
  }

  ajouterReservation() {
    const dialogRef = this.dialog.open(AjouterReservationComponent, {
      width: '800px',
      autoFocus: false,
      maxHeight: '85vh'
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getListeReservations(this.pageSize, this.pageIndex);
    });
  }

  ficheReservation(id : any) {
    const dialogRef = this.dialog.open(FicheReservationComponent, {
      width: '900px',
      autoFocus: false,
      maxHeight: '85vh'
    });
    dialogRef.componentInstance.id = id;
  }

  validerReservation(item) {
    Swal.fire({
      title: "Opération est en cours!",
      heightAuto: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    let body = {
      id_residence: this.id_residence,
      id_reservation: item.id_reservation,
    };
    this.reservationService.validerReservation(body).subscribe(
      (res) => {
        this.getListeReservations(this.pageSize, this.pageIndex);
        this.showMessageSuc(`Opération réussie`);
      },
      (error) => {
        this.showMessageError();
      }
    );
  }

  rejeterReservation(item: any) {
    Swal.fire({
      title: "Veuillez clarifier le motif du rejet",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      heightAuto: false,
      confirmButtonText: "Rejeter",
      showLoaderOnConfirm: true,
      preConfirm: (motif) => {
        Swal.fire({
          title: "Opération est en cours!",
          heightAuto: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        let body = {
          id_residence: this.id_residence,
          id_reservation: item.id_reservation,
          motif,
        };
        this.reservationService.rejeterReservation(body).subscribe(
          (res) => {
            this.getListeReservations(this.pageSize, this.pageIndex);
            this.showMessageSuc(`Réservation rejetée avec succès`);
          },
          (error) => {
            this.showMessageError();
          }
        );
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }
  annulerReservation(id_reservation: any) {

    Swal.fire({
      title: 'êtes-vous sûr de vouloir annuler cette réservation?',
      icon : "warning",
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      showLoaderOnConfirm: true,
      preConfirm: () => {
          
        const body = {
          id_residence : this.id_residence,
          id_reservation,
        };
        this.reservationService.annulerReservation(body).subscribe(
          (res) => {
            this.getListeReservations(this.pageSize, this.pageIndex);
            this.showMessageWarn(`Réservation annulée`);
          },
          (error) => {
            this.showMessageError();
          }
        );

      }
  });
  }
  initIncidentForm() {
    this.ReservationForm = this.formBuilder.group({
      id_reservation: [null],
      id_compte: [null],
      id_residence_installation: [null],
      statut_reservation: [null],
      code_type_installation: [null],
      date_reservation: [null],
    });
  }

  showMessageSuc(msg) {
    Swal.fire({
      icon: "success",
      text: msg,
      showConfirmButton: true,
      heightAuto: false,
      timer: 4000,
    });
  }
  showMessageWarn(msg) {
    Swal.fire({
      icon: "warning",
      text: msg,
      showConfirmButton: true,
      heightAuto: false,
      timer: 4000,
    });
  }

  FrmDate($event) {
    console.log(moment($event.target.value).format("YYYY/MM/DD"));
  }

  EndDate(e) {
    console.log(moment(e.target.value).format("YYYY/MM/DD"));
  }

  reset() {
    this.initIncidentForm();
    this.pageIndex = 0;
    this.getListeReservations(this.pageSize, this.pageIndex);
  }

  filtrer() {
    this.pageIndex = 0;
    this.getListeReservations(this.pageSize, this.pageIndex);
  }

  getNextPage(event) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getListeReservations(event.pageSize, event.pageIndex);
  }
}
