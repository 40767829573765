export const Apis = {
    backoffice: {
        ajouter_incident            : "incidents/ajouter",
        fichier_ajouter             :"solde/fichiers/ajouter",
        liste_fichiers              :"solde/fichiers/integres",
        liste_detaille_fichier      :"solde/liste/detail",
        upload                      :"uploads",
        uploadSolde                 :"solde/upload/solde",
        incident                    :"incidents/tab_incidents",
        liste_taches                : "incidents/taches",
        existence_compte            : "comptes/verification",
        authentification            : "comptes/authentification",
        validation                  : "comptes/token/validation",
        traiter_incident            : "incidents/traiter",
        get_detail_incident         : "incidents/detail",
        liste_utilisateurs          : "utilisateurs/affectations",
        qualifier_incident          : "incidents/qualifier",
        qualifier_traitement        : "incidents/qualifier/traitement",
        affectation_fiche           : "utilisateurs/affectation",
        salarie_fiche               : "utilisateurs/affectation/fiche_salarie",
        valider_affectation         : "utilisateurs/affectation/valider",
        rejeter_affectation         : "utilisateurs/affectation/rejeter",
        desactiver_affectation      : "utilisateurs/affectation/desactiver",
        activer_desactiver_residence: "residences/activer_desactiver/affectation",
        valider_affectation_bien    : "residences/valider/bien",
        rejeter_affectation_bien    : "residences/rejeter/bien",
        supprimer_affectation_bien  : "residences/supprimer/affectation/bien",
        liste_resident              : "utilisateurs/residents",
        get_role                    : "utilisateurs/getrole",
        liste_demandes              : "demandes-requetes/liste",
        fiche_demande               : "demandes-requetes/fiche",
        ajouter_request             : "demandes-requetes/ajouter_request",
        ajouter_demande             : "demandes-requetes/ajouter",
        cloturer_demande            : "demandes-requetes/close"

    },
    main: {
        //Authenfification
        version                     : "version",
        public_key                  : "authentification/public-key",
        modifier_pwd                : "authentification/modifier-password",
        generer_pwd                 : "authentification/generer-password",
        connexion                   : "authentification/connexion",
        ajouter_reservation         : "reservations/ajouter",
        annuler_reservation         : "reservations/annuler",
        existence_compte            : "authentification/verification",
        liste_residences            : "residences/",
        authentification            : "authentification/activer",
        liste_Immeuble              : "residences/biens",
        all_biens                   : "residences/all-biens",
        fiche_residence             : "residences/fiche",
        ajouter_affectation_resident: "residences/ajouter",
        modifier_compte             : "residences/modifier",
        modifier_metiers            : "residences/modifier_metier",
        modifier_affectation_resident: "residences/modifier",
        supprimer_bien              : "residences/supprimer/bien",
        liste_metiers_residence     : "residences/liste_metiers_residence",
        authentification_par_GSM    : "comptes/authentification",
        getCodifParametrages        : "parametrages/tab_parametrage",
        activerCodifParametrage     : "main/codification/activer/",
        desactiverCodifParametrage  : "main/codification/desactiver/",
        uploadImages                : "uploads/without/",
        ajouterCodifParametrage     : "main/codification/add",
        modifierCodifParametrage    : "main/codification/upd",
        getCodification             : "main/codification/param",
        getStatistiquesDashboard    : "dashboard/statistiques",
        residence_stats             : "dashboard/residence",
        FicheIncident               : "incidents/fiche_incident",
        liste_categories            : "residences/categories",
        list_residence              : "residences",
        list_parametrage            : "parametrages/tab_parametrage",
        list_statut_incident        : "parametrages/get_status_incident",
        fiche_resident              : "residents/fiche",
        res_list_biens              : "residents/liste/biens",
        list_biens                  : "residents/biens",
        list_incident               : "residents/incidents",
        list_immeuble               : "residences/biens",
        ajouter_biens               : "residences/ajouter/bien",
        ajouter_bien_autre          : "residences/ajouter/bien/autre",
        liste_news                  : "news/tab_news",
        fiche_news                  : "news/fiche",
        desactiver_news             : "news/annuler",
        liste_communication         : "communication/tab_communication",
        liste_info_utiles           : "info_utiles/liste",
        ajouter_info_utiles         : "info_utiles/ajouter",
        activer_info_utiles         : "info_utiles/activer",
        desactiver_info_utiles      : "info_utiles/desactiver",
        fiche_communication         : "communication/fiche",
        liste_sondage               : "sondage/tab_sondage",
        fiche_sondage               : "sondage/fiche",
        valider_communication       : "communication/valider",
        rejeter_communication       : "communication/rejeter",
        annuler_communication       : "communication/annuler",
        ajouter_news                : "news/ajouter",                         
        update_news                 : "news/modifier",
        list_alerts                 : "alert/liste",
        desactivate_alert           : "alert/desactiver",
        fiche_alert                 : "alert/fiche",
        ajouter_alert               : "alert/ajouter",
        tableau_residences          : "residences/9999999999/0",
        liste_reservation: 'reservations',
        fiche_reservation: 'reservations/fiche',
        valider_reservation: 'reservations/valider',
        rejeter_reservation: 'reservations/rejeter',
        liste_categorie_reservation: 'reservations/liste-categories',
        liste_reservateur: 'reservations/liste-reservateur',
        liste_residence_categorie: "residences/categories",
        liste_intervention:"intervention/liste_intervention",
        fiche_intervention:"intervention/fiche_intervention",
        annuler_intervention:"intervention/annuler",
        historique_incident: "incidents/historique",
        ajouter_intervention: "intervention/ajouter_intervention",
        verifier_incident: "incidents/verifier",
        annuler_incident: "incidents/annuler",
        ajouter_rapport: "intervention/ajouter-rapport",
        liste_utilisateur: "utilisateurs/liste-utilisateurs",
        liste_param_categorie: "parametrages/tab-parametrage-categorie",
        agenda_intervention:"agenda/interventions",
        agenda_requests:"agenda/requests",
        agenda_reservations:"agenda/reservations",
        agenda_colors:"agenda/colors",
        color_reservation: 'agenda/reservations/color',
        upload_document: 'uploads/document',
        // Autorisation
        list_autorisation           : "autorisations/liste",
        list_historique             : "autorisations/liste_historique",
        fiche_autorisation          : "autorisations/fiche",
        ajouter_autorisation        : "autorisations/ajouter",
        anuuler_autorisation        : "autorisations/annuler",
    } 
}
