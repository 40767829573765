import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { PageTitleService } from "app/core/page-title/page-title.service";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { ListIncidentService } from "app/service/list-incident-service/list-incident.service";
import { CurrentUserService } from "app/service/current-user.service";
import { MatDialog } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import Swal from "sweetalert2";
import { SweetAlertService } from "app/service/sweetalert-service/sweetalert.service";
import { SharedService } from "app/service/shared-service/shared.service";
import { ListresidentService } from "app/service/resident/listresident.service";
import { CommunicationServiceService } from "app/service/communication-service/communication-service.service";
import { ResidencesService } from "app/service/residences/residences.service";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { CookieStoreService } from "app/service/cookieService/cookieService.service";

export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = "Objets par page";

  return customPaginatorIntl;
}

@Component({
  selector: "ms-ajouter-incident",
  templateUrl: "./ajouter-incident.component.html",
  styleUrls: ["./ajouter-incident.component.scss"],
  
})
export class AjouterIncidentComponent implements OnInit {
  @ViewChild(CdkVirtualScrollViewport)
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport;
  @Input() isVerifier: boolean = false;
  @Input() fiche: any = { isfiche: false, id_compte: null };
  cleIncident;
  isLoading = false;
  userItem;
  incident;
  // form: FormGroup;
  listeMotifAbandon;
  isSubmitted: boolean;
  cle_incident: any = '';
  id_incident: any;
  images: any;
  imagesList: [];
  imagesListProv = [];
  photoUrls;
  imgUpload;
  selectedFiles;
  imgExtension;
  order;
  fd;
  code_statut_incident;
  description;
  motif_abandon;
  listeCategories = [];
  deletedImages = [];
  emitImages;
  id_residence;
  ListIncident;
  records_per_page;
  page_number;
  code_categorie_prin;
  code_categorie_seco;
  selectedCategorie;
  listeSousCategories;
  IsResidentChecked: boolean = false;
  selectedSousCategorie;
  selectedImmeuble;
  selectedEtage;
  selectedBien;
  selectedTypeIncident;
  selectedResident;
  liste_residents;
  listeTypeIncidents;
  listeImmeubles;
  filterelisteResidents = [];
  filterelisteResident = [];
  listeEtages;
  listeBien;
  niveau_gravite;
  damage_caused;
  reasons;
  solutions_retenue;
  decision: string = "GEST";
  description_incident: string;
  listeAscenseur = [];
  listeMonteCharge = [];
  listeGarage = [];
  selectedAscenseur: string;
  selectedMonteCharge: string;
  ListNiveauGravite: any[];
  libelle_occupant
  constructor(
    private pageTitleService: PageTitleService,
    private formBuilder: FormBuilder,
    private listincidentservice: ListIncidentService,
    private _sweetAlertService: SweetAlertService,
    private _listeResidentsService: ListresidentService,
    private _residenceService: ResidencesService,
    public sharedService: SharedService,
    private _currentUser: CurrentUserService,
    public dialog: MatDialog,
    private cookies: CookieStoreService,
    public dialogRef: MatDialogRef<AjouterIncidentComponent>
  ) {
    this.userItem = this._currentUser.getRoleCurrentUser();
  }

  ngOnInit(): void {
    this.photoUrls = [];
    this.imgUpload = [];
    this.selectedFiles = [];
    this.libelle_occupant = this.cookies.getTOKEN("libelle_occupant").replace(/"/g, '');
    // this.getListIncident(this.records_per_page, this.page_number);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.sharedService.getSelectedIdResidenceBehavior().subscribe((value) => {
        this.id_residence = value;
        const body = {
          id_residence: this.id_residence,
        };
        this.getResidenceCategories(body);
        this.getListImmeuble(body);
        this.getListeResidents();
        this.getListeNiveaugravite();
      });
    }, 0);
  }

  getListeResidents() {
    let body = {};
    if (this.decision == "RESI") {
      body = {
        code_role_compte: 'RESI',
        code_statut_affectation: 'VALI',
        showInactive : 'O',
        id_residence: this.id_residence,
      };
    }
    else if (this.decision == "GEST") {
      body = {
        code_role_compte: 'GEST',
        code_statut_affectation: 'VALI',
        showInactive : 'O',
        id_residence: this.id_residence,
      };
    }
    else if (this.decision == "AGEN") {
      body = {
        code_role_compte: 'AGEN',
        code_statut_affectation: 'VALI',
        showInactive : 'O',
        id_residence: this.id_residence,
      };
    }
    this._listeResidentsService
      .getListResident(10, 0, body)
      .subscribe(
        (result) => {
          this.liste_residents =  this.filterelisteResidents = result.residents;
        },
        (error) => {
          this.showMessageError();
        }
      );
  }

  openChange($event: boolean, index?, id_demandeur?) {
    if ($event) {
      this.cdkVirtualScrollViewPort.scrollToIndex(0);
      this.cdkVirtualScrollViewPort.checkViewportSize();
    }
  }
  filterResident(value, index?) {
    this.filterelisteResident = this.liste_residents
    this.filterelisteResident = this.filterelisteResident.
      filter(resident => resident.nom_compte.toUpperCase().includes(value.toUpperCase()))

  }

  getResidenceCategories(body) {
    this.listincidentservice.getResidenceCategories(body).subscribe(
      (res: any) => {
        this.listeCategories = res.categories;
      },
      (error) => {
        this.showMessageError();
      }
    );
  }

  getListeNiveaugravite() {
    this.ListNiveauGravite = [];
    let body ={
      "type_parametrages" : "NVGR"
    }
    this.listincidentservice.getStatut(body).subscribe((res: any) => {
      this.ListNiveauGravite = res.parametrage;
    })
  }

  getSousCategories() {
    if (this.selectedCategorie) {
      this.listeSousCategories = this.listeCategories.find(
        (category) =>
          category.code_categorie == this.selectedCategorie.code_categorie
      ).liste_sous_categories;
    }
  }

  getTypeIncidents() {
    if (this.selectedSousCategorie) {
      this.selectedAscenseur = null;
      this.selectedMonteCharge = null;
      this.selectedBien = null;
      this.selectedImmeuble = null;
      this.selectedEtage = null;
      this.listeTypeIncidents = this.listeSousCategories.find(
        (category) =>
          category.code_categorie == this.selectedSousCategorie.code_categorie
      ).liste_type_incidents;
    }
  }

  getListImmeuble(body) {
    this._residenceService.getBiensResidences(body).subscribe(
      (result) => {
        this.listeImmeubles = result.liste_immeuble_villas.immeubles;
      },
      (error) => {
        this.showMessageError();
      }
    );
  }

  getEtage() {
    if (this.selectedImmeuble) {
      this.listeEtages = this.listeImmeubles.find(
        (immeuble) => immeuble.id_immeuble == this.selectedImmeuble
      ).nbr_etages;

      this.listeAscenseur = [];
      let countAscenseur = this.listeImmeubles.find(
        (immeuble) => immeuble.id_immeuble == this.selectedImmeuble
      ).nbr_ascenseurs;
      for (let i = 1; i <= countAscenseur; i++) {
        this.listeAscenseur.push(i);
      }
      
      this.listeMonteCharge = [];
      let countMonteCharge = this.listeImmeubles.find(
        (immeuble) => immeuble.id_immeuble == this.selectedImmeuble
      ).nbr_monte_charge;
      console.log(countMonteCharge);
      
      for (let i = 1; i <= countMonteCharge; i++) {
        this.listeMonteCharge.push(i);
      }
      console.log(this.listeMonteCharge);
      
      this.listeGarage = [];
      let countGarage = this.listeImmeubles.find(
        (immeuble) => immeuble.id_immeuble == this.selectedImmeuble
      ).nbr_etages_garage;
      console.log(countGarage);
      
      for (let i = 1; i <= countGarage; i++) {
        this.listeGarage.push(-i);
      }
    }
  }

  getBien() {
    if (this.selectedEtage) {
      this.listeBien = this.listeEtages.find(
        (bien) => bien.nbr_etage == this.selectedEtage
      ).biens;
    }
  }
  getListIncident(records_per_page, page_number) {
    this.isLoading = false;
    this.ListIncident = [];

    let body = {
      code_statut_incident: this.code_statut_incident,
      code_categorie_prin: this.code_categorie_prin,
      code_categorie_seco: this.code_categorie_seco,
      id_residence: this.id_residence,
      longitude: null,
      latitude: null,
    };
    this.listincidentservice
      .getListIncident(records_per_page, page_number, body)
      .subscribe(
        (res: any) => {
          this.ListIncident = res.incidents;
          // this.totalIncidents = res.nbr_total_incidents;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }

  save() {
    setTimeout(() => {

      if (!this.selectedResident && !this.isVerifier) {
        return this.showMessageWarning("Demandeur est obligatoire")
      } else if ((!this.selectedCategorie?.code_categorie || !this.selectedSousCategorie?.code_categorie ) && !this.isVerifier) {
        return this.showMessageWarning("Les champs catégorie principale et secondaire sont obligatoire")
      } else if (!this.niveau_gravite  && !this.isVerifier ) {
        return this.showMessageWarning("Le niveau de gravité est obligatoire")
      } else if (!this.selectedTypeIncident  && !this.isVerifier) {
        return this.showMessageWarning("Le type d'incident est obligatoire")
      }

      Swal.fire({
        title: "Opération est en cours!",
        heightAuto: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let body = {}
      if (!this.isVerifier) {
        body = {
          id_residence: this.id_residence,
          code_role_createur: 'GEST',
          id_demandeur: this.selectedResident,
          code_role_demandeur: this.decision,
          code_categorie_prin: this.selectedCategorie.code_categorie,
          code_categorie_seco: this.selectedSousCategorie.code_categorie,
          code_type_incident: this.selectedTypeIncident,
          niveau_gravite : this.niveau_gravite,
          damage_caused : this.damage_caused,
          reasons : this.reasons,
          solutions_retenue : this.solutions_retenue,
          id_immeuble: this.selectedImmeuble,
          num_etage:
            this.listeSousCategories[this.selectedSousCategorie?.index]
              ?.flag_etage == "O"
              ? this.selectedEtage
              : null,
          id_bien:
            this.listeSousCategories[this.selectedSousCategorie?.index]
              ?.flag_bien == "O"
              ? this.selectedBien?.id_bien
              : null,
          reference_bien:
            this.listeSousCategories[this.selectedSousCategorie?.index]
              ?.flag_bien == "O"
              ? this.selectedBien?.reference_bien
              : null,
          description_incident: this.description_incident,
          num_ascenseur: this.selectedAscenseur,
          monte_charge: this.selectedMonteCharge,
          _cle_incident: this.cle_incident
        };
      }
      else {
        body = {
          id_incident: this.id_incident,
          id_residence: this.id_residence,
          code_statut_incident: this.code_statut_incident,
          code_etape: 'CLOT',
          description_incident: this.description_incident,
          _cle_incident: this.cle_incident
        };
      }

      body["images"] = this.imgUpload?.map((el, index) => el.id_image);

      if (!this.isVerifier) {
        this.listincidentservice.ajouterIncident(body).subscribe(
          (success) => {
            this.showMessageSuc(`Opération réussie`);
          },
          (error) => {
            this.showMessageError();
          }
        );
      }
      else {

        if (!this.code_statut_incident) {
          return this.showMessageWarning("Merci de choisir le statut d'incident")
        }
        
        this.listincidentservice.verifierIncident(body).subscribe(
          (success) => {
            this.showMessageSuc(`Incident Verifié avec succès`);
          },
          (error) => {
            this.showMessageError();
          }
        );
      }
    }, 100);
  }

  showMessageSuc(msg) {
    Swal.fire({
      icon: "success",
      text: msg,
      showConfirmButton: true,
      heightAuto: false,
      timer: 4000,
    });
    this.dialogRef.close(true);
  }

  showMessageWarning(msg) {
    Swal.fire({
      icon: "warning",
      text: msg,
      showConfirmButton: false,
      heightAuto: false,
      timer: 4000,
      didOpen: () => {
        Swal.hideLoading();
      }
    });
  }

  showMessageError() {
    Swal.fire({
      icon: "error",
      title: `Une erreur est survenue`,
      text: "Veuillez réessayer plus tard",
      showConfirmButton: false,
      heightAuto: false,
      timer: 2500,
    });
  }

  setImages() {
    this.photoUrls = this.images.map((item, index) => {
      return {
        url: item.url_image,
        id_image: item.id_image,
      };
    });

    this.selectedFiles = this.images.map((item) => {
      return {
        data: item.url_image,
        id_image: item.id_image,
        inProgress: true,
        progress: 100,
      };
    });

    this.imgUpload = this.images.map((item) => {
      return {
        url: item.url_image,
        id_image: item.id_image,
      };
    });
  }

  isImgUpload(type?) {
    var valid = true;
    type.forEach((el) => {
      if (el.progress < 100) valid = false;
    });
    return valid;
  }

  onFileSelected(event): void {
    var files = event.target.files;
    if (
      files.length <=
      (this.photoUrls.length <= 3 ? 3 - this.photoUrls.length : 3)
    ) {
      for (let file of files) {
        const extension = file.type;
        if (
          extension == "image/png" ||
          extension == "image/jpeg" ||
          extension == "image/jpg"
        ) {
          this.selectedFiles.push({
            data: file,
            inProgress: false,
            progress: 0,
          });

          let reader = new FileReader();
          reader.onload = (e: any) => {
            this.photoUrls.push({ url: e.target.result, id_image: null });
          };
          reader.readAsDataURL(file);
        } else {
          Swal.fire({
            icon: "warning",
            title: "Il faut télécharger une image .png ou .jpeg ou .jpg",
            heightAuto: false,
          });
        }
      }

      if (this.selectedFiles[0].inProgress == false)
        this.saveImage(this.selectedFiles[0], 0, () => {
          this.saveImages();
        });
      else this.saveImages();
    } else {
      Swal.fire({
        icon: "warning",
        title: "Vous ne pouvez sélectionner que 3 images",
        heightAuto: false,
      });
    }
  }

  removePhoto(value: any, index) {
    this.photoUrls.splice(index, 1);
    this.selectedFiles.splice(index, 1);
    this.imgUpload.splice(index, 1);
    if (value.id_image) this.deletedImages.push(value);
  }

  getExtension(photo) {
    const extension = photo.type.split("/")[1].toLowerCase();
    return extension;
  }

  saveImages() {
    console.log("this.cle_incident : ", this.cle_incident);
    // console.log(this.imgUpload)

    if (this.cle_incident) {
      for (var i = 0; i < this.selectedFiles.length; i++) {
        if (this.selectedFiles[i].inProgress == false)
          this.saveImage(this.selectedFiles[i], i, () => {});
      }
    }
  }

  saveImage(photo, index, callBackFunction?) {
    const formdata = new FormData();
    formdata.append("image", photo.data);
    photo.inProgress = true;
    this.listincidentservice
      .uploadImage(
        this.getExtension(photo.data),
        index + 1,
        this.cle_incident,
        formdata
      )

      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            photo.progress = Math.round((event.loaded / event.total) * 99);
            break;
          case HttpEventType.Response:
            photo.progress = 100;
            this.cle_incident = event.body._cle_incident;
            this.imgUpload.push(event.body);
            callBackFunction();
        }
      }),
      (err) => {
        if (err.status != 401) this.showMessageError();
        photo.inProgress = false;
      };
  }

  saveImg() {
    if (this.isImgUpload(this.selectedFiles)) {
      
      this.emitImages.emit({
        cle_incident: this.cle_incident,
        imgUpload: this.imgUpload,
        deletedImages: this.deletedImages,
      });
    } else {
      Swal.fire({
        icon: "warning",
        title:
          "Veuillez patientez quelques instants, nous chargeons vos images",
        heightAuto: false,
      });
    }
    // } else {
    //   this.requiredImage = true;
    // }
  }
}
