import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ReservationService } from 'app/service/reservation-service/reservation.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from "moment";
import Swal from "sweetalert2";
import { MatDialogRef } from '@angular/material/dialog';
import { CookieStoreService } from 'app/service/cookieService/cookieService.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ms-reservation-modal',
  templateUrl: './reservation-modal.component.html',
  styleUrls: ['./reservation-modal.component.scss']
})
export class ReservationModalComponent implements OnInit {

  reservation : any;
  id : string | number;
  id_residence : string | number;

  constructor(
    private reservationService : ReservationService, private toastr : ToastrService,
    private cookies: CookieStoreService,
    private route : ActivatedRoute,
  ) {
    this.id = this.route.snapshot.paramMap.get('id_reservation');
  }

  ngOnInit(): void {
    this.id_residence = this.cookies.getTOKEN("id_residence");  
    this.getReservation();
  }

  getReservation(){
    this.reservationService.getFiche(this.id,{id_residence : this.id_residence})
    .subscribe({
      next: (response) => {
        this.reservation = response.reservation;
      },
      error: (error : HttpErrorResponse) => {
        this.toastr.error(error.error.message ?? "Internal Error!");
      }
    })
  }

  formatTime(time : string | undefined){
    if(time){
      const parsedTime: Date = new Date(`2023-01-01T${time}`);
      return moment(parsedTime).format("H:mm");
    }
    else return "-";
  }

  formatDate(date : string | undefined){
    if(date){
      const parsedTime: Date = new Date(date);
      return moment(parsedTime).format("YYYY-MM-DD H:mm");
    }
    else return "-";
  }

  validerReservation() {
    Swal.fire({
      title: "Opération est en cours!",
      heightAuto: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    let body = {
      id_residence: this.id_residence,
      id_reservation: this.id,
    };
    this.reservationService.validerReservation(body).subscribe(
      (res) => {
        this.getReservation();
        this.showMessageSuc(`Opération réussie`);
      },
      (error) => {
        this.showMessageError();
      }
    );
  }

  rejeterReservation() {
    Swal.fire({
      title: "Veuillez clarifier le motif du rejet",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      heightAuto: false,
      confirmButtonText: "Rejeter",
      showLoaderOnConfirm: true,
      preConfirm: (motif) => {
        Swal.fire({
          title: "Opération est en cours!",
          heightAuto: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        let body = {
          id_residence: this.id_residence,
          id_reservation: this.id,
          motif,
        };
        this.reservationService.rejeterReservation(body).subscribe(
          (res) => {
            this.getReservation();
            this.showMessageSuc(`Réservation rejetée avec succès`);
          },
          (error) => {
            this.showMessageError();
          }
        );
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  annulerReservation() {
    const body = {
      id_reservation: this.id,
    };
    this.reservationService.annulerReservation(body).subscribe(
      (res) => {
        this.getReservation();
        this.showMessageWarn(`Réservation annulée`);
      },
      (error) => {
        this.showMessageError();
      }
    );
  }

  showMessageSuc(msg : string) {
    Swal.fire({
      icon: "success",
      text: msg,
      showConfirmButton: true,
      heightAuto: false,
      timer: 4000,
    });
  }

  showMessageError() {
    Swal.fire({
      icon: "error",
      text: `une erreur s'est produite. veuillez réessayer ultérieurement.`,
      showConfirmButton: false,
      heightAuto: false,
      timer: 2500,
    });
  }

  showMessageWarn(msg : string) {
    Swal.fire({
      icon: "warning",
      text: msg,
      showConfirmButton: true,
      heightAuto: false,
      timer: 4000,
    });
  }

}
