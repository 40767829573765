import { RoleGuard } from './../service/role-guard.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListAlertsComponent } from './list-alerts/list-alerts.component';
import { FicheAlertsComponent } from './fiche-alerts/fiche-alerts.component';

const routes: Routes = [
    {
      path: '',
      redirectTo: 'dashboard',
      pathMatch: 'full'
    },
    {
      path: '',
      canActivateChild: [RoleGuard],
      children: [
        {
          path: 'liste-alerts',
          component: ListAlertsComponent,
          data: { roles: ['ADMI','USER','AGENT','GEST'] }
        },
        {
          path: 'fiche-alerte/:id_alert',
          component: FicheAlertsComponent,
          data: { roles: ['ADMI','USER','AGENT','GEST'] }
        },
      ]
    }
    ]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AlertRoutingModule { }
