import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { WidgetComponentModule } from 'app/widget-component/widget-component.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { TranslateModule } from '@ngx-translate/core';
import { MatListModule } from '@angular/material/list';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxMaskModule } from 'ngx-mask';
import { MatTabsModule } from '@angular/material/tabs';
import { environment } from 'environments/environment';
import { MatRadioModule } from '@angular/material/radio';
import {MatTableDataSource} from '@angular/material/table';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { QuillModule } from 'ngx-quill';
import { ListeReservationComponent } from "./liste-reservation/liste-reservation.component";
import { ReservationsRoutingModule } from "./reservations-routing.module";
import { CalendrierReservationsComponent } from './calendrier-reservations/calendrier-reservations.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { AjouterReservationComponent } from './ajouter-reservation/ajouter-reservation.component';
import { GroupEventsDialogComponent } from './group-events-dialog/group-events-dialog.component';
import { ReservationModalComponent } from './reservation-modal/reservation-modal.component';
import { FicheReservationComponent } from './fiche-reservation/fiche-reservation.component';
import { IncidentModule } from "../incident/incident.module";

@NgModule({
    declarations: [ListeReservationComponent, CalendrierReservationsComponent, AjouterReservationComponent, GroupEventsDialogComponent, ReservationModalComponent, FicheReservationComponent],
    exports: [
        ListeReservationComponent,
        ReservationModalComponent
    ],
    imports: [
        CommonModule,
        QuillModule,
        MatCardModule,
        ReservationsRoutingModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatPaginatorModule,
        MatDividerModule,
        MatTableModule,
        MatChipsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
        MatExpansionModule,
        MatSortModule,
        MatDialogModule,
        MatMenuModule,
        FormsModule,
        ReactiveFormsModule,
        MatSnackBarModule,
        SlickCarouselModule,
        TranslateModule,
        MatListModule,
        NgbModule,
        PerfectScrollbarModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        FlexLayoutModule,
        NgxMaskModule,
        MatRadioModule,
        FullCalendarModule,
        MatTabsModule,
        IncidentModule
    ]
})
export class ReservationsModule {}
