import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ms-group-events-dialog',
  templateUrl: './group-events-dialog.component.html',
  styleUrls: ['./group-events-dialog.component.scss']
})
export class GroupEventsDialogComponent implements OnInit {

  items : any[];
  type : string;
  color: string;
  
  constructor(private router : Router) { }

  ngOnInit(): void { }

  getEventUrl(id : any){
    if(this.type === 'Interventions') return `/interventions/fiche/${id}`
    // else if(type === 'Reservation') return `reservations/fiche/${id}`
    else return null
  }

  navigateToFiche(id : any){
    const dist = this.getEventUrl(id);
    if(dist){
      this.router.navigate([dist]);
    }
  }
}
