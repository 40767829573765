import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PageTitleService } from './../../core/page-title/page-title.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FichierIntegresService } from './../../service/listfichier/fichier-integres.service';
import { AssertNotNull } from '@angular/compiler';
import { SharedService } from 'app/service/shared-service/shared.service';


@Component({
  selector: 'ms-list-fichier-detaille',
  templateUrl: './list-fichier-detaille.component.html',
  styleUrls: ['./list-fichier-detaille.component.scss']
})


export class ListFichierDetailleComponent implements OnInit {

  constructor(private route :ActivatedRoute , 
    private SharedService:SharedService,
    private pageTitleService:PageTitleService, 
    private FichierIntegresService : FichierIntegresService ,
    ) { }


  // name_residence ;
  displayedColumns: string[] = [ 'reference_immeuble', 'reference_bien', 'ref_residence',"type_fichier","date_creation","montant","type_paiement","solde"];
  id_solde  :any ;
  dataSource  !:any ;
  fichierdetailleform ! :FormGroup ;
  type_facture =null ;
  Immeuble ;
  isLoading ;
  //
  listefichierintegres  !:any ; 
  listeimmeuble !:any  ;
  id_residence : any  = 0;
  
  totallistfichier :any ;
  pageSize = 10 ;
  pageIndex = 0 ;
  


  body = {
    "id_solde":  this.route.snapshot.paramMap.get('id'),
    "id_residence" : null,
    "code_type_fichier" :  null ,
    "ref_residence" : null ,
    "reference_bien" : null,
    "reference_immeuble" :null
  };

  ngOnInit(): void {
    
    this.fichierdetailleform =new FormGroup({
      "Immeuble":new FormControl('',null),
      "type":new FormControl('',null)
    });

    this.SharedService.getSelectedIdResidenceBehavior().subscribe(value => {
      this.id_residence = value;
      
      this.body = {
        "id_solde":  this.route.snapshot.paramMap.get('id'),
        "code_type_fichier" :  null ,
        "id_residence" : this.id_residence,
        "ref_residence" : null ,
        "reference_bien" : null,
        "reference_immeuble" :null
      };

      this.getlistfichierdetaille(this.pageSize ,this.pageIndex, this.body)
      this.getlisteimmeuble()
    });

    setTimeout(() => {
      this.pageTitleService.setTitle("Liste solde détaillée");
    }, 0);

  }

  getlistfichierdetaille(records_per_page,page_number ,body:any){
    this.isLoading =true
    
    this.FichierIntegresService.getdetaillefichier(records_per_page,page_number,this.body).subscribe((res: any) => {
      this.dataSource = res.liste_soldes_detail ;
      this.totallistfichier  =res.nombre_total_solde_detail
      this.isLoading=false
  })
  }

  getNextPage(event) {
    this.pageSize = event.pageSize
    this.pageIndex = event.pageIndex
    this.getlistfichierdetaille(this.pageSize,this.pageIndex ,this.body)
  }

  handlsumbmit(){
    this.type_facture =this.fichierdetailleform.value.type  ;
    this.Immeuble =this.fichierdetailleform.value.Immeuble  ;
  
    this.body = {
          "id_solde": this.route.snapshot.paramMap.get('id'),
          "code_type_fichier" : this.type_facture ? this.type_facture :null,
          "id_residence" : this.id_residence,
          "ref_residence" :null,
          "reference_bien" :null ,
          "reference_immeuble" :this.Immeuble ? this.Immeuble :null
        }
    this.getlistfichierdetaille(this.pageSize ,this.pageIndex,this.body) ;

  }

  resetinput(){
    
    this.fichierdetailleform.reset()
    this.body = {
      "id_solde": this.route.snapshot.paramMap.get('id'),
      "code_type_fichier" : null ,
      "id_residence" : this.id_residence,
      "ref_residence" :null ,
      "reference_bien" : null,
      "reference_immeuble" :null

    }
    this.getlistfichierdetaille(this.pageSize ,this.pageIndex,this.body)
  }

  getlisteimmeuble(){
    const body1={
      "id_residence" : this.id_residence
      }
    this.isLoading=true ;
    this.FichierIntegresService.getimmeuble(body1).subscribe((res:any)=>{
      this.listeimmeuble =res.liste_immeuble_villas.immeubles
      this.isLoading=false
      
    })
  }


}
