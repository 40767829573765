import { RoleGuard } from "../service/role-guard.guard";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ListeReservationComponent } from "./liste-reservation/liste-reservation.component";
import { CalendrierReservationsComponent } from "./calendrier-reservations/calendrier-reservations.component";
import { FicheReservationComponent } from "./fiche-reservation/fiche-reservation.component";
import { ReservationModalComponent } from "./reservation-modal/reservation-modal.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "",
    canActivateChild: [RoleGuard],
    children: [
      {
        path: "liste",
        component: ListeReservationComponent,
        data: { roles: ["ADMI", "USER", "AGENT", "GEST"] },
      },
      {
        path: "fiche-reservation/:id_reservation",
        component: FicheReservationComponent,
        data: { roles: ["ADMI", "USER", "AGENT", "GEST"] },
      },
      {
        path: "calendrier",
        component: CalendrierReservationsComponent,
        data: { 
          roles: ["ADMI", "USER", "AGENT", "GEST"], 
          type: "RESE",
          title: "Agenda des réservations",
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReservationsRoutingModule {}
