import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiService } from '../api-service/api.service';
import { Apis } from '../API';

@Injectable({
  providedIn: 'root'
})
export class FichierIntegresService {

  constructor(private apiService: ApiService ,
    private http: HttpClient) { }

  
  getfichierintegres(records_per_page, page_number,body){
    return this.apiService.post(`${Apis.backoffice.liste_fichiers}/${records_per_page}/${page_number}`, body)
      
    }


  getdetaillefichier(records_per_page ,page_number,body ){
      return this.apiService.post(`${Apis.backoffice.liste_detaille_fichier}/${records_per_page}/${page_number}`, body)
    }
  
  uploadSolde(formData :FormData, id_residence : any ){     
    return  this.apiService.upload(`${Apis.backoffice.uploadSolde}/${id_residence}` ,formData)
  }


  upload1(formData :FormData ){     
    return  this.apiService.upload(`${Apis.backoffice.upload}` ,formData)
  }


  add_fichier(body){
      return this.apiService.post(`${Apis.backoffice.fichier_ajouter}`,body)
      
    }


    // get immeuble 
    getimmeuble(body){
      return this.apiService.post(`${Apis.main.liste_Immeuble}`,body)
    }
  

    getlist_residence_affecte(){
      return this.apiService.get(`${Apis.main.list_residence}/${99999}/${0}`)
    }
}
