import { Router } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { FichierIntegresService } from './../../service/listfichier/fichier-integres.service';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { Component, OnInit } from '@angular/core';
import  {FichierAjouterComponent} from '../fichier-ajouter/fichier-ajouter.component'
import * as XLSX from 'xlsx';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CookieStoreService } from 'app/service/cookieService/cookieService.service';
import { SharedService } from 'app/service/shared-service/shared.service';
// import { DatePipe } from '@angular/common';

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();

@Component({
  selector: 'ms-list-fichier-integre',
  templateUrl: './list-fichier-integre.component.html',
  styleUrls: ['./list-fichier-integre.component.scss']
})
export class ListFichierIntegreComponent implements OnInit {

  campaignOne = new FormGroup({
    date_fiche_debut: new FormControl(null),
    date_fiche_fin: new FormControl(null),
  });
  campaignTwo = new FormGroup({
    date_fiche_debut: new FormControl(new Date(year, month, 15)),
    date_fiche_fin: new FormControl(new Date(year, month, 19)),
  });

  
  residencelistForm ! :FormGroup ;
  isLoading ;
  dataSource  :any ;
  listefichierintegres ;
  
  flag_residences  ;
  date_fiche_fin  ;
  date_fiche_debut  ;
  id_residence;

  //pagination 
  totallistfichier :any ;
  pageSize = 10 ;
  pageIndex = 0 ;

  body = {
    "date_debut" : null ,
    "date_fin" :  null, 
    "id_residence" : null
  };

  displayedColumns: string[] = ['nbr_lignes', 'montant_facture',
    "montant_paiement","montant_solde",'date_integration ' ,"afficher"];
  subscription: any;

    
      constructor(private pageTitleService:PageTitleService,
              private FichierIntegresService:   FichierIntegresService  ,
              private router :Router ,
              public dialog: MatDialog ,
              private snackBar: MatSnackBar ,
              private cookies: CookieStoreService,
              public sharedService : SharedService,
              
        ) { }
        ngOnInit(): void {
          setTimeout(() => {
            this.pageTitleService.setTitle("Liste des fichiers intégrés ");
            this.id_residence = this.cookies.getTOKEN("id_residence");
            this.subscription = this.sharedService.getSelectedIdResidence().subscribe(value => {
              this.id_residence = value;
              this.userSubmit()
            });      
            this.userSubmit();
          }, 0);
          
          this.residencelistForm =new FormGroup({
            "date_fiche_fin":new FormControl('',Validators.required),
            "date_fiche_debut":new FormControl('',Validators.required),
          }) ;
      
          
        }

        ngOnDestroy(): void {
          if (this.subscription) {
            this.subscription.unsubscribe();
          }
        }

    ///////////////////open dialog//////////////////////////////
    ajouter(){
          let  dialogRef =this.dialog.open(FichierAjouterComponent,{
            width :"555px",
            
          })
            dialogRef.afterClosed().subscribe(result => {
              
                  if (result.message === 'success' ) {      
                      this.successNotification(result.message_data)
                  // }else if (result.message === "annuler"){
                  //     this.snackBar.open(`Fenetre colsed `, 'close', {
                  //         duration: 2000,
                  //       }) 
                  }else if (result.message === "fail") {
                    this.erreurNotification(result.message_data)
                  }
                  
                    this.getlistfichierintegres(this.pageSize, this.pageIndex)
              
            });
    }
    
    //alert  message 
    successNotification(message_data) {
      Swal.fire('Succès', `${message_data} !`, 'success');
    }
    erreurNotification(message_data) {
      Swal.fire('Erreur', ` ${message_data}`, 'error');
    }

    ///////////////////////////////////////////


    




  
  //filtre data 
  userSubmit(){
    this.pageIndex = 0;
    this.flag_residences =this.residencelistForm.value.residences 
    this.date_fiche_debut =this.campaignOne.get('date_fiche_debut').value;
    this.date_fiche_fin =this.campaignOne.get('date_fiche_fin').value;

    
    // console.log(this.date_fiche_debut)
    this.body = {
      "date_debut" : this.date_fiche_debut ? this.date_fiche_debut : null ,
      "date_fin" :  this.date_fiche_fin ? this.date_fiche_fin : null,
      "id_residence" : this.id_residence
    }
    this.getlistfichierintegres(this.pageSize, this.pageIndex) ;
    
  }

  

  // stouck all data 
  getlistfichierintegres(records_per_page:number,page_number:number) {
    this.isLoading = true;
    this.FichierIntegresService.getfichierintegres(records_per_page,page_number,this.body).subscribe((res: any) => {
      
      
      this.listefichierintegres = res.residences ;
      this.dataSource=res.liste_soldes ;
      this.totallistfichier  =res.nbr_total_soldes
      this.isLoading = false;
  })
  }




  getNextPage(event) {
    this.pageSize = event.pageSize
    this.pageIndex = event.pageIndex
    this.getlistfichierintegres(event.pageSize, event.pageIndex)
    
  }


    //reset 
  resetinput(){

    this.campaignOne = new FormGroup({
      date_fiche_debut: new FormControl(null),
      date_fiche_fin: new FormControl(null),
    });
    this.pageIndex = 0
    this.residencelistForm.reset()
    this.getlistfichierintegres(this.pageSize, this.pageIndex)
    // this.getlistfichierintegres()

  
  }



  //  list detaille fichier 
  listdetaille(id_fichier ,id_residence){
    const queryParams = { id_residence: id_residence};
    this.router.navigate([`/liste_fichier/liste/${id_fichier}`] );
  }



}
