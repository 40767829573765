import { Component, OnInit } from '@angular/core';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { CurrentUserService } from 'app/service/current-user.service';
import { UtilisateurService } from 'app/service/utilisateur-service/utilisateur.service';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { ListIncidentService } from 'app/service/list-incident-service/list-incident.service';
import { CookieStoreService } from 'app/service/cookieService/cookieService.service';
import { SharedService } from 'app/service/shared-service/shared.service';
import { AjouterUtilisateurComponent } from '../ajouter-utilisateur/ajouter-utilisateur.component';
import { Router } from '@angular/router';
import { FicheUtilisateurComponent } from '../fiche-utilisateur/fiche-utilisateur.component';

@Component({
  selector: 'ms-liste-utilisateurs',
  templateUrl: './liste-utilisateurs.component.html',
  styleUrls: ['./liste-utilisateurs.component.scss']
})
export class ListeUtilisateursComponent implements OnInit {
  isLoading
  totalUtilisateurs
  listeUtilisateurs :any
  roles = ['ADMI','AGEN','GEST','USER']
  UtilisateurForm: FormGroup;
  userItem
  pageSize = 10
  pageIndex = 0
  nbr_total_affectations
  listeResidence = []
  affectations : any
  affectation : any
  id_affectation
  residences
  incident
  ListStatut = []
  id_residence
  subscription

  constructor(
    private pageTitleService    : PageTitleService   ,
    private _currentUser        : CurrentUserService ,
    private utilisateurService  : UtilisateurService ,
    private router              : Router              ,
    private formBuilder         : FormBuilder        ,
    public dialog               : MatDialog         ,
    private listincidentservice : ListIncidentService ,
    private cookies: CookieStoreService,
    public sharedService : SharedService,   

  ) {
    this.userItem = this._currentUser.getRoleCurrentUser();
    
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.pageTitleService.setTitle("Liste des genstionnaires");
    }, 0);
    this.initForm();
    this.getStatut();

    this.id_residence = this.cookies.getTOKEN("id_residence");
    this.subscription = this.sharedService.getSelectedIdResidence().subscribe(value => {
      this.id_residence = value;
        this.getUtilisateur(this.pageSize, this.pageIndex);
      });      
      this.getUtilisateur(this.pageSize, this.pageIndex);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  initForm() {
    this.UtilisateurForm = this.formBuilder.group({
      code_role_compte : [null],
      code_statut_affectation : [null],
      tel_compte : [null],
      nom_compte : [null]
    });
  }
  

  reset() {
    this.UtilisateurForm.reset();
    this.pageIndex = 0
    this.getUtilisateur(this.pageSize, this.pageIndex)
  }

  filtrer(){
    this.pageIndex = 0;
    this.getUtilisateur(this.pageSize, this.pageIndex);
    }

  getUtilisateur(records_per_page, page_number) {
    this.isLoading = true;
    this.listeUtilisateurs = []
    let body = {
      "id_residence" : this.id_residence,
      "code_role_compte" : "GEST",
      "nom_compte" : this.UtilisateurForm.get('nom_compte').value,
      "tel_compte" : this.UtilisateurForm.get('tel_compte').value,
      "code_statut_affectation" : this.UtilisateurForm.get('code_statut_affectation').value,
      "showInactive" : "O"
    };
    this.utilisateurService.getUtilisateurs(records_per_page, page_number, body)
      .subscribe((res: any) => {
        this.listeUtilisateurs = res.affectations;
        this.affectation = res.affectations
        // console.log(this.affectation)
        this.totalUtilisateurs = res.nbr_total_affectations;
        this.isLoading = false;
      }, err => {
        this.showMessageError();
        this.isLoading = false;
      })
  }

  ficheUtilisateur(id : any) {
    const dialogRef = this.dialog.open(FicheUtilisateurComponent, {
      width: '900px',
      autoFocus: false,
      maxHeight: '85vh'
    });
    dialogRef.componentInstance.id_compte = id;
  }

  showMessageError() {
    Swal.fire({
      icon: 'error',
      text: `une erreur s'est produite. veuillez réessayer ultérieurement.`,
      showConfirmButton: false,
      heightAuto: false,
      timer: 2500,
    });
  }

  ajouter_utilisateur() {
    let  dialogRef = this.dialog.open(AjouterUtilisateurComponent,{
        width :"700px",
        maxHeight: "80vh"
    });
    dialogRef.componentInstance.roleSelected = "GEST"
    dialogRef.afterClosed().subscribe(result => {
      this.pageIndex = 0;
      this.getUtilisateur(this.pageSize, this.pageIndex);
    })
  }

  getNextPage(event) {
    this.pageSize = event.pageSize
    this.pageIndex = event.pageIndex
    this.getUtilisateur(event.pageSize, event.pageIndex)
  }
  resetData(event) {
    if (event == true)
      this.getUtilisateur(this.pageSize, this.pageIndex)
  }

  openFiche(id_compte:any):void{
    this.router.navigate([`residence/fiche-salarie/${id_compte}`] );
  }
  
  getStatut() {
    this.ListStatut = [];
    let body ={
      "type_parametrages" : "STAF"
    }
    this.listincidentservice.getStatut(body)
      .subscribe((res: any) => {
      this.ListStatut = res.parametrage;
    })
  }

}
