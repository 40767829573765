import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api-service/api.service';
import { Apis } from '../API';


@Injectable({
  providedIn: 'root'
})
export class NewsServiceService {

  constructor(private apiService: ApiService, private http: HttpClient) { }

  gelListNews(records_per_page, page_number, body) {
    return this.apiService.post(`${Apis.main.liste_news}/${records_per_page}/${page_number}`, body)
  }

  getCategories(id_residence){
    return this.apiService.get(`${Apis.main.liste_categories}/${id_residence}`)
  }
  getPopulation(id_residence){
    return this.apiService.post(`${Apis.main.liste_Immeuble}`,id_residence)
  }

  getFicheNews(id_news, body) {
    return this.apiService.post(`${Apis.main.fiche_news}/${id_news}`, body)
  }
  desactiverNews(id_news, body) {
    return this.apiService.post(`${Apis.main.desactiver_news}/${id_news}`, body)
  }
  ajouterNews(body){
    return this.apiService.post(`${Apis.main.ajouter_news}`,body)
  }
  updateNews(id_news, body){
    return this.apiService.post(`${Apis.main.update_news}/${id_news}`,body)
  }
}
