import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api-service/api.service';
import { Apis } from '../API';

@Injectable({
  providedIn: 'root'
})
export class ResidencesService {

  constructor(private apiService: ApiService, private http: HttpClient) { }

  getListResidences(records_per_page, page_number) {
    return this.apiService.get(`${Apis.main.liste_residences}/${records_per_page}/${page_number}`)
  }

  getBiensResidences(body) {
    return this.apiService.post(`${Apis.main.liste_Immeuble}`,body)
  }
  
  getaAllBiens(body) {
      return this.apiService.post(`${Apis.main.all_biens}`,body)
  }
  getFicheResidences(id_residence) {
    return this.apiService.post(`${Apis.main.fiche_residence}/${id_residence}`)
  }

  ajouterAffectationResident(body) {
    return this.apiService.post(`${Apis.main.ajouter_affectation_resident}`,body)
  }
  ModifierCompte(body) {
    return this.apiService.post(`${Apis.main.modifier_compte}`,body)
  }
  modifierAffectationResident(body) {
    return this.apiService.post(`${Apis.main.modifier_affectation_resident}`,body)
  }

  ajouterAffectationBien(body) {
    return this.apiService.post(`${Apis.main.ajouter_bien_autre}`,body)
  }

  supprimerBien(body) {
    return this.apiService.post(`${Apis.main.supprimer_bien}`,body)
  }

  getListeMetiersResidence (body) {
    return this.apiService.post(`${Apis.main.liste_metiers_residence}`,body)
  }

  uploadDocument (extension_document,id_objet,code_objet,nom_document,ordre_document,fd) {
    return this.apiService.upload(`${Apis.main.upload_document}/${extension_document}/${id_objet}/${code_objet}/${nom_document}/${ordre_document}`,fd)
  }
  
  getVersion () {
    return this.apiService.get(`${Apis.main.version}`)
  }

}