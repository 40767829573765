import { Injectable } from '@angular/core';
import { ApiService } from '../api-service/api.service';
import { Apis } from '../API';

@Injectable({
  providedIn: 'root'
})
export class AgendaService {

  constructor(private apiService: ApiService) { }

  listIntervention(data : any){
    return this.apiService.post(Apis.main.agenda_intervention, data)
  }

  listRequests(data : any){
    return this.apiService.post(Apis.main.agenda_requests, data)
  }

  listReservation(data : any){
    return this.apiService.post(Apis.main.agenda_reservations, data)
  }

  parametrage(data : any){
    return this.apiService.post(Apis.main.list_parametrage, data)
  }

  getReservationColors(body : any){
    return this.apiService.post(Apis.main.color_reservation, body);
  }
}
