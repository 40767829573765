import { Component, OnInit } from '@angular/core';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { FormBuilder,FormControl,FormGroup} from '@angular/forms';
import { ListIncidentService} from 'app/service/list-incident-service/list-incident.service';
import { CurrentUserService } from 'app/service/current-user.service';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import Swal from 'sweetalert2';

@Component({
  selector: 'ms-qualifier-traitement',
  templateUrl: './qualifier-traitement.component.html',
  styleUrls: ['./qualifier-traitement.component.scss']
})
export class QualifierTraitementComponent implements OnInit {
  cleIncident
  isLoading = false
  userItem
  incident
  form: FormGroup;
  listeMotifAbandon
  isSubmitted: boolean;
  cle_incident: any;
  id_incident : any;
  images: any;
  imagesList: [];
  imagesListProv = []
  photoUrls
  imgUpload
  selectedFiles
  imgExtension
  order
  fd
  code_statut_incident
  description
  motif_abandon
  qualifTraitIncidentForm;
  listeCategories = []  
  deletedImages = []
  emitImages

  constructor(
    private pageTitleService    : PageTitleService                      ,
    private formBuilder         : FormBuilder                           ,
    private listincidentservice : ListIncidentService                   ,
    private _currentUser        : CurrentUserService                    , 
    public  dialog              : MatDialog                             , 
    public  dialogRef           : MatDialogRef<QualifierTraitementComponent>
  ) {
    this.userItem = this._currentUser.getRoleCurrentUser();
    this.qualifTraitIncidentForm = new FormGroup({
      code_statut_incident   : new FormControl(),
      description_backoffice : new FormControl(),
    });
   }

  ngOnInit(): void {
    setTimeout(()=>{
      this.pageTitleService.setTitle("Qualifier Traitement");
    },0)
  }



  initForm(data?) {
    this.qualifTraitIncidentForm = this.formBuilder.group({
      id_utilisateur           : [this.userItem != null ? this.userItem.id_utilisateur : null],
      cle_incident             :  [this.cleIncident ? this.cleIncident : null],
      code_statut_incident     : [data ? (data.code_statut_reponse != null ? data.code_statut_reponse : data.code_statut_incident)    : null],
      description_backoffice   : [data ? data.description_backoffice : null],
    });
  }


  quaifierTraitement(){
    this.isLoading = true;
    // console.log(this.qualifTraitIncidentForm)
   let body = {
      "id_incident"          : this.id_incident,
      "code_statut_incident" : this.qualifTraitIncidentForm.controls['code_statut_incident'].value,
      "description"          : this.qualifTraitIncidentForm.get('description_backoffice').value,
      "images"               : this.images
    };

    this.listincidentservice.qualifierTraitement(body).subscribe((res:any) => {
      this.incident = res;
      this.cle_incident = this.incident.cle_incident;
      this.isLoading = false;
    },err => {
      this.isLoading = false;
    })
  }

  onChangeStatutIncident(){
    if (this.qualifTraitIncidentForm.controls['code_statut_incident'].value == 'CLOT' || this.qualifTraitIncidentForm.controls['code_statut_incident'].value == 'ATRA') {
      this.qualifTraitIncidentForm.get('description_backoffice').setValue(null);
      this.photoUrls = [];
      this.imgUpload = [];
      this.selectedFiles = [];
    }
    else {
      this.photoUrls = [];
      this.imgUpload = [];
      this.selectedFiles = [];
    }
  }

  save() {
    this.isSubmitted = true
    setTimeout(() => {
      if (this.isSubmitted && this.qualifTraitIncidentForm.valid) {
        Swal.fire({
          title: "Opération est en cours!",
          heightAuto: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
        const body = this.qualifTraitIncidentForm.value
        body["images"] = this.imgUpload.map((el, index) => el.id_image)

        this.listincidentservice.qualifierTraitement(body).subscribe(success => {
          this.showMessageSuc(`Opération réussie`);
        }, error => {
          this.showMessageError();
        })
      }
    }, 100);
  }

  showMessageSuc(msg) {
    Swal.fire({
      icon: 'success',
      text: msg,
      showConfirmButton: true,
      heightAuto: false,
      timer: 4000,
    });
    this.dialogRef.close(true)
  }

  showMessageError() {
    Swal.fire({
      icon: 'error',
      title: `Une erreur est survenue`,
      text: 'Veuillez réessayer plus tard',
      showConfirmButton: false,
      heightAuto: false,
      timer: 2500
    });
  }
  

  setImages() {
    this.photoUrls = this.images.map((item, index) => {
      return {
        url: item.url_image,
        id_image: item.id_image,
      };
    });

    this.selectedFiles = this.images.map((item) => {
      return {
        data: item.url_image,
        id_image: item.id_image,
        inProgress: true,
        progress: 100,
      };
    });

    this.imgUpload = this.images.map((item) => {
      return {
        url: item.url_image,
        id_image: item.id_image,
      };
    });

  }

  isImgUpload(type?) {
    var valid = true;
    type.forEach((el) => {
      if (el.progress < 100) valid = false;
    });
    return valid;
  }

  onFileSelected(event): void {
    var files = event.target.files;
    if (
      files.length <=
      (this.photoUrls.length <= 3 ? 3 - this.photoUrls.length : 3)
    ) {
      for (let file of files) {
        const extension = file.type;
        if (
          extension == 'image/png' ||
          extension == 'image/jpeg' ||
          extension == 'image/jpg'
        ) {
          this.selectedFiles.push({
            data: file,
            inProgress: false,
            progress: 0,
          });

          let reader = new FileReader();
          reader.onload = (e: any) => {
            this.photoUrls.push({ url: e.target.result, id_image: null });
          };
          reader.readAsDataURL(file);
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Il faut télécharger une image .png ou .jpeg ou .jpg',
            heightAuto: false,
          });
        }
      }

      if (this.cle_incident && this.selectedFiles[0].inProgress == false)
        this.saveImage(this.selectedFiles[0], 0, () => {
          this.saveImages();
        });
      else this.saveImages();
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Vous ne pouvez sélectionner que 3 images',
        heightAuto: false,
      });
    }
  }

  removePhoto(value: any, index) {
    this.photoUrls.splice(index, 1);
    this.selectedFiles.splice(index, 1);
    this.imgUpload.splice(index, 1);
    if (value.id_image)
      this.deletedImages.push(value);
  }

  getExtension(photo) {
    const extension = photo.type.split('/')[1].toLowerCase();
    return extension;
  }

  saveImages() {
    console.log("this.cle_incident : ",this.cle_incident)
    // console.log(this.imgUpload)

    if (this.cle_incident) {
      for (var i = 0; i < this.selectedFiles.length; i++) {
        if (this.selectedFiles[i].inProgress == false)
          this.saveImage(this.selectedFiles[i], i, () => { });
      }
    }
  }

  saveImage(photo, index, callBackFunction?) {
    const formdata = new FormData();
    formdata.append('image', photo.data);
    photo.inProgress = true;
    this.listincidentservice
      .uploadImage(
        this.getExtension(photo.data),
        index + 1,
        this.cle_incident,
        formdata
      )
      
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            photo.progress = Math.round((event.loaded / event.total) * 99);
            break;
          case HttpEventType.Response:
            photo.progress = 100;
            this.cle_incident = event.body._cle_incident;
            this.imgUpload.push(event.body);
            callBackFunction();
        }
      }),
      (err) => {
        if (err.status != 401) this.showMessageError();
        photo.inProgress = false;
      };
  }

  saveImg() {
    if (this.isImgUpload(this.selectedFiles)) {
      this.emitImages.emit({
        cle_incident: this.cle_incident,
        imgUpload: this.imgUpload,
        deletedImages: this.deletedImages,
      });
    } else {
      Swal.fire({
        icon: 'warning',
        title:
          'Veuillez patientez quelques instants, nous chargeons vos images',
        heightAuto: false,
      });
    }
  // } else {
  //   this.requiredImage = true;
  // }
  }

}
