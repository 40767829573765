import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { CoreService } from '../../service/core/core.service';
import { MenuItems } from '../../core/menu/menu-items/menu-items';
import { CurrentUserService } from 'app/service/current-user.service';
import { CookieStoreService } from 'app/service/cookieService/cookieService.service';
import { ResidencesService } from 'app/service/residences/residences.service';
import { environment } from 'environments/environment';

@Component({
   selector: 'ms-side-bar',
   templateUrl: './side-bar.component.html',
   styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
   @Input() menuList: any;
   @Input() verticalMenuStatus: boolean;
   userItem;
   version;
   token;
   id_residence: string;
   currentResidenceInfo;
   source_logo;
   constructor(
      public translate: TranslateService,
      private router: Router,
      public coreService: CoreService,
      private _currentUser: CurrentUserService,
      public menuItems: MenuItems,
      private cookies :  CookieStoreService,
      private residencesService : ResidencesService
   ) {
      this.userItem = this._currentUser.getRoleCurrentUser();
   }
   _menuItems = this.menuItems.getAll()

   ngOnInit() {
      this.source_logo = environment.code_logo === 'dev' ? "assets/img/resident-dev-dark.png" : 
                         environment.code_logo === 'pre' ? "assets/img/resident-pre-dark.png" : "assets/img/resident-app-dark.png"
      this.id_residence = this.cookies.getTOKEN("id_residence");
      this.token = this.cookies.getTOKEN("userData");
      this.token = JSON.parse(this.token).nom_compte;
      this.residencesService.getFicheResidences(this.id_residence).subscribe(res => {
        this.currentResidenceInfo = res.residence
        console.log(this.currentResidenceInfo);
      })
      this.getVersion();
    }
    getInitials(): string {
      if (this.currentResidenceInfo?.nom_compte) {
        return this.currentResidenceInfo?.nom_compte
          .split(' ')
          .map(name => name.charAt(0).toUpperCase())
          .join('');
      } else {
        return '';
      }
    }
    getVersion() {
      this.residencesService.getVersion().subscribe(res => {
        console.log(res);
        
        this.version = res.version
      })
    }
   //render to the crm page
   onClick() {
      var first = location.pathname.split('/')[1];
      if (first == 'horizontal') {
         this.router.navigate(['/horizontal/dashboard/crm']);
      } else {
         this.router.navigate(['/dashboard/crm']);
      }
   }

}
