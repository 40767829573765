import { AfterViewInit, Component, Input,EventEmitter, OnInit, ViewChild ,Output,ViewEncapsulation } from "@angular/core";
import { PageTitleService } from "app/core/page-title/page-title.service";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { ListIncidentService } from "app/service/list-incident-service/list-incident.service";
import { CurrentUserService } from "app/service/current-user.service";
import { FicheIncidentComponent } from "../fiche-incident/fiche-incident.component";
import { MatDialog } from "@angular/material/dialog";
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

import Swal from "sweetalert2";
import { CookieStoreService } from "app/service/cookieService/cookieService.service";
import { SharedService } from "app/service/shared-service/shared.service";
import { UtilisateurService } from "app/service/utilisateur-service/utilisateur.service";
import { QualifIncidentTachesComponent } from "app/taches/qualif-incident-taches/qualif-incident-taches/qualif-incident-taches.component";
import { QualifierTraitementTachesComponent } from "app/taches/qualifier-traitement-taches/qualifier-traitement-taches/qualifier-traitement-taches.component";
import { AjouterIncidentComponent } from "../ajouter-incident/ajouter-incident.component";
import { AjouterInterventionComponent } from "app/taches/traiter-incident-taches/traiter-incident-taches/ajouter-intervention.component";
import { startWith, map, filter } from "rxjs/operators";
import { Observable } from "rxjs";

export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = "Objets par page";

  return customPaginatorIntl;
}

@Component({
  selector: "ms-liste-incident",
  templateUrl: "./liste-incident.component.html",
  styleUrls: ["./liste-incident.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class ListeIncidentComponent implements OnInit, AfterViewInit {
  @Output() totalIncidentsChanged: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild(CdkVirtualScrollViewport)
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport;
  @Input() fiche: any = { isfiche: false, id_compte: null };
  IncidentForm;
  pageSize = 10;
  pageIndex = 0;
  code_statut_incident: string = null;
  code_categorie_prin: string = null;
  code_categorie_seco: string = null;
  id_residence;
  longitude: string = null;
  latitude: string = null;
  isLoading;
  incidentForm: FormGroup;
  ListIncident = [];
  totalIncidents: number = 0;
  userItem;
  categories;
  listeResidence = [];
  listeCategories = [];
  listeSousCategories = [];
  listeTypeIncidents = [];
  listeEtapes = [];
  listeUtilisateur = [];
  idcategorie;
  ListStatut = [];
  cle_incident;
  id_incident;
  incident;
  records_per_page;
  page_number;
  idIncident;
  ListHistorique;
  localLattitude;
  localLongitude;
  filtreTypeIncidents;
  subscription: any;
  role_user: any;
  selectedOption;
  // options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  filterelisteUtilisateur: any[] = [];
  constructor(
    private pageTitleService: PageTitleService,
    private formBuilder: FormBuilder,
    private listincidentservice: ListIncidentService,
    private _currentUser: CurrentUserService,
    private utilisateurService: UtilisateurService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private cookies: CookieStoreService,
    public sharedService: SharedService,
    private router: Router
  ) {
    this.userItem = this._currentUser.getRoleCurrentUser();
    this.idIncident = this.route.snapshot.paramMap.get("id_incident");
  }
  ngAfterViewInit(): void {
    
  }
 
  ngOnInit(): void {
    this.id_residence = this.cookies.getTOKEN("id_residence");
    if (!this.fiche.isFiche) {

      setTimeout(() => {
        this.pageTitleService.setTitle("Liste des incidents");

      }, 0);

      this.getResidenceCategories();
      this.getTypeIncident();
      this.getUtilisateur();
      this.getStatut();
      // this.subscription = this.sharedService.getSelectedIdResidence().subscribe(value => {
      //   this.id_residence = value;
      //   this.getListIncident(this.pageSize, this.pageIndex);
      // });
      this.utilisateurService
        .getRoleUserPerResidence({ id_residence: this.id_residence })
        .subscribe((value) => {
          this.role_user = value;
        });
      this.initIncidentForm();
    }
    this.getEtape();
    this.getListIncident(this.pageSize, this.pageIndex);
  }

  



  ngOnChanges(changes) {
    if (this.fiche?.isFiche) {
      this.initIncidentForm();
      this.getListIncident(this.pageSize, this.pageIndex);
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  initIncidentForm() {
    this.IncidentForm = this.formBuilder.group({
      code_statut_incident: [null],
      latitude: [null],
      longitude: [null],
      code_categorie_seco: [null],
      code_categorie_prin: [null],
      code_type_incident: [null],
      code_etape: [null],
      createur: [null],
      code_role_createur: [null],
      demandeur: [null],
      code_role_demandeur: [null],
      reference_incident: [null],
    });
  }
  getTypeIncident() {
    const body = {
      "code_type_categorie": 'TYIN'
    }
    this.listincidentservice.getTypeIncident(body).subscribe((result) => {
      this.filtreTypeIncidents = result;
      
    }, (error) => {
      this.showMessageError();
    })
  }
  getListIncident(records_per_page, page_number) {
    this.isLoading      = true;
    this.ListIncident = [];
    this.id_residence = this.cookies.getTOKEN("id_residence");
    let body = {
      code_statut_incident: this.IncidentForm.get("code_statut_incident").value || null,
      code_categorie_prin: this.IncidentForm.get("code_categorie_prin").value || null,
      code_categorie_seco: this.IncidentForm.get("code_categorie_seco").value || null,
      code_type_incident: this.IncidentForm.get("code_type_incident").value || null,
      code_etape: this.IncidentForm.get("code_etape").value || null,
      id_residence: this.id_residence,
      id_createur: this.IncidentForm.get("createur").value?.id_createur || null,
      code_role_createur: this.IncidentForm.get("createur").value?.code_role_createur || null,
      id_demandeur: this.IncidentForm.get('demandeur').value?.id_demandeur ? this.IncidentForm.get('demandeur').value.id_demandeur : this.fiche?.isFiche ? Number(this.fiche?.id_compte): null,
      code_role_demandeur: this.IncidentForm.get('demandeur').value?.code_role_demandeur ? this.IncidentForm.get('demandeur').value.code_role_demandeur : this.fiche?.isFiche ? this.fiche?.code_role_compte: null,
      longitude: null,
      latitude: null,
      reference_incident:
        this.IncidentForm.get("reference_incident").value || null,
        
    };
   
    this.listincidentservice
      .getListIncident(records_per_page, page_number, body)
      .subscribe(
        (res: any) => {
          this.ListIncident = res.incidents;
          this.incident = res;
          this.totalIncidents = res.nbr_total_incidents;
          this.totalIncidentsChanged.emit(this.totalIncidents);
          this.isLoading = false;
        },
        (err) => {
          console.log(err);

          this.isLoading = false;
        }
      );
  }

  getIcon(codeRole: string): string {
    return codeRole === 'GEST' ? '../../../assets/icon/icon-resident-back.png' : '../../../assets/icon/icon-resident-resi.png';
  }
  clearSearchInput(inputElement: HTMLInputElement, index: number): void {
    inputElement.value = '';
    this.filterUtilisateur('', index);
  }

  openChange($event: boolean, index?, id_demandeur?) {
    if ($event) {
      this.cdkVirtualScrollViewPort.scrollToIndex(0);
      this.cdkVirtualScrollViewPort.checkViewportSize();
    }
  }
  filterUtilisateur(value, index?) {
    this.filterelisteUtilisateur = this.listeUtilisateur
    this.filterelisteUtilisateur = this.filterelisteUtilisateur.
      filter(utilisateur => utilisateur.nom_compte.toUpperCase().includes(value.toUpperCase()))

  }
  getResidenceCategories() {
    const body = {
      id_residence: Number(this.cookies.getTOKEN("id_residence")),
    };
    this.listincidentservice.getResidenceCategories(body).subscribe(
      (res: any) => {
        this.listeCategories = res.categories;
      },
      (error) => {
        this.showMessageError();
      }
    );
  }

  getSousCategories() {
    if (this.IncidentForm.get("code_categorie_prin").value) {
      this.listeSousCategories = this.listeCategories.find(
        (category) =>
          category.code_categorie ==
          this.IncidentForm.get("code_categorie_prin").value
      ).liste_sous_categories;
    }
  }

  getTypeIncidents() {
    if (this.IncidentForm.get("code_categorie_seco")) {
      this.listeTypeIncidents = this.listeSousCategories.find(
        (category) =>
          category.code_categorie ==
          this.IncidentForm.get("code_categorie_seco").value
      ).liste_type_incidents;
    }
  }
  showMessageError() {
    Swal.fire({
      icon: "error",
      title: `Une erreur est survenue`,
      text: "Veuillez réessayer plus tard",
      showConfirmButton: false,
      heightAuto: false,
      timer: 2500,
    });
  }

  getStatut() {
    this.ListStatut = [];
    let body = {
      id_residence: this.id_residence,
    };

    this.listincidentservice.getStatutIncident(body).subscribe((res: any) => {
      this.ListStatut = res.parametrage;
    });
  }

  getEtape() {
    this.listeEtapes = [];
    let body = {
      type_parametrages: "COET",
    };
    this.listincidentservice.getStatut(body).subscribe((res: any) => {
      this.listeEtapes = res.parametrage;
    });
  }

  getUtilisateur() {
    const body = {
      code_role_compte: ["GEST", "RESI"],
      id_residence: this.id_residence,
    };
    this.utilisateurService.getListeUtilisateurs(body).subscribe(
      (result) => {
        this.listeUtilisateur = this.filterelisteUtilisateur =  result;
      },
      (error) => {
        console.log(error);

        this.showMessageError();
      }
    );
  }
  reset() {
    this.initIncidentForm();
    this.pageIndex = 0;
    this.getListIncident(this.pageSize, this.pageIndex);
  }

  filtrer() {
    this.pageIndex = 0;
    this.getListIncident(this.pageSize, this.pageIndex);
  }

  getNextPage(event) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getListIncident(event.pageSize, event.pageIndex);
  }

  openDialogFicheIncident(idIncident): void {
    const dialogRef = this.dialog.open(FicheIncidentComponent, {
      width: "800px",
      autoFocus: false,
      maxHeight: "85vh",
    });
    dialogRef.componentInstance.incident = idIncident;
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getListIncident(this.pageSize, this.pageIndex);
      }
    });
  }

  /* resetData(event) {
    if (event == true) this.getListIncident(this.pageSize, this.pageIndex);
  } */

  openQualifDialog(item) {
    const dialogRef = this.dialog.open(QualifIncidentTachesComponent, {
      width: "800px",
      autoFocus: false,
      maxHeight: "85vh",
    });
    dialogRef.componentInstance.cle_incident = item.cle_incident;
    dialogRef.componentInstance.id_incident = item.id_incident;
    dialogRef.componentInstance.id_residence = item.id_residence;
    dialogRef.componentInstance.code_categorie_prin = item.code_categorie_prin;
    dialogRef.componentInstance.code_type_incident = item.code_type_incident;
    dialogRef.componentInstance.code_categorie_seco = item.code_categorie_seco;
    dialogRef.componentInstance.code_type_incident =
      item.incident.code_type_incident;
    dialogRef.componentInstance.code_statut_incident =
      item.code_statut_incident;
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getListIncident(this.pageSize, this.pageIndex);
      }
    });
  }

  openTraitDialog(item) {
    const dialogRef = this.dialog.open(AjouterInterventionComponent, {
      width: "800px",
      autoFocus: false,
      maxHeight: "85vh",
    });
    dialogRef.componentInstance.cle_incident = item.cle_incident;
    dialogRef.componentInstance.id_incident = item.id_incident;
    dialogRef.componentInstance.isTypeDisable = true;
    dialogRef.componentInstance.code_origin_intervention = "INCI";
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getListIncident(this.pageSize, this.pageIndex);
      }
    });
  }

  openQualifTraitDialog(item) {
    const dialogRef = this.dialog.open(QualifierTraitementTachesComponent, {
      width: "800px",
      autoFocus: false,
      maxHeight: "85vh",
    });
    dialogRef.componentInstance.cle_incident = item.cle_incident;
    dialogRef.componentInstance.id_incident = item.id_incident;
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getListIncident(this.records_per_page, this.page_number);
      }
    });
  }
  AjouterIncident() {
    const dialogRef = this.dialog.open(AjouterIncidentComponent, {
      width: "900px",
      autoFocus: false,
      maxHeight: "90vh",
      panelClass: 'custom-dialog-container',
    });
    dialogRef.afterOpened().subscribe(() => {
      const dialogContainer = document.querySelector('.custom-dialog-container');
      if (dialogContainer) {
        const dialogContent = dialogContainer.querySelector('.mat-dialog-content');
        if (dialogContent instanceof HTMLElement) {
          // Update height and overflow properties
          dialogContent.style.height = '34vh';
          dialogContent.style.overflow = 'auto';
        }
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getListIncident(this.pageSize, this.pageIndex);
      }
    });
  }
  
  
}
