import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api-service/api.service';
import { Apis } from '../API';

@Injectable({
  providedIn: 'root'
})
export class ListresidentService {
  constructor(private apiService: ApiService, private http: HttpClient) { }
  
  getListResident(records_per_page, page_number, body) {
    return this.apiService.post(`${Apis.backoffice.liste_resident}/${records_per_page}/${page_number}`, body)
  }
  getFicheResident(id_compte, body) {
    return this.apiService.post(`${Apis.main.fiche_resident}/${id_compte}`,body)
  }

  getListBiens(records_per_page, page_number, body) {
    return this.apiService.post(`${Apis.main.list_biens}/${records_per_page}/${page_number}`, body)
  }

  getListIncident(records_per_page, page_number, body) {
    return this.apiService.post(`${Apis.main.list_incident}/${records_per_page}/${page_number}`, body)
  }
  getListAutorisation(records_per_page, page_number, body) {
    return this.apiService.post(`${Apis.main.list_autorisation}/${records_per_page}/${page_number}`, body)
  }

  validerAffectationBien(body) {
    return this.apiService.post(`${Apis.backoffice.valider_affectation_bien}`,body)
  }

  rejeterAffectationBien(body) {
    return this.apiService.post(`${Apis.backoffice.rejeter_affectation_bien}`,body)
  }

  supprimerAffectationBien(body) {
    return this.apiService.post(`${Apis.backoffice.supprimer_affectation_bien}`,body)
  }

  getListBiensWithoutPage(body) {
    return this.apiService.post(Apis.main.res_list_biens, body);
  }
}