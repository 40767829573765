import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from 'app/service/role-guard.guard';
import { ListeUtilisateursComponent } from './liste-utilisateurs/liste-utilisateurs.component';
import { FicheUtilisateurComponent } from './fiche-utilisateur/fiche-utilisateur.component';
import { ListeAgentsComponent } from './liste-agents/liste-agents.component';

const routes: Routes = [
  {
    path: 'residence',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    canActivateChild: [RoleGuard],
    children: [
      {
        path: 'liste-utilisateurs',
        component: ListeUtilisateursComponent,
        data: { roles: ['ADMI','USER','AGENT','GEST'] }
      },
      {
        path: 'liste-agents',
        component: ListeAgentsComponent,
        data: { roles: ['ADMI','USER','AGENT','GEST'] }
      },
      {
        path: 'fiche-salarie/:id_compte',
        component: FicheUtilisateurComponent,
        data: { roles: ['ADMI','USER','AGENT','GEST'] }
      },
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UtilisateursRoutingModule { }
