import { AlertModule } from './alerts/alert.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule, Routes } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Ng5BreadcrumbModule, BreadcrumbService } from 'ng5-breadcrumb';
import { TourMatMenuModule } from 'ngx-tour-md-menu';
import { ToastrModule } from 'ngx-toastr';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RoutingModule } from "./app-routing.module";
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { AuthService } from './service/auth-service/auth.service';
import { PageTitleService } from './core/page-title/page-title.service';
import { GeneAppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { MenuToggleModule } from './core/menu/menu-toggle.module';
import { MenuItems } from './core/menu/menu-items/menu-items';
import { AuthGuard } from './core/guards/auth.guard';
import { WidgetComponentModule } from './widget-component/widget-component.module';
import { SideBarComponent } from './Shared/side-bar/side-bar.component';
import { FooterComponent } from './Shared/footer/footer.component';
import { CookieService } from 'ngx-cookie-service';
import { AuthInterceptorService } from './service/auth-interceptor.service';
import { ExpireSessionService } from './service/expire-session/expire-session.service';
import { RoleGuard } from './service/role-guard.guard';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ChartsModule } from 'ng2-charts';
import { ToolbarHeaderComponent } from './toolbar-header/toolbar-header.component';
import { Ng2TelInputModule} from 'ng2-tel-input';
import { NgOtpInputModule } from  'ng-otp-input';
import { UtilisateursModule } from './utilisateurs/utilisateurs.module';
import { MatFormFieldModule  } from '@angular/material/form-field';
import { ListResidentComponent } from './resident/list-resident/list-resident.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatCalendar} from '@angular/material/datepicker';
import { ListFichierIntegreComponent } from './fichier-integre/list-fichier-integre/list-fichier-integre.component';
import { ListFichierDetailleComponent } from './fichier-integre/list-fichier-detaille/list-fichier-detaille.component';
import { FichierAjouterComponent } from '../app/fichier-integre/fichier-ajouter/fichier-ajouter.component';
import { QuillModule } from 'ngx-quill';
import { CKEditorModule } from 'ng2-ckeditor';
import { QualifIncidentTachesComponent } from './taches/qualif-incident-taches/qualif-incident-taches/qualif-incident-taches.component';
import { QualifierTraitementTachesComponent } from './taches/qualifier-traitement-taches/qualifier-traitement-taches/qualifier-traitement-taches.component';
import { AjouterInterventionComponent } from './taches/traiter-incident-taches/traiter-incident-taches/ajouter-intervention.component';
import { FullCalendarModule } from '@fullcalendar/angular';

export const firebaseConfig = {
	apiKey: "AIzaSyCE0po6Q8jGuBEds-A903KEU4U6Cerojzo",
	authDomain: "gene-eaeef.firebaseapp.com",
	databaseURL: "https://gene-eaeef.firebaseio.com",
	projectId: "gene-eaeef",
	storageBucket: "gene-eaeef.appspot.com",
	messagingSenderId: "81833823583",
	appId: "1:81833823583:web:581d7ddd8cfa93a4"
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true
};

@NgModule({
	imports: [
		//TachesModule,
		CKEditorModule,
    MatProgressBarModule,
		BrowserModule,
		QuillModule.forRoot(),
		MatPaginatorModule,
		NgOtpInputModule,
		Ng2TelInputModule,
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		AlertModule,
		FormsModule,
		ReactiveFormsModule,
		RoutingModule,
		FlexLayoutModule,
		Ng5BreadcrumbModule.forRoot(),
		TourMatMenuModule.forRoot(),
		PerfectScrollbarModule,
		MenuToggleModule,
		HttpClientModule,
		MatSlideToggleModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		AngularFireModule.initializeApp(firebaseConfig),
		AngularFireAuthModule,
		MatButtonModule,
		MatCardModule,
		MatMenuModule,
		MatToolbarModule,
		MatIconModule,
		MatBadgeModule,
		MatInputModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatProgressSpinnerModule,
		MatTableModule,
		MatExpansionModule,
		MatSelectModule,
		MatSnackBarModule,
		MatTooltipModule,
		MatChipsModule,
		MatListModule,
		MatSidenavModule,
		MatTabsModule,
		MatProgressBarModule,
		MatCheckboxModule,
		MatSliderModule,
		MatRadioModule,
		MatDialogModule,
		MatGridListModule,
		ToastrModule.forRoot(),
		WidgetComponentModule,
		LoadingBarRouterModule,
		ScrollingModule,
		BrowserModule,
		UtilisateursModule,
		RouterModule,
		NgbModalModule,
		LoadingBarModule,
		MatFormFieldModule,
		MatAutocompleteModule,
		ScrollingModule
	],
	declarations: [
		QualifIncidentTachesComponent,
		QualifierTraitementTachesComponent,
		AjouterInterventionComponent,
		GeneAppComponent,
		MainComponent,
		SideBarComponent, FooterComponent, ToolbarHeaderComponent, ListResidentComponent,
		SideBarComponent, FooterComponent, ToolbarHeaderComponent,
		//components  list  fichier 
		ListFichierIntegreComponent,
		ListFichierDetailleComponent ,
		FichierAjouterComponent,

 
	],
	bootstrap: [GeneAppComponent],
	providers: [
		MenuItems,
		BreadcrumbService,
		PageTitleService,
		AuthService,
		MatCalendar,
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		},
		AuthGuard,
		RoleGuard,
		CookieService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptorService,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ExpireSessionService,
			multi: true
		}, 
		{ 
			provide: MAT_DATE_LOCALE, 
			useValue: 'fr' 
		}
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class GeneAppModule { }
