import { Component, EventEmitter, Input , OnInit, Output, SimpleChanges } from '@angular/core';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { FormBuilder,FormControl,FormGroup} from '@angular/forms';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { ListIncidentService} from 'app/service/list-incident-service/list-incident.service';
import { CurrentUserService } from 'app/service/current-user.service';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import Swal from 'sweetalert2';
import { SharedService } from 'app/service/shared-service/shared.service';
import { AjouterIncidentComponent } from '../ajouter-incident/ajouter-incident.component';
import { AjouterInterventionComponent } from 'app/taches/traiter-incident-taches/traiter-incident-taches/ajouter-intervention.component';


@Component({
  selector: 'ms-verifier-incident',
  templateUrl: './verifier-incident.component.html',
  styleUrls: ['./verifier-incident.component.scss']
})
export class VerifierIncidentComponent implements OnInit {

  cleIncident
  userItem
  incident
  form: FormGroup;
  listeMotifAbandon
  isSubmitted: boolean;
  cle_incident: any;
  id_incident : any;
  images: any;
  imagesList: [];
  imagesListProv = []
  photoUrls
  imgUpload
  selectedFiles
  imgExtension
  order
  fd
  code_statut_incident
  description
  motif_abandon
  qualifIncidentForm;
  niveau_gravite;
  damage_caused;
  reasons;
  solutions_retenue;
  listeCategories = []  
  deletedImages = []
  emitImages
  code_type_incident
  id_residence
  ListIncident
  records_per_page = 10
  page_number = 0
  code_categorie_prin
  code_categorie_seco
  listeMorifs = []
  ListNiveauGravite: any;

  constructor(
      private pageTitleService    : PageTitleService                      ,
      private formBuilder         : FormBuilder                           ,
      private listincidentservice : ListIncidentService                   ,
      private _currentUser        : CurrentUserService                    , 
      public  dialog              : MatDialog                             , 
      public sharedService        : SharedService                         ,
      public  dialogRef           : MatDialogRef<VerifierIncidentComponent>

  ) {
   }

   

  ngOnInit(): void {
       
  }

  verifierIncident() {
    const dialogRef = this.dialog.open(AjouterIncidentComponent, {
      width: "800px",
      autoFocus: false,
      maxHeight: "90vh",
      panelClass: 'custom-dialog-container',
    });
    dialogRef.afterOpened().subscribe(() => {
      const dialogContainer = document.querySelector('.custom-dialog-container');
      if (dialogContainer) {
        const dialogContent = dialogContainer.querySelector('.dialog-content');
        if (dialogContent instanceof HTMLElement) {
          dialogContent.style.height = '34vh';
          dialogContent.style.overflow = 'auto';
        }
      }
    });
    
    dialogRef.componentInstance.cle_incident = this.cle_incident
    dialogRef.componentInstance.id_incident = this.id_incident;
    dialogRef.componentInstance.isVerifier = true;
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dialog.closeAll();
      }
    })
  }

  AjouterInterventionDialog(){
    const dialogRef = this.dialog.open(AjouterInterventionComponent, {
			width: '800px',
			autoFocus: false,
      maxHeight: '85vh'
		});
    dialogRef.componentInstance.cle_incident = this.cle_incident;
    dialogRef.componentInstance.id_incident = this.id_incident;
    dialogRef.componentInstance.isTypeDisable = true;
    dialogRef.componentInstance.isRapport = false;
    
    dialogRef.componentInstance.code_origin_intervention = 'INCI';

    dialogRef.afterClosed().subscribe(result => {
      this.dialog.closeAll();
    })
  }

}
